import React from 'react'
import { MainContainer} from './styles'
import AccountManagerTask from './accountManagerTask/AccountManagerTask'
import AccountManagerSupport from './accountManagerSupportInbox/AccountManagerSupport'
import AccountManagerMentions from './accountManagerMentions/AccountManagerMentions'
const AccountManagerTaskComponent = () => {
  return (
    <MainContainer>
        <AccountManagerTask/>
        <AccountManagerSupport/>
        <AccountManagerMentions/>
    </MainContainer>
  )
}

export default AccountManagerTaskComponent
