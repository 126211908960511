import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai"; // Trash icon
import { MdDone, MdReportProblem } from "react-icons/md"; // Spam icon
import "./styles";
import useGetData from "../../../../hooks/useGetData";
import {
  Container,
  Dropdown,
  Loader,
  Button,
  MenuOption,
  IconWrapper,
  ReopenIconWrapper,
  ReopenIconWrapperr,
} from "./styles";
import { InstanceWithAuth } from "../../../../App";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Skeleton } from "antd";
import { PiArrowArcRightBold } from "react-icons/pi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import { CiTrash } from "react-icons/ci";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

import ChangeDepartmentModal from "../../changedepartmentModal/ChangeDepartmentModal";
import { handleAddOpenConversationDetails } from "../../../../redux/app/appSlice";

const ColumnHeader = ({
  selectedEmailId,
  refetchEmails,
  setSelectedEmailOrChat,
  email,
  selectedEmails,
  setSelectedEmails,
  selectedFolder,
  refetchCount,
  id,
  isInitialLoadThread,
  setInitialEmails,
  setSelectedEmailIdForStyling,
  setIsClosingAllConversations,
  isClosingAllConversations,
}) => {
  const dispatch = useDispatch();
  const { showMessage } = useGlobalAlertMessage();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [loadingMoveToFolder, setLoadingMoveToFolder] = useState(false);
  const [error, setError] = useState(null);
  const { app } = useSelector((state) => state);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [isChangeDepartmentModalOpen, setChangeDepartmentModalOpen] =
    useState(false);

  // Handle menu click to toggle dropdown
  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const moveToFolder = async (folder) => {
    try {
      setLoadingMoveToFolder(true);
      setIsOpen(false);
      let formData = new FormData();
      formData.append(`email_ids[0]`, selectedEmailId);
      let type;
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        type = "email";
      } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
        type = "chat";
      }
      formData.append("type", type);
      let url;
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        url = `support/emails/move_to/${folder}`;
      } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
        url = `support/emails/move_to/${folder}`;
      }
      const response = await InstanceWithAuth.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });
      if (response.status === 201 || response.status === 200) {
        setLoadingMoveToFolder(false);
        refetchEmails();
        refetchCount();
        setSelectedEmails([]);
        setInitialEmails((prevEmails) => {
          const emailList = Array.isArray(prevEmails?.data?.data)
            ? prevEmails.data.data
            : Array.isArray(prevEmails?.data)
            ? prevEmails.data
            : [];

          if (!emailList.length) return prevEmails;

          // Find index of the email to remove
          const movedEmailIndex = emailList.findIndex(
            (email) => email.id === selectedEmailId
          );

          if (movedEmailIndex === -1) return prevEmails; // If not found, return unchanged state

          // Remove the moved email
          const updatedEmails = [...emailList];
          updatedEmails.splice(movedEmailIndex, 1);

          // Determine the next email to select
          let nextEmail = null;
          if (updatedEmails.length > 0) {
            nextEmail =
              updatedEmails[movedEmailIndex] || // Try selecting the next email
              updatedEmails[movedEmailIndex - 1] || // If last in the list, select the previous email
              null; // If no emails left, select null
          }

          if (nextEmail) {
            dispatch(handleAddOpenConversationDetails(nextEmail));
            setSelectedEmailOrChat(nextEmail.id);
            setSelectedEmailIdForStyling(nextEmail.communication_id);
          } else {
            dispatch(handleAddOpenConversationDetails(""));
            setSelectedEmailOrChat(null);
            setSelectedEmailIdForStyling(null);
          }

          return {
            ...prevEmails,
            data: Array.isArray(prevEmails?.data?.data)
              ? { ...prevEmails.data, data: updatedEmails }
              : updatedEmails,
          };
        });
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
        setSelectedEmailOrChat(null);
      }
    } catch (error) {
      setError(error);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    } finally {
      setLoadingMoveToFolder(false);
    }
  };

  const handleCloseConversation = async () => {
    try {
      setLoading(true);

      let formData = new FormData();

      formData.append(`email_ids[0]`, selectedEmailId);

      let type;
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        type = "email";
      } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
        type = "chat";
      }

      formData.append("type", type);

      let url;
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        url = `support/emails/move_to/close`;
      } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
        url = `support/emails/move_to/close`;
      }

      const response = await InstanceWithAuth.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        setIsOpen(false);
        setSelectedEmails([]);
        setIsLoading(false);
        refetchCount();
        setSelectedEmailOrChat(null);
        dispatch(handleAddOpenConversationDetails(null));

        setInitialEmails((prevEmails) => {
          const emailList = Array.isArray(prevEmails?.data?.data)
            ? prevEmails.data.data
            : Array.isArray(prevEmails?.data)
            ? prevEmails.data
            : [];

          if (!emailList.length) return prevEmails;

          // Find the index of the email to remove
          const closedEmailIndex = emailList.findIndex(
            (email) => email.id === selectedEmailId
          );

          if (closedEmailIndex === -1) return prevEmails; // If not found, return unchanged state

          // Remove the closed email
          const updatedEmails = [...emailList];
          updatedEmails.splice(closedEmailIndex, 1);

          // Determine the next email to select
          const nextEmail =
            updatedEmails[closedEmailIndex] ||
            updatedEmails[closedEmailIndex - 1] ||
            null;

          if (nextEmail) {
            dispatch(handleAddOpenConversationDetails(nextEmail));
            setSelectedEmailOrChat(nextEmail.id);
            setSelectedEmailIdForStyling(nextEmail.communication_id);
          } else {
            dispatch(handleAddOpenConversationDetails(""));
            setSelectedEmailOrChat(null);
          }

          return {
            ...prevEmails,
            data: Array.isArray(prevEmails?.data?.data)
              ? { ...prevEmails.data, data: updatedEmails }
              : updatedEmails,
          };
        });

        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      setError(error);
      showMessage({ type: "error", content: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  // const handleCloseConversation = async () => {
  //   try {
  //     setLoading(true);

  //     let formData = new FormData();

  //     formData.append(`email_ids[0]`, selectedEmailId);

  //     let type;
  //     if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
  //       type = "email";
  //     } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
  //       type = "chat";
  //     }

  //     formData.append("type", type);

  //     let url;
  //     if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
  //       url = `support/emails/move_to/close`;
  //     } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
  //       url = `support/emails/move_to/close`;
  //     }

  //     const response = await InstanceWithAuth.post(url, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${auth?.userObj?.api_token}`,
  //       },
  //     });

  //     if (response.status === 201 || response.status === 200) {
  //       setIsLoading(false);
  //       setIsOpen(false);
  //       // refetchEmails();
  //       refetchCount();
  //       setSelectedEmails([]);
  //       setSelectedEmailOrChat(null);
  //       dispatch(handleAddOpenConversationDetails(null));
  //       showMessage({
  //         type: "success",
  //         content: response?.data?.message,
  //       });
  //     }
  //   } catch (error) {
  //     setError(error);

  //     showMessage({
  //       type: "error",
  //       content: error?.response?.data?.message,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleOpenConversation = async () => {
  //   try {
  //     setLoading(true);

  //     let formData = new FormData();
  //     formData.append(`email_ids[0]`, selectedEmailId);

  //     let type;
  //     if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
  //       type = "email";
  //     } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
  //       type = "chat";
  //     }

  //     formData.append("type", type);

  //     let url;
  //     if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
  //       url = `support/emails/move_to/open`;
  //     } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
  //       url = `support/emails/move_to/open`;
  //     }

  //     const response = await InstanceWithAuth.post(url, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${auth?.userObj?.api_token}`,
  //       },
  //     });

  //     // Handle the response after the request is successful
  //     if (response.status === 201 || response.status === 200) {
  //       setIsLoading(false);
  //       setIsOpen(false);
  //       refetchEmails();
  //       refetchCount();
  //       setSelectedEmails([]); // Clear selected emails

  //       // Display a success message
  //       showMessage({
  //         type: "success",
  //         content: response?.data?.message,
  //       });
  //       // Reset the selected email/chat
  //       setSelectedEmailOrChat(null);
  //     }
  //   } catch (error) {
  //     // Handle errors and show an error alert
  //     setError(error);
  //     showMessage({
  //       type: "error",
  //       content: error?.response?.data?.message,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleOpenConversation = async () => {
    try {
      setLoading(true);

      let formData = new FormData();
      formData.append(`email_ids[0]`, selectedEmailId);

      let type;
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        type = "email";
      } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
        type = "chat";
      }

      formData.append("type", type);

      let url;
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        url = `support/emails/move_to/open`;
      } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
        url = `support/emails/move_to/open`;
      }

      const response = await InstanceWithAuth.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        setIsOpen(false);
        setSelectedEmails([]);
        setIsLoading(false);
        refetchCount();
        setSelectedEmailOrChat(null);
        dispatch(handleAddOpenConversationDetails(null));

        setInitialEmails((prevEmails) => {
          const emailList = Array.isArray(prevEmails?.data?.data)
            ? prevEmails.data.data
            : Array.isArray(prevEmails?.data)
            ? prevEmails.data
            : [];

          if (!emailList.length) return prevEmails;

          // Find index of the email to remove
          const openedEmailIndex = emailList.findIndex(
            (email) => email.id === selectedEmailId
          );

          if (openedEmailIndex === -1) return prevEmails; // If not found, return unchanged state

          // Remove the opened email
          const updatedEmails = [...emailList];
          updatedEmails.splice(openedEmailIndex, 1);

          // Determine the next email to select
          let nextEmail = null;
          if (updatedEmails.length > 0) {
            nextEmail =
              updatedEmails[openedEmailIndex] || // Try selecting the next email
              updatedEmails[openedEmailIndex - 1] || // If last in the list, select the previous email
              null; // If no emails left, select null
          }

          if (nextEmail) {
            dispatch(handleAddOpenConversationDetails(nextEmail));
            setSelectedEmailOrChat(nextEmail.id);
            setSelectedEmailIdForStyling(nextEmail.communication_id);
          } else {
            dispatch(handleAddOpenConversationDetails(""));
            setSelectedEmailOrChat(null);
            setSelectedEmailIdForStyling(null);
          }

          return {
            ...prevEmails,
            data: Array.isArray(prevEmails?.data?.data)
              ? { ...prevEmails.data, data: updatedEmails }
              : updatedEmails,
          };
        });

        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      setError(error);
      showMessage({ type: "error", content: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isClosingAllConversations) {
      console.log("Conversations are being closed...");
    }
  }, [isClosingAllConversations]);

  const handleCloseConversations = async () => {
    try {
      setIsClosingAllConversations(true);

      setLoading(true);
      const emails = selectedEmails.filter(
        (email) => email.Model === "SupportEmails"
      );
      const chats = selectedEmails.filter(
        (email) => email.Model === "LiveChat"
      );

      let emailFormData = new FormData();
      emails.forEach((email, index) => {
        emailFormData.append(`email_ids[${index}]`, email.id);
      });
      emailFormData.append("type", "email");

      let chatFormData = new FormData();
      chats.forEach((chat, index) => {
        chatFormData.append(`email_ids[${index}]`, chat.id);
      });
      chatFormData.append("type", "chat");

      // Initialize API calls
      let emailResponse = null;
      let chatResponse = null;

      // API call for emails (if any)
      if (emails.length > 0) {
        emailResponse = await InstanceWithAuth.post(
          "support/emails/move_to/close",
          emailFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      // API call for chats (if any)
      if (chats.length > 0) {
        chatResponse = await InstanceWithAuth.post(
          "support/emails/move_to/close",
          chatFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      // Check if both responses are successful (200 or 201)
      if (
        (emails.length === 0 ||
          emailResponse?.status === 200 ||
          emailResponse?.status === 201) &&
        (chats.length === 0 ||
          chatResponse?.status === 200 ||
          chatResponse?.status === 201)
      ) {
        setSelectedEmailOrChat(null);
        setLoading(false);
        setIsOpen(false);
        refetchEmails();
        refetchCount();
        setSelectedEmails([]);
        showMessage({
          type: "success",
          content: "Conversations have been closed.",
        });
      } else {
        throw new Error("Failed to close all conversations.");
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        type: "error",
        content:
          error?.response?.data?.message ||
          error.message ||
          "An error occurred",
      });
    }
  };

  const handleOpenConversations = async () => {
    try {
      setLoading(true);
      setIsClosingAllConversations(true);
      const emails = selectedEmails.filter(
        (email) => email.Model === "SupportEmails"
      );
      const chats = selectedEmails.filter(
        (email) => email.Model === "LiveChat"
      );

      let emailFormData = new FormData();
      emails.forEach((email, index) => {
        emailFormData.append(`email_ids[${index}]`, email.id);
      });
      emailFormData.append("type", "email");

      let chatFormData = new FormData();
      chats.forEach((chat, index) => {
        chatFormData.append(`email_ids[${index}]`, chat.id);
      });
      chatFormData.append("type", "chat");

      let emailResponse = null;
      let chatResponse = null;

      if (emails.length > 0) {
        emailResponse = await InstanceWithAuth.post(
          "support/emails/move_to/open",
          emailFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      if (chats.length > 0) {
        chatResponse = await InstanceWithAuth.post(
          "support/emails/move_to/open",
          chatFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      if (
        (emails.length === 0 ||
          emailResponse?.status === 200 ||
          emailResponse?.status === 201) &&
        (chats.length === 0 ||
          chatResponse?.status === 200 ||
          chatResponse?.status === 201)
      ) {
        setLoading(false);
        setIsOpen(false);
        refetchEmails();
        refetchCount();
        setSelectedEmails([]);
        showMessage({
          type: "success",
          content: "Conversations have been reopened.",
        });

        setSelectedEmailOrChat(null);
      } else {
        throw new Error("Failed to reopen all conversations.");
      }
    } catch (error) {
      setLoading(false);

      showMessage({
        type: "error",
        content:
          error?.response?.data?.message ||
          error.message ||
          "An error occurred",
      });
    }
  };

  const handleTrashConversations = async () => {
    try {
      setLoading(true);
      setIsClosingAllConversations(true);

      // Separate emails and chats based on the "Model"
      const emails = selectedEmails.filter(
        (email) => email.Model === "SupportEmails"
      );
      const chats = selectedEmails.filter(
        (email) => email.Model === "LiveChat"
      );

      // Form data for emails and chats
      let emailFormData = new FormData();
      emails.forEach((email, index) => {
        emailFormData.append(`email_ids[${index}]`, email.id);
      });
      emailFormData.append("type", "email");

      let chatFormData = new FormData();
      chats.forEach((chat, index) => {
        chatFormData.append(`email_ids[${index}]`, chat.id);
      });
      chatFormData.append("type", "chat");

      // Initialize API responses
      let emailResponse = null;
      let chatResponse = null;

      // Make the API request for emails if there are any
      if (emails.length > 0) {
        emailResponse = await InstanceWithAuth.post(
          "support/emails/move_to/trash", // "trash" endpoint
          emailFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      // Make the API request for chats if there are any
      if (chats.length > 0) {
        chatResponse = await InstanceWithAuth.post(
          "support/emails/move_to/trash", // "trash" endpoint
          chatFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      // Check if both responses are successful (200 or 201)
      if (
        (emails.length === 0 ||
          emailResponse?.status === 200 ||
          emailResponse?.status === 201) &&
        (chats.length === 0 ||
          chatResponse?.status === 200 ||
          chatResponse?.status === 201)
      ) {
        // If both requests were successful, proceed
        setLoading(false);
        setIsOpen(false);
        refetchEmails();
        refetchCount();
        setSelectedEmails([]);
        showMessage({
          type: "success",
          content: `Conversations have been moved to trash.`,
        });

        // messageApi.open({
        //   type: "success",
        //   content: "Conversations have been moved to trash.",
        // });
        setSelectedEmailOrChat(null);
      } else {
        // If any response fails (status not 200/201)
        throw new Error("Failed to move all conversations to trash.");
      }
    } catch (error) {
      setLoading(false);

      showMessage({
        type: "error",
        content:
          error?.response?.data?.message ||
          error.message ||
          "An error occurred",
      });
    }
  };
  const handleCloseTrackingModal = () => {
    setChangeDepartmentModalOpen(false);
  };
  return (
    <>
      <Container ref={dropdownRef}>
        {isInitialLoadThread ? (
          <Skeleton.Input active size="defual" />
        ) : (
          <>
            {" "}
            {selectedEmails?.length > 0 && (
              <>
                {selectedFolder == "Chat" ||
                selectedFolder == "All Open" ||
                selectedFolder === "Unassigned" ||
                selectedFolder === "Assigned to me" ||
                selectedFolder == "Inbox" ? (
                  <button className="button" disabled={loading}>
                    <Popconfirm
                      title="Close Conversations"
                      description="Are you sure to close?"
                      onConfirm={() => handleCloseConversations()}
                      // onCancel={c}
                      okText="Yes"
                      cancelText="No"
                    >
                      <ReopenIconWrapperr>
                        <ReopenIconWrapper>
                          <MdDone size={16} />
                        </ReopenIconWrapper>
                        {loading ? "Closing" : "Close Conversations"}
                      </ReopenIconWrapperr>
                    </Popconfirm>
                  </button>
                ) : (
                  <button className="button" disabled={loading}>
                    <Popconfirm
                      title="Reopen Conversations"
                      description="Are you sure to reopen?"
                      onConfirm={() => handleOpenConversations()}
                      // onCancel={c}
                      okText="Yes"
                      cancelText="No"
                    >
                      <ReopenIconWrapperr>
                        <ReopenIconWrapper>
                          <PiArrowArcRightBold size={16} />
                        </ReopenIconWrapper>
                        {loading ? "Opening" : "Reopen Conversations"}
                      </ReopenIconWrapperr>
                    </Popconfirm>
                  </button>
                )}
                {selectedFolder !== "Trash" && (
                  <button className="button" disabled={loadingMoveToFolder}>
                    <Popconfirm
                      title="Move to trash"
                      description="Are you sure to trash?"
                      onConfirm={() => handleTrashConversations()}
                      // onCancel={c}
                      okText="Yes"
                      cancelText="No"
                    >
                      <ReopenIconWrapperr>
                        <ReopenIconWrapper>
                          <CiTrash size={16} />
                        </ReopenIconWrapper>
                        {loadingMoveToFolder ? "Loading" : "Move to trash"}
                      </ReopenIconWrapperr>
                    </Popconfirm>
                  </button>
                )}
              </>
            )}
            {selectedEmails?.length <= 0 && (
              <button className="button" disabled={loading}>
                {app?.allOpenConversationDetails?.Model === "SupportEmails" &&
                app?.allOpenConversationDetails?.status === 1 ? (
                  <Popconfirm
                    title="Close Conversation"
                    description="Are you sure to close?"
                    onConfirm={() => handleCloseConversation()}
                    // onCancel={c}
                    okText="Yes"
                    cancelText="No"
                  >
                    <ReopenIconWrapperr>
                      <ReopenIconWrapper>
                        <MdDone size={16} />
                      </ReopenIconWrapper>
                      {loading ? "Closing" : "Close Conversation"}
                    </ReopenIconWrapperr>
                  </Popconfirm>
                ) : app?.allOpenConversationDetails?.Model === "LiveChat" &&
                  !app?.allOpenConversationDetails?.is_closed ? (
                  <Popconfirm
                    title="Close Conversation"
                    description="Are you sure to close?"
                    onConfirm={() => handleCloseConversation()}
                    // onCancel={c}
                    okText="Yes"
                    cancelText="No"
                  >
                    <ReopenIconWrapperr>
                      <ReopenIconWrapper>
                        <MdDone size={16} />
                      </ReopenIconWrapper>
                      {loading ? "Closing" : "Close Conversation"}
                    </ReopenIconWrapperr>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Reopen Conversation"
                    description="Are you sure to reopen?"
                    onConfirm={() => handleOpenConversation()}
                    // onCancel={c}
                    okText="Yes"
                    cancelText="No"
                  >
                    <ReopenIconWrapperr>
                      <ReopenIconWrapper>
                        <PiArrowArcRightBold size={16} />
                      </ReopenIconWrapper>
                      {loading ? "Opening" : "Reopen Conversation"}
                    </ReopenIconWrapperr>
                  </Popconfirm>
                )}
              </button>
            )}
            {selectedEmails?.length <= 0 &&
              app?.allOpenConversationDetails?.Model === "SupportEmails" && (
                <>
                  <Button onClick={handleMenuClick}>⋯</Button>
                  {isOpen && (
                    <Dropdown>
                      {isLoading ? (
                        <Loader>Loading...</Loader>
                      ) : (
                        <>
                          {selectedFolder !== "Trash" && (
                            <MenuOption onClick={() => moveToFolder("trash")}>
                              <IconWrapper>
                                <AiOutlineDelete size={16} />
                              </IconWrapper>
                              Move to Trash
                            </MenuOption>
                          )}
                          {selectedFolder !== "Spam" && (
                            <MenuOption onClick={() => moveToFolder("spam")}>
                              <IconWrapper>
                                <MdReportProblem size={16} />
                              </IconWrapper>
                              Move to Spam
                            </MenuOption>
                          )}

                          <div
                            style={{ position: "relative" }}
                            onClick={() =>
                              setChangeDepartmentModalOpen(
                                !isChangeDepartmentModalOpen
                              )
                            }
                          >
                            <MenuOption
                              onClick={() => setShowSubmenu(!showSubmenu)}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <IconWrapper style={{ marginTop: "1px" }}>
                                <BsArrowRight size={16} />
                              </IconWrapper>
                              Move Conversation
                            </MenuOption>
                          </div>
                        </>
                      )}
                    </Dropdown>
                  )}
                </>
              )}
          </>
        )}
      </Container>
      {isChangeDepartmentModalOpen && (
        <ChangeDepartmentModal
          isModalOpen={isChangeDepartmentModalOpen}
          handleCancel={handleCloseTrackingModal}
          handleOk={handleCloseTrackingModal}
          selectedEmailId={selectedEmailId}
          email={email}
        />
      )}
    </>
  );
};

export default ColumnHeader;
