import React, { useState, useEffect, useRef, useMemo } from "react";
import { FaEnvelope, FaRegTrashAlt, FaRegCommentDots } from "react-icons/fa";
import { GoReply } from "react-icons/go";
import {
  SecondColumnContainer,
  HiddenCheckboxInput,
  StyledCheckbox,
  StyledCheckboxContainer,
  CommentBoxWrapper,
  Text,
} from "./styles";
import { Dropdown, Menu, Skeleton, Space } from "antd";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import useGetData from "../../../hooks/useGetData";
import DataNotFound from "../../../common/DataNotFound";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { InstanceWithAuth } from "../../../App";
import { useSelector } from "react-redux";
import Echo from "laravel-echo";
import { handleAddOpenConversationDetails } from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";
const SecondColumn = ({
  onEmailSelect,
  selectedEmailId,
  selectedFolder,
  status,
  setRefetchEmails,
  isInitialLoad,
  setIsInitialLoad,
  selectedEmails,
  setSelectedEmails,
  id,
  setSelectedCompanyId,
  searchParm,
  initialEmails,
  refetchEmails,
  order,
  setOrder,
  loading = { loading },
  selectedEmailIdForStyling,
  loadingNextPageEmails,
  setLoadingNextPageEmails,
  setPage,
  page,
}) => {
  const [hoveredEmailId, setHoveredEmailId] = useState(null);
  const [isFolderChange, setIsFolderChange] = useState(false);
  const [emails, setEmails] = useState("");
  const { auth, app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const emailListRef = useRef(null);
  const [SortedAllOpenData, setSortedAllOpenData] = useState([]);

  useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      key: "local",
      cluster: "your-app-cluster",
      wsHost: `${process.env.REACT_APP_SOCKET_BASE_URL}`,
      wsPort: `${process.env.REACT_APP_CHAT_WSPORT}`,
      wssPort: `${process.env.REACT_APP_CHAT_WSSPORT}`,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
      disableStats: true,
    });

    echo.channel("chat.new").listen("ChatNew", (event) => {
      console.log("New event received:", event);
      setEmails((prevEmails) =>
        Array.isArray(prevEmails) ? [event, ...prevEmails] : [event]
      );
    });

    echo.connector.pusher.connection.bind("connected", () => {
      console.log(
        "Successfully connected to Pusher to listen for new chat requests"
      );
    });

    return () => {
      echo.disconnect();
    };
  }, []);

  useEffect(() => {
    if (initialEmails?.data) {
      setEmails(initialEmails);
    }
  }, [initialEmails]);

  useEffect(() => {
    if (setRefetchEmails) {
      setRefetchEmails(refetchEmails);
    }
  }, [refetchEmails, setRefetchEmails]);

  useEffect(() => {
    setIsFolderChange(true);
    refetchEmails();
    setIsFolderChange(false);
  }, [selectedFolder]);

  // const SortedAllOpenData =
  //   selectedFolder === "All Open" || selectedFolder === "All Closed"
  //     ? Array.isArray(emails?.data?.data)
  //       ? emails.data.data
  //       : []
  //     : Array.isArray(emails?.data)
  //     ? emails.data
  //     : [];

  useEffect(() => {
    const newSortedData =
      selectedFolder === "All Open" ||
      selectedFolder === "All Closed" ||
      selectedFolder === "Unassigned" ||
      selectedFolder === "Assigned to me"
        ? Array.isArray(initialEmails?.data?.data)
          ? [...initialEmails.data.data]
          : []
        : Array.isArray(initialEmails?.data)
        ? [...initialEmails.data]
        : [];
    console.log("hamza", newSortedData);
    setSortedAllOpenData(newSortedData);
  }, [initialEmails, selectedFolder]);

  useEffect(() => {
    if (id && initialEmails?.data?.length >= 0) {
      const numericId = Number(id);
      // onEmailSelect(numericId)
      // setIsInitialLoad(false);
    } else {
      if (isInitialLoad) {
        if (initialEmails?.data?.length >= 0) {
          // onEmailSelect(initialEmails?.data[0]?.id)
          // setIsInitialLoad(false);
          setSelectedCompanyId(initialEmails?.data[0]?.company_id);
        }
      }
    }
  }, [initialEmails]);

  useEffect(() => {
    if (id && initialEmails?.data?.length > 0 && selectedFolder == "Chat") {
      const numericId = Number(id);
      // onEmailSelect(numericId)
      setIsInitialLoad(false);
    } else {
      if (isInitialLoad && selectedFolder == "Chat") {
        if (initialEmails?.data?.length > 0) {
          // dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
          // onEmailSelect(initialEmails?.data[0]?.id)
          setIsInitialLoad(false);
          setSelectedCompanyId(initialEmails?.data[0]?.company_id);
        }
      }
    }
  }, [initialEmails]);

  useEffect(() => {
    if (id && SortedAllOpenData?.length > 0 && selectedFolder == "All Open") {
      setIsInitialLoad(false);
    } else {
      if (isInitialLoad && selectedFolder == "All Open") {
        if (SortedAllOpenData?.length > 0) {
          // dispatch(handleAddOpenConversationDetails(SortedAllOpenData[0]));
          onEmailSelect(SortedAllOpenData[0]?.id);
          setIsInitialLoad(false);
          setSelectedCompanyId(SortedAllOpenData?.[0]?.company_id);
          if (selectedFolder === "All Open") {
            const firstEmail = SortedAllOpenData?.[0];
            if (firstEmail) {
              // dispatch(handleAddOpenConversationDetails(firstEmail));
            }
          }
        }
      }
    }
  }, [initialEmails]);

  const getIcon = (type) => {
    switch (type) {
      case "email":
        return <FaEnvelope />;
      case "trash":
        return <FaRegTrashAlt />;
      case "chat":
        return <FaRegCommentDots />;
      default:
        return <FaEnvelope />;
    }
  };

  const getIconsForAllChat = (type) => {
    switch (type) {
      case "Email":
        return <FaEnvelope />;
      case "trash":
        return <FaRegTrashAlt />;
      case "LiveChat":
        return <FaRegCommentDots />;
      default:
        return <FaEnvelope />;
    }
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelected) => {
      if (prevSelected.some((e) => e.id === email.id)) {
        return prevSelected.filter((e) => e.id !== email.id);
      } else {
        return [...prevSelected, email];
      }
    });
  };

  const handleSelectAllChange = () => {
    const emailData =
      selectedFolder === "All Open" ||
      selectedFolder === "All Closed" ||
      selectedFolder === "Unassigned" ||
      selectedFolder === "Assigned to me"
        ? initialEmails?.data?.data
        : initialEmails?.data;

    setSelectedEmails((prevSelected) =>
      prevSelected?.length === emailData?.length
        ? []
        : emailData?.map((email) => email)
    );
  };

  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Handle null/undefined input

    const marker = "[--!!email-history!!--]";
    const splitIndex = text.indexOf(marker);

    // Remove marker if it exists
    if (splitIndex !== -1) {
      text = text.substring(splitIndex + marker.length);
    }

    // Truncate if necessary
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const formatTime = (time) => {
    const now = moment();
    const updatedAt = moment(time);

    if (now.diff(updatedAt, "minutes") < 60) {
      return `${now.diff(updatedAt, "minutes")}m`;
    } else if (now.diff(updatedAt, "hours") < 24) {
      return `${now.diff(updatedAt, "hours")}h`;
    } else if (now.diff(updatedAt, "days") === 1) {
      return "Yesterday";
    } else {
      return updatedAt.format("MMM D");
    }
  };

  const handleSortOrderChange = (newOrder) => {
    setOrder(newOrder);
  };

  useEffect(() => {
    // Only proceed if the email list is rendered
    if (!emailListRef.current) {
      console.warn("🚫 emailListRef is not attached!");
      return;
    }

    const emailListEl = emailListRef.current;
    let timeoutId = null;

    const handleScroll = () => {
      console.log("🟡 Scroll event triggered!");
      if (loadingNextPageEmails) return;

      const { scrollTop, scrollHeight, clientHeight } = emailListEl;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        console.log("Reached bottom, fetching more emails...");

        // Clear any existing timeout to prevent multiple triggers
        if (timeoutId) clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setLoadingNextPageEmails(true);
        }, 300); // 1-second delay before fetching
      }
    };

    emailListEl.addEventListener("scroll", handleScroll);
    console.log("✅ Scroll event listener attached.");

    return () => {
      emailListEl.removeEventListener("scroll", handleScroll);
      console.log("🔄 Scroll event listener removed.");
      if (timeoutId) clearTimeout(timeoutId); // Cleanup timeout on unmount
    };
  }, [initialEmails]);

  useEffect(() => {
    if (loadingNextPageEmails) {
      console.log("🚀 Fetching next page emails...");
      refetchEmails().finally(() => {
        console.log("🔵 Finished fetching, resetting loading state");
        setLoadingNextPageEmails(false); // Reset state once emails are fetched
      });
    }
  }, [loadingNextPageEmails]);

  const sortingMenu = (
    <Menu>
      <Menu.Item onClick={() => handleSortOrderChange("DESC")}>
        Newest
      </Menu.Item>
      <Menu.Item onClick={() => handleSortOrderChange("ASC")}>Oldest</Menu.Item>
    </Menu>
  );

  const handleAddConverstionDetails = (
    emailId,
    companyId,
    conversationsDetails
  ) => {
    onEmailSelect(emailId, companyId, conversationsDetails);
    dispatch(handleAddOpenConversationDetails(conversationsDetails));
  };

  // useEffect(() => {
  //   console.log(
  //     "🔍 Checking if emailListRef is defined:",
  //     emailListRef.current
  //   );
  // }, [emailListRef.current]);

  // useEffect(() => {
  //   console.log("Emails updated:", initialEmails);
  // }, [initialEmails]);

  return (
    <SecondColumnContainer>
      {isInitialLoad && !initialEmails ? (
        <Skeleton active style={{ margin: "10px", width: "auto" }}></Skeleton>
      ) : (
        <>
          <div className="header">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <StyledCheckboxContainer>
                <HiddenCheckboxInput
                  checked={
                    selectedEmails?.length ===
                    (selectedFolder === "All Open" ||
                    selectedFolder === "All Closed" ||
                    selectedFolder === "Unassigned" ||
                    selectedFolder === "Assigned to me"
                      ? initialEmails?.data?.data?.length
                      : initialEmails?.data?.length)
                  }
                  onChange={handleSelectAllChange}
                />
                <StyledCheckbox
                  checked={
                    selectedEmails?.length > 0 &&
                    (selectedFolder === "All Open" ||
                    selectedFolder === "All Closed" ||
                    selectedFolder === "Unassigned" ||
                    selectedFolder === "Assigned to me"
                      ? initialEmails?.data?.data?.length > 0
                      : initialEmails?.data?.length > 0) &&
                    selectedEmails?.length ===
                      (selectedFolder === "All Open" ||
                      selectedFolder === "All Closed" ||
                      selectedFolder === "Unassigned" ||
                      selectedFolder === "Assigned to me"
                        ? initialEmails?.data?.data?.length
                        : initialEmails?.data?.length)
                  }
                  onChange={handleSelectAllChange}
                />
              </StyledCheckboxContainer>

              {selectedEmails?.length > 0 && (
                <span className="selected-mails">
                  {selectedEmails?.length + " " + "Selected"}
                </span>
              )}
            </div>

            {selectedEmails?.length <= 0 && (
              <Dropdown
                overlay={sortingMenu}
                trigger={["click"]}
                overlayStyle={{ cursor: "pointer" }}
              >
                <Space className="btn-title">
                  {order === "DESC" ? "Newest" : "Oldest"}
                  <MdOutlineKeyboardArrowDown
                    width="1rem"
                    height="1rem"
                    size="15px"
                  ></MdOutlineKeyboardArrowDown>
                </Space>
              </Dropdown>
            )}
          </div>
          <div className="email-list" ref={emailListRef}>
            {(selectedFolder == "All Open" ||
              selectedFolder == "All Closed" ||
              selectedFolder === "Unassigned" ||
              selectedFolder === "Assigned to me") &&
            SortedAllOpenData?.length > 0 ? (
              SortedAllOpenData?.map((email) => (
                <div
                  key={email.id}
                  className={`email-item ${
                    email.communication_id === selectedEmailIdForStyling
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    handleAddConverstionDetails(
                      email.id,
                      email.company_id,
                      email
                    )
                  }
                  onMouseEnter={() => setHoveredEmailId(email.id)}
                  onMouseLeave={() => setHoveredEmailId(null)}
                >
                  <div className="email-header">
                    {hoveredEmailId === email.id ||
                    selectedEmails.some((e) => e.id === email.id) ? (
                      <div className="email-checkbox">
                        <StyledCheckboxContainer>
                          <HiddenCheckboxInput
                            checked={selectedEmails.some(
                              (e) => e.id === email.id
                            )}
                            onChange={() => handleCheckboxChange(email)}
                          />
                          <StyledCheckbox
                            checked={selectedEmails.some(
                              (e) => e.id === email.id
                            )}
                            onChange={() => handleCheckboxChange(email)}
                          />
                        </StyledCheckboxContainer>
                      </div>
                    ) : (
                      <div
                        className={`email-icon ${
                          email.communication_id === selectedEmailIdForStyling
                            ? "selected-email-icon"
                            : ""
                        }`}
                      >
                        {getIconsForAllChat(email?.Model)}
                      </div>
                    )}
                    <div className="email-details">
                      <div className="email-top-row">
                        <span
                          className={
                            email?.read_status == 1
                              ? "email-sender"
                              : "unread-email-sender"
                          }
                        >
                          {truncateText(
                            email?.contact_id
                              ? email?.contact_name
                              : email?.Model === "SupportEmails"
                              ? email?.is_sent
                                ? email?.receiver_name ||
                                  email?.sender_name ||
                                  "Unknown Visitor"
                                : email?.sender_name || "Unknown Visitor"
                              : email?.guest_name || "Unknown Visitor",
                            app?.sidebarCollapsed ? 15 : 8
                          )}
                        </span>
                        <span
                          className={
                            email?.read_status == 1
                              ? "email-time"
                              : "unread-email-time"
                          }
                        >
                          {formatTime(
                            email.updated_at === null
                              ? email.created_at
                              : email.updated_at
                          )}
                        </span>
                      </div>
                      <div
                        className={
                          email?.read_status == 1
                            ? "email-subject"
                            : "unread-email-subject"
                        }
                      >
                        {email?.Model == "SupportEmails"
                          ? truncateText(email?.subject, 15)
                          : truncateText(email?.owner, 15)}
                      </div>
                      <div className="email-preview">
                        {email?.last_message_type == "note" ? (
                          <CommentBoxWrapper title="Hey just adding comment">
                            <GoReply
                              style={{ marginRight: 8, color: "#808080" }}
                            />
                            <Text>{email?.last_message}</Text>
                          </CommentBoxWrapper>
                        ) : (
                          truncateText(
                            email?.last_message
                              ?.replace(
                                /<style[\s\S]*?>[\s\S]*?<\/style>/gi,
                                ""
                              ) // Remove <style> tags
                              ?.replace(
                                /<script[\s\S]*?>[\s\S]*?<\/script>/gi,
                                ""
                              ) // Remove <script> tags
                              ?.replace(/<[^>]+>/g, "") // Remove all remaining HTML tags
                              ?.replace(/&nbsp;/g, " ") // Replace non-breaking spaces
                              ?.replace(/\s+/g, " ") // Remove extra spaces
                              ?.trim(), // Remove leading/trailing spaces
                            27
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : SortedAllOpenData?.length > 0 ? (
              SortedAllOpenData?.map((email) => (
                <div
                  key={email.id}
                  className={`email-item ${
                    email.communication_id === selectedEmailIdForStyling
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    handleAddConverstionDetails(
                      email.id,
                      email.company_id,
                      email
                    )
                  }
                  onMouseEnter={() => setHoveredEmailId(email.id)}
                  onMouseLeave={() => setHoveredEmailId(null)}
                >
                  <div className="email-header">
                    {hoveredEmailId === email.id ||
                    selectedEmails.some((e) => e.id === email.id) ? (
                      <div className="email-checkbox">
                        <StyledCheckboxContainer>
                          <HiddenCheckboxInput
                            checked={selectedEmails.some(
                              (e) => e.id === email.id
                            )}
                            onChange={() => handleCheckboxChange(email)}
                          />
                          <StyledCheckbox
                            checked={selectedEmails.some(
                              (e) => e.id === email.id
                            )}
                            onChange={() => handleCheckboxChange(email)}
                          />
                        </StyledCheckboxContainer>
                      </div>
                    ) : (
                      <div
                        className={`email-icon ${
                          email.id === selectedEmailId
                            ? "selected-email-icon"
                            : ""
                        }`}
                      >
                        {getIcon(
                          selectedFolder == "Chat" ? "chat" : email?.type
                        )}
                      </div>
                    )}
                    <div className="email-details">
                      <div className="email-top-row">
                        <span
                          className={
                            email?.read_status == 1
                              ? "email-sender"
                              : "unread-email-sender"
                          }
                        >
                          {selectedFolder == "Chat"
                            ? truncateText(
                                email?.contact_id
                                  ? email?.contact_name
                                  : email?.guest_name,
                                15
                              )
                            : truncateText(
                                email?.contact_id
                                  ? email?.contact_name
                                  : email?.is_sent
                                  ? email?.receiver_name ||
                                    email?.sender_name ||
                                    "Unknown Visitor"
                                  : email?.sender_name || "Unknown Visitor",
                                app?.sidebarCollapsed ? 15 : 8
                              )}
                        </span>

                        <span
                          className={
                            email?.read_status == 1
                              ? "email-time"
                              : "unread-email-time"
                          }
                        >
                          {formatTime(
                            email.updated_at === null
                              ? email.created_at
                              : email.updated_at
                          )}
                        </span>
                      </div>
                      <div
                        className={
                          email?.read_status == 1
                            ? "email-subject"
                            : "unread-email-subject"
                        }
                      >
                        {selectedFolder == "Chat" && email.owner != null
                          ? truncateText(email.owner, 15)
                          : truncateText(email.subject, 15)}
                      </div>
                      <div className="email-preview">
                        {email?.last_message_type == "note" ? (
                          <CommentBoxWrapper title="Hey just adding comment">
                            <GoReply
                              style={{ marginRight: 8, color: "#808080" }}
                            />
                            <Text>{email?.last_message}</Text>
                          </CommentBoxWrapper>
                        ) : (
                          truncateText(
                            email?.last_message
                              ?.replace(
                                /<style[\s\S]*?>[\s\S]*?<\/style>/gi,
                                ""
                              ) // Remove <style> tags
                              ?.replace(
                                /<script[\s\S]*?>[\s\S]*?<\/script>/gi,
                                ""
                              ) // Remove <script> tags
                              ?.replace(/<[^>]+>/g, "") // Remove all remaining HTML tags
                              ?.replace(/&nbsp;/g, " ") // Replace non-breaking spaces
                              ?.replace(/\s+/g, " ") // Remove extra spaces
                              ?.trim(), // Remove leading/trailing spaces
                            27
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-emails" style={{ marginTop: "55%" }}>
                <DataNotFound
                  label={
                    selectedFolder == "All Open" ||
                    selectedFolder === "Unassigned" ||
                    selectedFolder === "Assigned to me"
                      ? "No conversation found"
                      : selectedFolder == "Chat"
                      ? "No chat found"
                      : selectedFolder == "All Closed"
                      ? "No conversation found"
                      : "No email Found"
                  }
                />
              </div>
            )}

            {/* Loader at the bottom */}
          </div>
          {loadingNextPageEmails && (
            <div className="loader">Loading more...</div>
          )}
          {/* <button onClick={handleFetchEmails}>Test Fetch Emails</button>; */}
        </>
      )}
    </SecondColumnContainer>
  );
};

export default SecondColumn;
