import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  padding: 24px 40px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: #fff;
  margin-top: 42px;
  .adminProfile {
    display: flex;
    width: 100%;
    max-width: 1293px;
    align-items: flex-start;
    gap: 40px;

    .admin_continer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      flex: 1 0 0;
      align-self: stretch;

      
    }
  }
`;

export { MainContainer };
