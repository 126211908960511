import React, { useEffect, useState, useRef } from "react";
import { ChatMessageContainer, AttachmentList, AttachmentItem } from "./styles";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FiMoreVertical } from "react-icons/fi"; // Importing the three dots icon
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import Avatar from "./avatar/Avatarr";
import GetLogo from "../../../getlogo/getlogo";
import {
  AiOutlineAudio,
  AiOutlineFile,
  AiOutlineFilePdf,
} from "react-icons/ai";

function Comment({
  note,
  selectedEmailId,
  refetchNotes,
  onEdit,
  setSelectedTab,
}) {
  const [expanded, setExpanded] = useState(false);
  const [showFaded, setShowFaded] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  const dropdownRef = useRef(null);
  const { auth } = useSelector((state) => state);

  useEffect(() => {
    if (note?.notes?.length > 350 && !expanded) {
      setShowFaded(true);
    } else {
      setShowFaded(false);
    }
  }, [expanded, note?.notes?.length]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleEditNote = () => {
    setSelectedTab("Comment");
    setShowDropdown(false);
    onEdit(note);
  };

  const handleDeleteNote = async () => {
    setShowDropdown(false);
    try {
      const response = await InstanceWithAuth.delete(
        `support/emails/notes/${note.id}`,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        refetchNotes();
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const TruncateText = ({ text }) => {
    const maxLength = 15;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <ChatMessageContainer expanded={expanded} showFaded={showFaded}>
      <div className="email-item">
        <div className="email-header">
          <Avatar currentEmployeeImage={note?.user} />

          <div className="email-details">
            <div className="email-top-row">
              <div className="left-container">
                <span className="email-sender">
                  {note?.user?.first_name} {note?.user?.last_name}
                </span>
              </div>

              <div className="make-row" ref={dropdownRef}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={handleDropdownToggle}
                >
                  <FiMoreVertical />
                </span>
                {showDropdown && (
                  <div className="dropdown-menu">
                    <div onClick={handleEditNote}>Edit Note</div>
                    <div onClick={() => handleDeleteNote(note.id)}>
                      Delete Note
                    </div>
                  </div>
                )}
              </div>
            </div>
            <span className="vissible-msg">
              <div className="logo">{GetLogo("eye")}</div>
              <div className="msg">only visible to your team</div>
            </span>
            <div
              className={`email-subject ${expanded ? "expanded" : ""}`}
              dangerouslySetInnerHTML={{ __html: note?.notes }}
            />
            <AttachmentList>
              {note?.attachments.map((file, index) => {
                console.log("file?.filename", file?.filename);
                const fileExtension = file?.filename
                  ?.split(".")
                  .pop()
                  .toLowerCase();

                const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
                const isImage = imageExtensions.includes(fileExtension);
                console.log("fileExtension", fileExtension);
                // Define icons for specific file types
                const getFileIcon = () => {
                  if (fileExtension === "pdf") {
                    return <AiOutlineFilePdf size={20} color="#FF0000" />; // Red for PDF
                  }
                  if (fileExtension === "wav") {
                    return <AiOutlineAudio size={20} color="#007BFF" />; // Blue for Audio
                  }
                  return <AiOutlineFile size={20} color="#6c757d" />; // Gray for other files
                };

                return (
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    className="AttachmentItem"
                  >
                    <div className="file-name">
                      {isImage ? (
                        <img
                          className="file-image"
                          src={file?.url}
                          alt={file.filename}
                        />
                      ) : (
                        <span className="file-icon">{getFileIcon()}</span>
                      )}
                      <TruncateText text={file.filename}></TruncateText>
                    </div>
                    <span className="file-size">{file.formatted_size}</span>
                  </a>
                );
              })}
            </AttachmentList>
            {note?.notes?.length > 150 && (
              <div className="toggle-button">
                <div className="show-more" onClick={toggleExpand}>
                  {expanded ? (
                    <>
                      Show less{" "}
                      <span
                        style={{
                          paddingTop: "4px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TiArrowSortedUp />
                      </span>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                      }}
                    >
                      <span> Show more </span>
                      <span
                        style={{
                          padding: 0,
                          marginBottom: "-7px",
                        }}
                      >
                        <TiArrowSortedDown />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ChatMessageContainer>
  );
}

export default Comment;
