import React, { useEffect, useState } from "react";
import { MainContainer, BtnWarapper } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import FirstColumn from "../../components/ticket/FirstColumn/FirstColumn";
import dummyData from "./dummyData.json";
import SecondColumn from "../../components/ticket/SecondColumn/SecondColumn";
import ThirdColumn from "../../components/ticket/ThirdColumn/ThirdColumn";
import useGetData from "../../hooks/useGetData";
import { Skeleton } from "antd";
import Spinner from "../../common/Spinner";
import { InstanceWithAuth } from "../../App";
import { useSelector } from "react-redux";

const Ticket = () => {
  const [isComposerVisible, setIsComposerVisible] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [fetchingEmployees, setFechingEmployees] = useState(false);
  const [fetchingTicket, setFechingTicket] = useState(false);
  const [employees, setEmployees] = useState([]);
  const { auth } = useSelector((state) => state);

  const fetchEmployees = async () => {
    try {
      setFechingEmployees(true);
      const response = await InstanceWithAuth.get(`/users-home`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response?.data) {
        console.log("response.data.roles", response.data.roles);
        const allEmployees = response.data.roles.reduce(
          (acc, role) => [...acc, ...role.users],
          []
        );
        console.log("allEmployees", allEmployees);
        setEmployees(allEmployees);
        setFechingEmployees(false);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const { companyID, ticketID } = useParams();
  const {
    data: companyData,
    loading: companyDataLoading,
    error: companyDataErro,
  } = useGetData(`companies/${companyID}`);

  const {
    data: ticketData,
    loading: ticketDataLoading,
    error: ticketDataErro,
    refetchData,
  } = useGetData(`support/tickets/${ticketID}`);

  const openComposer = () => {
    setIsComposerVisible(true);
  };

  const closeComposer = () => {
    setIsComposerVisible(false);
  };

  const handleCancel = () => {
    setShowSaveBtn(false);
  };
  return (
    <>
      <CommonHaeder
        firstBcItem={"Tickets"}
        firstBcIcon={"logTickets"}
        secondBcItem={`${
          ticketData?.data?.type === "Ticket" ? "Ticket #" : "Task #"
        }${ticketData?.data?.number}`}
        secondBcIcon={"logTickets"}
      />
      <MainContainer>
        <div className="first-column">
          {ticketDataLoading || employees?.length < 0 ? (
            <div style={{ padding: "12px" }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <FirstColumn
                ticket={ticketData?.data}
                refetchData={refetchData}
                callingFromTicketDetails={true}
                setFechingEmployees={setFechingEmployees}
                employees={employees}
              />
            </>
          )}
        </div>
        <div className="second-column">
          {ticketDataLoading ? (
            <div style={{ padding: "12px" }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <SecondColumn
                openComposer={openComposer}
                ticket={ticketData?.data}
                ticketID={ticketID}
                refetchDataTicket={refetchData}
              />
            </>
          )}
        </div>
        <div className="third-column">
          {ticketDataLoading ? (
            <div style={{ padding: "12px" }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <ThirdColumn ticket={ticketData?.data} />
            </>
          )}
        </div>
      </MainContainer>
    </>
  );
};

export default Ticket;
