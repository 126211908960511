import React, { useEffect, useRef } from "react";
import "./styles";
import GetLogo from "../../../../getlogo/getlogo";
import {
  Container,
  ContentContainer,
  SearchContainer,
  ContentCard,
  PaginationContainer,
} from "./styles";
import { Collapse, Pagination, Skeleton } from "antd";
import { useState } from "react";
import useGetData from "../../../../../hooks/useGetData";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../../App";
import { useGlobalAlertMessage } from "../../../../../hooks/useAlertMessage";

const { Panel } = Collapse;

const Activity = ({ userID }) => {
  const [collopasedKey, setCollopasedKey] = useState(false);
  const { auth } = useSelector((state) => state);
  const [logs, setLogs] = useState(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useGlobalAlertMessage();
  const inputRef = useRef(null);
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };
  useEffect(() => {
    if (isSearchVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (inputRef.current.value.trim() === "") {
          setIsSearchVisible(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleSearchIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };
  const fetchLogs = async (search = "") => {
    setLoading(true);
    try {
      const response = await InstanceWithAuth.get(
        `${process.env.REACT_APP_API_BASE_URL}/activity-logs/contact/${userID}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
          params: {
            search: search,
          },
        }
      );
      setLogs(response.data);
    } catch (error) {
      console.error("Error fetching logs", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchLogs = debounce(fetchLogs, 500);
    debouncedFetchLogs();
  }, [userID]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    fetchLogs(searchTerm);
  };

  const onShowSizeChange = async (current, pageSize) => {
    try {
      setLoading(true);
      const response = await InstanceWithAuth.get(
        `${process.env.REACT_APP_API_BASE_URL}/activity-logs/contact/${userID}?page=${current}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        setLogs(response.data);
        setLoading(false);
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${ampm} EST`;
    return formattedDate;
  };

  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Activity" key="1" className="account-panel">
          <ContentContainer>
            <SearchContainer>
              <div className="search">
                {isSearchVisible ? (
                  <div className="search-input">
                    <span className="icon">{GetLogo("searchIcon")}</span>
                    <input
                      onChange={handleSearchChange}
                      type="text"
                      className="input"
                      placeholder="Search"
                      ref={inputRef}
                    />
                  </div>
                ) : (
                  <div className="search-icon" onClick={handleSearchIconClick}>
                    <i className="fas fa-search">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_3290_44862)">
                          <path
                            d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5Z"
                            stroke="#2B3746"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.4996 10.4996L8.34961 8.34961"
                            stroke="#2B3746"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3290_44862">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                    <div className="search-title">Search</div>
                  </div>
                )}
              </div>
            </SearchContainer>
            {logs !== null ? (
              <>
                {logs?.data?.map((item) => (
                  <ContentCard>
                    <div className="paragraf">
                      <span className="colorfull">{item.message}</span>
                    </div>
                    <div className="icons-and-date">
                      <span className="date">
                        {formatDate(item.created_at)}
                      </span>
                      {item?.added_by && (
                        <div className="active">{item?.added_by}</div>
                      )}
                    </div>
                  </ContentCard>
                ))}
              </>
            ) : (
              <Skeleton></Skeleton>
            )}
            <PaginationContainer>
              <Pagination
                showSizeChanger={false}
                onChange={onShowSizeChange}
                defaultCurrent={logs?.current_page}
                nextIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="8"
                    viewBox="0 0 6 8"
                    fill="none"
                  >
                    <path
                      d="M1.5 7L4.5 4L1.5 1"
                      stroke="#556373"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                prevIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="8"
                    viewBox="0 0 6 8"
                    fill="none"
                  >
                    <path
                      d="M4.5 7L1.5 4L4.5 1"
                      stroke="#556373"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                total={logs?.last_page && logs?.last_page * 10}
                className="pagination-div"
              />
            </PaginationContainer>
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  );
};

export default Activity;
