import React, { useState, useEffect } from "react";
import { FirstColumnContainer } from "./styles";
import GetLogo from "../../getlogo/getlogo";
import ColumnHeader from "./columnHeaderr/ColumnHeader";
import useGetData from "../../../hooks/useGetData";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import { FaRegCommentDots } from "react-icons/fa";
import { InstanceWithAuth } from "../../../App";

const FirstColumn = ({
  selectedFolder,
  onFolderSelect,
  isAllClosedSelected,
  setRefetchCount,
  openComposer,
  selectedEmailId,
  setSearchParm,
  department,
  setDepartment,
  sidebarCollapsed,
  setInitialEmails,
  searchText,
  setSearchText,
  setIsSearching,
  setDebouncedSearch,
}) => {
  const [menuItems, setMenuItems] = useState([]);
  const [isOnline, setIsOnline] = useState(null);
  const { app } = useSelector((state) => state);
  const { data: departments, error } = useGetData("support/departments");
  const { data: count, refetchData: refetchCount } = useGetData(
    `support/emails/count?department_id=${department?.id}`
  );
  const { auth } = useSelector((state) => state);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  // test
  useEffect(() => {
    if (departments && departments.data.length > 0) {
      setDepartment(departments.data[0]);
    }
  }, [departments]);

  useEffect(() => {
    if (setRefetchCount) {
      setRefetchCount(refetchCount);
    }
  }, [refetchCount, setRefetchCount]);

  useEffect(() => {
    if (count) {
      setMenuItems([
        {
          key: "8",
          icon: GetLogo("mailbox"),
          label: "Unassigned",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.unassigned,
        },
        {
          key: "9",
          icon: GetLogo("mailbox"),
          label: "Assigned to me",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.assigned_to_me,
        },
        {
          key: "5",
          icon: GetLogo("chat"),
          label: "All Open",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.all,
        },

        {
          key: "1",
          icon: GetLogo("mailbox"),
          label: "Inbox",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.inbox,
        },
        {
          key: "6",
          // icon: GetLogo("chat"),
          label: "Chat",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.chat,
        },

        {
          key: "2",
          icon: GetLogo("mailbox"),
          label: "Sent",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.inbox,
        },
        {
          key: "3",
          icon: GetLogo("reports"),
          label: "Spam",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.spam,
        },
        {
          key: "4",
          icon: GetLogo("delete"),
          label: "Trash",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.trash,
        },

        {
          key: "7",
          icon: GetLogo("mailbox"),
          label: "All Closed",
          className: "menu-item",
          isBadge: true,
          // count: count?.data?.closed,
        },
      ]);

      setIsInitialLoad(false);
    }
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchCount();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetchCount]);

  const getIconsForAllChat = (type) => {
    switch (type) {
      case "6":
        return <FaRegCommentDots color="#a4a6a6" size={16} />;
      default:
        return <FaRegCommentDots color="#a4a6a6" size={16} />;
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await InstanceWithAuth.get(
        `users/${auth?.userObj?.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setIsOnline(response.data?.user?.chat_status);
      }
    } catch (error) {
      console.error("Failed to fetch companies", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [auth?.userObj?.id]);

  return (
    <FirstColumnContainer>
      <Skeleton
        loading={isInitialLoad && (!departments || !count)}
        active
        style={{ margin: "10px", width: "auto" }}
      >
        <div>
          {app?.sidebarCollapsed && (
            <ColumnHeader
              department={department}
              setDepartment={setDepartment}
              departments={departments?.data}
              setSearchParm={setSearchParm}
              isOnline={isOnline}
              setIsOnline={setIsOnline}
              setInitialEmails={setInitialEmails}
              searchText={searchText}
              setSearchText={setSearchText}
              setIsSearching={setIsSearching}
              setDebouncedSearch={setDebouncedSearch}
            />
          )}

          {menuItems.map((item) => (
            <>
              {!app?.sidebarCollapsed ? (
                <>
                  <div
                    className={`small-sidebar-item ${
                      (selectedFolder === item.label && !isAllClosedSelected) ||
                      (item.label === "All closed" && isAllClosedSelected)
                        ? "active"
                        : ""
                    }`}
                    key={item.key}
                    onClick={() => onFolderSelect(item?.label, item?.key)}
                  >
                    <div className="right">
                      <span className="icon">
                        {item.icon ? item.icon : getIconsForAllChat(item?.key)}
                      </span>
                    </div>
                    {(selectedFolder === item.label && !isAllClosedSelected) ||
                    item.label === "All Open" ||
                    item.label === "Chat" ||
                    item.label === "Inbox" ||
                    item.label === "Unassigned" ||
                    item.label === "Assigned to me" ? (
                      <span className="left">{item.count}</span>
                    ) : null}
                  </div>
                </>
              ) : (
                <div
                  className={`sidebar-item ${
                    (selectedFolder === item.label && !isAllClosedSelected) ||
                    (item.label === "All closed" && isAllClosedSelected)
                      ? "active"
                      : ""
                  }`}
                  key={item.key}
                  onClick={() => onFolderSelect(item?.label, item?.key)}
                >
                  <div className="right">
                    <span className="icon">
                      {item.icon ? item.icon : getIconsForAllChat(item?.key)}
                    </span>
                    <span className="label">{item.label}</span>
                  </div>
                  {(selectedFolder === item.label && !isAllClosedSelected) ||
                  item.label === "All Open" ||
                  item.label === "Chat" ||
                  item.label === "Inbox" ||
                  item.label === "Unassigned" ||
                  item.label === "Assigned to me" ? (
                    <span className="left">{item.count}</span>
                  ) : null}
                </div>
              )}
            </>
          ))}
        </div>

        {app?.sidebarCollapsed && (
          <>
            <button onClick={openComposer} className="compose-button">
              Compose
            </button>
          </>
        )}
      </Skeleton>
    </FirstColumnContainer>
  );
};

export default FirstColumn;
