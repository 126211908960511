import React, { useState } from 'react'
import { Collapse, Skeleton } from 'antd'
import { Container, ContentContainer, CompanyCards } from './styles'
import { useNavigate } from 'react-router-dom'
import useGetData from '../../../../../hooks/useGetData'
import FormatDate from '../../../../../common/FormatDate'
import DataNotFound from '../../../../../common/DataNotFound'
import GetLogo from '../../../../getlogo/getlogo'
import { useDispatch } from 'react-redux'
import { handSelectCompanyFlowTab } from '../../../../../redux/app/appSlice'
import { Progress } from 'antd'
import './styles'
import StatusLabelButton from '../../../../statusLabelButton/StatusLabelButton'
const { Panel } = Collapse

const Mailbox = ({ userID, companyData }) => {
  const [collopasedKey, setCollopasedKey] = useState(false)
  const {
    data: mailBoxData,
    loading: mailBoxDataLoading,
    error: mailBoxDataError,
  } = useGetData(`virtual-mailbox-profiles?per_page=5000&company_id=${userID}`)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleRedirect = (companyId) => {
    dispatch(handSelectCompanyFlowTab('4'))
    navigate(`/companies/${companyId}`)
  }

  https: return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Mailbox" key="1" className="account-panel">
          <ContentContainer>
            {mailBoxDataLoading ? (
              <Skeleton active></Skeleton>
            ) : mailBoxData?.data?.length == 0 ? (
              <DataNotFound label={'No MailBox Found'} />
            ) : (
              mailBoxData?.data?.map((item) => (
                <CompanyCards>
                  <div className="company-labels">
                    <span className="office">
                      Mailbox #{item?.mailbox_number}
                    </span>
                    <div className="time-details">
                      <div className="btns">
                        <StatusLabelButton Data={item}></StatusLabelButton>
                        <button
                          className="view-btn"
                          onClick={() => handleRedirect(item?.company_id)}
                        >
                          <span className="btn-text">View</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="storage-FormatDate">
                    <p className="date">{FormatDate(item?.created_at)}</p>
                    <div className="td-storage">
                      <span className="storage">
                        Storage {item?.storage_used}/{item?.total_storage}
                      </span>
                      <Progress
                        className={`storage-progress ${
                          (item?.storage_used / item?.total_storage) * 100 >
                          90
                            ? 'full'
                            : ''
                        }`}
                        percent={
                          (item?.storage_used / item?.total_storage) * 100
                        }
                        size="small"
                        showInfo={false}
                      />
                    </div>
                  </div>
                </CompanyCards>
              ))
            )}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}

export default Mailbox
