import React, { useState } from "react";
import NameAvatar from "../../../common/NameAvatar";
import {
  FirstColumnContainer,
  IconLabelContainer,
  IconLabel,
  IconButtonGroup,
  IconBackground,
} from "./styles";
import GetLogo from "../../getlogo/getlogo";
import ColumnHeader from "./statusAndPipline/StatusAndPipline";
import { Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import StatusAndPipline from "./statusAndPipline/StatusAndPipline";
import { useDispatch } from "react-redux";
import { handleIsCommingFromTikcetDetail } from "../../../redux/app/appSlice";
import AllTabs from "./AllTabs/AllTabs";
import CustomTooltip from "../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import {
  FaPen,
  FaEnvelope,
  FaPhone,
  FaLaptop,
  FaCalendar,
  FaEllipsisH,
} from "react-icons/fa";
import moment from "moment";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";

const FirstColumn = ({
  ticket,
  refetchData,
  CallingFromTicketCenter,
  setOpenTicketDrawer,
  refetchTikcedBoardData,
  callingFromTicketDetails,
  setFechingEmployees,
  employees,
  showUserAndCompanyAvatar = false,
  handleShowUserDetails,
  setCallingFromTicketCenter,
}) => {
  const [selectedItem, setSelectedItem] = useState("1st Action");
  const navigate = useNavigate();

  const iconButtons = [
    { icon: FaPen, label: "Note" },
    { icon: FaEnvelope, label: "Email" },
  ];

  const formatTime = (time) => {
    const now = moment();
    const updatedAt = moment(time);

    if (now.diff(updatedAt, "minutes") < 60) {
      return `${now.diff(updatedAt, "minutes")} minutes`;
    } else if (now.diff(updatedAt, "hours") < 24) {
      return `${now.diff(updatedAt, "hours")} hours`;
    } else if (now.diff(updatedAt, "days") === 1) {
      return "Yesterday";
    } else {
      return updatedAt.format("MMM D");
    }
  };
  const dispatch = useDispatch();

  const handleRedirect = (cardId) => {
    window.open(`/tickets/${cardId}`, "_blank");
  };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/ticketcenter");
    }
  };

  return (
    <FirstColumnContainer style={{ border: CallingFromTicketCenter && "none" }}>
      <div className="make-row1">
        <span
          className="back"
          onClick={() => {
            CallingFromTicketCenter === true
              ? setOpenTicketDrawer(false)
              : handleGoBack();
          }}
        >
          <IoIosArrowBack size={14} color="#2b3746" />
        </span>
        <span className="company-id">
          {ticket?.type === "Ticket" ? "Ticket #" : "Task #"}
          {ticket?.number}
        </span>
        {CallingFromTicketCenter === true && (
          <span
            className="icon"
            onClick={() => {
              handleRedirect(ticket?.id);
            }}
          >
            <LuExternalLink size={17}></LuExternalLink>
          </span>
        )}
      </div>

      <span className="company-name">{ticket?.name}</span>
      {ticket?.is_closed == 1 ? (
        <span className="opened-time">Closed: {ticket?.closed_at}</span>
      ) : (
        <span className="opened-time">Open: {ticket?.opened_at}</span>
      )}
      <StatusAndPipline
        refetchData={refetchData}
        // pipelines={data?.pipelines}
        ticket={ticket}
        refetchTikcedBoardData={refetchTikcedBoardData}
        callingFromTicketDetails={callingFromTicketDetails}
      />
      {showUserAndCompanyAvatar ? (
        <div style={{ paddingLeft: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            {ticket?.contact?.avatar != "" ? (
              <CustomTooltip
                text={`${ticket?.contact?.first_name} ${ticket?.contact?.last_name}`}
                marginleft="3rem"
                marginTop="-30px"
              >
                <img
                  onClick={(e) =>
                    handleShowUserDetails(e, ticket?.contact?.id, "conatct")
                  }
                  src={ticket?.contact?.avatar}
                  alt="avatar"
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    // marginRight: '8px',
                    marginTop: "12px",
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip
                text={`${ticket?.contact?.first_name} ${ticket?.contact?.last_name}`}
                marginleft="3rem"
                marginTop="-10px"
              >
                <div
                  style={{
                    // marginRight: '8px',
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    handleShowUserDetails(e, ticket?.contact?.id, "conatct")
                  }
                >
                  <NameAvatar
                    val={20}
                    userName={`${ticket?.contact?.first_name} ${ticket?.contact?.last_name}`}
                    fontSize={12}
                  ></NameAvatar>
                </div>
              </CustomTooltip>
            )}

            {ticket?.company?.avatar != "" ? (
              <CustomTooltip
                text={ticket?.company?.name}
                marginleft="3rem"
                marginTop="-30px"
              >
                <img
                  onClick={(e) =>
                    handleShowUserDetails(e, ticket?.company?.id, "company")
                  }
                  src={ticket?.company?.avatar}
                  alt="avatar"
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    // marginRight: '8px',
                    marginTop: "12px",
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip
                text={ticket?.company?.name}
                marginleft="3rem"
                marginTop="-10px"
              >
                <div
                  style={{
                    // marginRight: '8px',
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    handleShowUserDetails(e, ticket?.company?.id, "company")
                  }
                >
                  <NameAvatar
                    val={20}
                    userName={ticket?.company?.name}
                    fontSize={12}
                  ></NameAvatar>
                </div>
              </CustomTooltip>
            )}
          </div>
        </div>
      ) : null}

      <AllTabs
        ticket={ticket}
        refetchData={refetchData}
        CallingFromTicketCenter={CallingFromTicketCenter}
        refetchTikcedBoardData={refetchTikcedBoardData}
        setOpenTicketDrawer={setOpenTicketDrawer}
        callingFromTicketDetails={callingFromTicketDetails}
        setFechingEmployees={setFechingEmployees}
        employees={employees}
      />
    </FirstColumnContainer>
  );
};

export default FirstColumn;
