import React, { useEffect, useRef, useState } from "react";
import {
  ComposerWrapper,
  Button1,
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
  selectedFolder,
} from "./styles";
import { IoClose } from "react-icons/io5";
import "react-quill/dist/quill.snow.css";
import { FaExpandAlt } from "react-icons/fa";
import { ImShrink2 } from "react-icons/im";
import { FiPaperclip } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Input, Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { CrossIcon } from "../../../../common/customPill/styles";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import useGetData from "../../../../hooks/useGetData";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

Quill.register("modules/emoji", Emoji);

const { Option } = Select;

const EmailComposer = ({
  visible,
  onClose,
  refetchEmails,
  callingFromInbox,
  contactData,
  callingFromCompany,
  callingFromContact,
  callingFromCompanyDetails,
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [from, setFrom] = useState("");
  const [toRecipients, setToRecipients] = useState([]);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [fetchedEmails, setFetchedEmails] = useState([]);
  const [sendingMail, setSendingMail] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { showMessage } = useGlobalAlertMessage();
  const { auth } = useSelector((state) => state);
  const [searchValue, setSearchValue] = useState("");
  const [isOpenTo, setIsOpenTo] = useState(false);
  const [isOpenCc, setIsOpenCc] = useState(false);
  const [isOpenBcc, setIsOpenBcc] = useState(false);

  const toSelectRef = useRef(null);
  const ccSelectRef = useRef(null);
  const bccSelectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toSelectRef.current && !toSelectRef.current.contains(event.target)) {
        setTimeout(() => setIsOpenTo(false), 300);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ccSelectRef.current && !ccSelectRef.current.contains(event.target)) {
        setTimeout(() => setIsOpenCc(false), 300);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        bccSelectRef.current &&
        !bccSelectRef.current.contains(event.target)
      ) {
        setTimeout(() => setIsOpenBcc(false), 300);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileSelect = (files) => {
    setSelectedFiles(files);
  };

  const {
    data: sendersData,
    loading: sendersDataLoading,
    refetchData: refetchsendersData,
  } = useGetData(`support/emails/senders`);

  const handleInputChange = (name, value) => {
    if (name === "message") setMessage(value);
    if (name === "subject") setSubject(value);
  };

  useEffect(() => {
    if (sendersData?.data) {
      const supportEmail = sendersData.data.find(
        (item) => item.alias === "support"
      )?.email;
      setFrom(supportEmail || sendersData.data[0]?.email);
    }
  }, [sendersData]);

  const handleSend = async () => {
    const cleanedMessage = message.replace(/<p>(\s|<br>)*<\/p>$/, "").trim();

    setSendingMail(true);
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message_body", cleanedMessage);
    formData.append("from_email", from);

    toRecipients.forEach((recipient, index) =>
      formData.append(`to[${index}]`, recipient)
    );
    ccRecipients.forEach((recipient, index) =>
      formData.append(`cc[${index}]`, recipient)
    );
    bccRecipients.forEach((recipient, index) =>
      formData.append(`bcc[${index}]`, recipient)
    );

    Array.from(selectedFiles).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/compose`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        if (callingFromInbox) {
          refetchEmails();
        }
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
        setToRecipients([]);
        setCcRecipients([]);
        setBccRecipients([]);
        setSubject("");
        setMessage("");
        setSelectedFiles([]);
        setShowCc(false);
        setShowBcc(false);
        onClose();
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    } finally {
      setSendingMail(false);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    handleFileSelect(files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleEditorChange = (content) => {
    setMessage(content);
    handleInputChange("message", content);
  };

  const handleKeyPress = (event, type) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const inputValue = event.target.value.trim().toLowerCase(); // Normalize input

    if (event.key === "Enter" && emailRegex.test(inputValue)) {
      const addEmail = (list, setList) => {
        // Normalize existing emails for comparison
        const normalizedList = list.map((email) => email.trim().toLowerCase());

        if (normalizedList.includes(inputValue)) {
          console.error(
            "Duplicate email detected in the same field:",
            inputValue
          );
          return; // Prevent adding duplicate email
        }

        const newList = [...list, inputValue];
        console.log("Updated email list for type:", type, newList); // Debugging step
        setList(newList);
      };

      // Check which type of recipient to update
      if (type === "to") addEmail(toRecipients, setToRecipients);
      if (type === "cc") addEmail(ccRecipients, setCcRecipients);
      if (type === "bcc") addEmail(bccRecipients, setBccRecipients);

      // Clear the input field
      setSearchValue("");
      setTimeout(() => {
        event.target.value = "";
      }, 100);
    }
  };

  const extractEmails = (formattedRecipients) => {
    // Use a regular expression to extract emails from the formatted string
    return formattedRecipients.map((recipient) => {
      const match = recipient.match(
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
      );
      return match ? match[0] : recipient; // Return the email if found, else fallback to the string
    });
  };

  const handleSelectChange = (value, type) => {
    setSearchValue("");
    const emailsOnly = extractEmails(value);

    const uniqueEmails = (emails) => [
      ...new Set(emails.map((email) => email.trim().toLowerCase())),
    ];

    switch (type) {
      case "to":
        setToRecipients(uniqueEmails(emailsOnly)); // Deduplicate before updating state
        break;
      case "cc":
        setCcRecipients(uniqueEmails(emailsOnly));
        break;
      case "bcc":
        setBccRecipients(uniqueEmails(emailsOnly));
        break;
      case "from":
        setFrom(value);
        break;
      default:
        break;
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleSearch = async (searchTerm, type) => {
    switch (type) {
      case "to":
        setIsOpenTo(true);
        break;
      case "cc":
        setIsOpenCc(true);
        break;
      case "bcc":
        setIsOpenBcc(true);
        break;

      default:
        break;
    }

    setSearchValue(searchTerm);
    if (!searchTerm) return;

    if (searchTerm === "") {
      setFetchedEmails([]);
    }

    const formData = new FormData();
    formData.append("search", searchTerm);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/suggestion_emails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.data?.status === "success") {
        setFetchedEmails(response?.data?.data?.filter(Boolean));
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  useEffect(() => {
    if (callingFromCompany) {
      setToRecipients([contactData?.contact?.starthub_app_email_login]);
    }
    if (callingFromContact) {
      setToRecipients([contactData?.email]);
    }
    if (callingFromCompanyDetails) {
      setToRecipients([contactData?.dashboard_login_email]);
      setSubject("Important: Please Update Your Payment Method");
      setMessage(`
  <p>Dear ${contactData?.contact?.first_name},</p>

   <br>
  
  <p>
    I hope you're doing well! To keep everything running smoothly, please take a moment to update your payment method by clicking the link below:
  </p>
  
    <br>


  <p>
  👉
    <a href="${contactData?.update_payment_link}" target="_blank">
       Update Payment Method
    </a>
  </p>
  
  <br>
  
  <p>
    If you have any questions or need a hand with anything, just let us know—we’re happy to help.
  </p>
  
  <p>Thanks so much for taking care of this!</p>

  <br>

  <p>Best,</p>
  
  <p>${auth?.userObj?.first_name} ${auth?.userObj?.last_name}</p>
`);
    }
  }, [contactData]);

  const mergeEmails = () => {
    let formattedSender;
    if (callingFromCompany) {
      const senderEmail = contactData?.contact?.starthub_app_email_login;
      const senderName =
        contactData &&
        `${contactData?.contact?.first_name || ""} ${
          contactData?.contact?.last_name || ""
        }`.trim();

      formattedSender = senderEmail;
    }
    if (callingFromContact) {
      const senderEmail = contactData?.email;
      formattedSender = senderEmail;
    }

    const formattedFetchedEmails = fetchedEmails.map((contact) => {
      const { first_name, last_name, email, company_name } = contact;

      const namePart =
        first_name && last_name ? `${first_name} ${last_name}` : "";

      return namePart
        ? `${namePart} (${email})${company_name ? ` (${company_name})` : ""}`
        : `${email}${company_name ? ` (${company_name})` : ""}`;
    });
    if (!callingFromInbox) {
      const mergedEmails = [
        ...new Set([formattedSender, ...formattedFetchedEmails]),
      ].filter(Boolean);

      return mergedEmails;
    } else {
      const mergedEmails = [...new Set([...formattedFetchedEmails])].filter(
        Boolean
      );

      return mergedEmails;
    }
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  if (!visible) return null;

  const toggleFullScreen = () => {
    setFullScreen((prev) => !prev);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: "1000",
        overflow: "hidden",
        bottom: 0,
        right: 0,
      }}
    >
      <ComposerWrapper fullScreen={fullScreen}>
        <Header className="draggable-handle">
          <HeaderTitle>Email</HeaderTitle>
          <div className="make-row">
            <Button1 onClick={toggleFullScreen}>
              {fullScreen ? <ImShrink2 /> : <FaExpandAlt />}
            </Button1>
            <Button1 onClick={onClose}>
              <CrossIcon />
            </Button1>
          </div>
        </Header>
        <Body>
          <MessageBodyContainer fullScreen={fullScreen}>
            <div className="main-div">
              <div className="left-div" ref={toSelectRef}>
                <span className="name">To</span>
                <Select
                  mode="multiple"
                  suffixIcon={
                    <MdOutlineKeyboardArrowDown
                      size={19}
                      fill="rgba(85, 99, 115, 0.6)"
                    ></MdOutlineKeyboardArrowDown>
                  }
                  autoClearSearchValue={true}
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Enter or choose recipients"
                  value={toRecipients}
                  onChange={(value) => {
                    handleSelectChange(value, "to");
                  }}
                  onSearch={(value) => handleSearch(value, "to")}
                  open={isOpenTo}
                  onSelect={() => {
                    setIsOpenTo(false);
                  }}
                  onFocus={() => {
                    setIsOpenTo(true);
                  }}
                  tokenSeparators={[",", ";"]}
                  notFoundContent={<span>Press enter to add this email</span>}
                  onInputKeyDown={(e) => {
                    if (e.key !== " ") {
                      handleKeyPress(e, "to");
                    }
                  }}
                  searchValue={searchValue}
                >
                  {mergeEmails(fetchedEmails).map((recipient) => (
                    <Option key={recipient} value={recipient}>
                      {recipient}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="right-div">
                <span className="name" onClick={() => setShowCc(!showCc)}>
                  Cc
                </span>
                <span className="name" onClick={() => setShowBcc(!showBcc)}>
                  Bcc
                </span>
              </div>
            </div>

            {/* Cc Recipients Field */}
            {showCc && (
              <div className="main-div">
                <div className="left-div" ref={ccSelectRef}>
                  <span className="name">Cc</span>
                  <Select
                    mode="multiple"
                    suffixIcon={
                      <MdOutlineKeyboardArrowDown
                        size={19}
                        fill="rgba(85, 99, 115, 0.6)"
                      ></MdOutlineKeyboardArrowDown>
                    }
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Enter or choose Cc recipients"
                    value={ccRecipients}
                    onChange={(value) => handleSelectChange(value, "cc")}
                    onSearch={(value) => handleSearch(value, "cc")}
                    tokenSeparators={[",", ";"]}
                    notFoundContent={<span>Press enter to add this email</span>}
                    onInputKeyDown={(e) => {
                      if (e.key !== " ") {
                        handleKeyPress(e, "cc");
                      }
                    }}
                    searchValue={searchValue}
                    open={isOpenCc}
                    onSelect={() => {
                      setIsOpenCc(false);
                    }}
                    onFocus={() => {
                      setIsOpenCc(true);
                    }}
                  >
                    {mergeEmails(fetchedEmails).map((recipient) => (
                      <Option key={recipient} value={recipient}>
                        {recipient}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            {/* Bcc Recipients Field */}
            {showBcc && (
              <div className="main-div">
                <div className="left-div" ref={bccSelectRef}>
                  <span className="name">Bcc</span>
                  <Select
                    mode="multiple"
                    suffixIcon={
                      <MdOutlineKeyboardArrowDown
                        size={19}
                        fill="rgba(85, 99, 115, 0.6)"
                      ></MdOutlineKeyboardArrowDown>
                    }
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Enter or choose Bcc recipients"
                    value={bccRecipients}
                    onChange={(value) => handleSelectChange(value, "bcc")}
                    onSearch={(value) => handleSearch(value, "bcc")}
                    tokenSeparators={[",", ";"]}
                    notFoundContent={<span>Press enter to add this email</span>}
                    onInputKeyDown={(e) => {
                      if (e.key !== " ") {
                        handleKeyPress(e, "bcc");
                      }
                    }}
                    searchValue={searchValue}
                    open={isOpenBcc}
                    onSelect={() => {
                      setIsOpenBcc(false);
                    }}
                    onFocus={() => {
                      setIsOpenBcc(true);
                    }}
                  >
                    {mergeEmails(fetchedEmails).map((recipient) => (
                      <Option key={recipient} value={recipient}>
                        {recipient}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className="main-div">
              <div className="left-div">
                <span className="name">From</span>
                <Select
                  suffixIcon={
                    <MdOutlineKeyboardArrowDown
                      size={19}
                      fill="rgba(85, 99, 115, 0.6)"
                    ></MdOutlineKeyboardArrowDown>
                  }
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Enter or choose a sender"
                  value={from}
                  onChange={(value) => handleSelectChange(value, "from")}
                  notFoundContent={<span>Press enter to add this email</span>}
                  onInputKeyDown={(e) => {
                    if (e.key !== " ") {
                      handleKeyPress(e, "from");
                    }
                  }}
                >
                  {sendersData?.data?.map((sender) => (
                    <Option key={sender?.email} value={sender?.email}>
                      {sender?.email}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="main-div">
              <div className="left-div">
                <span className="name">Subject</span>
                <Input
                  mode="multiple"
                  allowClear
                  value={subject}
                  type="text"
                  style={{ width: "100%" }}
                  placeholder="Enter subject"
                  className="input"
                  onChange={(e) => handleInputChange("subject", e.target.value)}
                ></Input>
              </div>
            </div>

            {/* Quill Editor */}
            <div className="text-area-wrapper">
              <ReactQuill
                name="message"
                className="quill-editor"
                theme="snow"
                value={message}
                onChange={handleEditorChange}
                placeholder="Type your message here..."
                modules={{
                  toolbar: {
                    container: "#quill-toolbarid",
                    "emoji-toolbar": true,
                    "emoji-textarea": true,
                    "emoji-shortname": true,
                  },
                }}
                // onKeyDown={(event) => {
                //   if (event.key === "Enter") {
                //     if (event.shiftKey) {
                //       return;
                //     }
                //     if (message.trim()) {
                //       event.preventDefault();
                //       handleSend();
                //     } else {
                //       event.preventDefault();
                //     }
                //   }
                // }}
              />
            </div>

            {/* Attachment List */}
            <AttachmentList>
              {selectedFiles.map((file, index) => (
                <AttachmentItem key={index}>
                  <TruncateText text={file.name} />
                  <span className="file-size">
                    ({(file.size / 1024).toFixed(1)}kb)
                  </span>
                  <RemoveIcon onClick={() => handleRemoveFile(index)}>
                    <IoClose />
                  </RemoveIcon>
                </AttachmentItem>
              ))}
            </AttachmentList>

            {/* Buttons */}
            <ButtonsWrapper>
              <div id="quill-toolbarid" className="custom-toolbar">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-link"></button>
              </div>

              <Button onClick={handleFileClick}>
                <FiPaperclip />
                <input
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                />
              </Button>
              <Button
                className="send-button"
                onClick={handleSend}
                disabled={sendingMail || message === ""}
              >
                {sendingMail ? "Sending..." : "Send"}
              </Button>
            </ButtonsWrapper>
          </MessageBodyContainer>
        </Body>
      </ComposerWrapper>
    </div>
  );
};

export default EmailComposer;
