import React, { useEffect, useRef, useState } from "react";
import ChatContainer from "./styles";
import ChatMessage from "../chatMessage/ChatMessage";
import Comment from "../comment/Comment";
import moment from "moment";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";

function MailsAndComments({
  email,
  currentEmployeeImage,
  selectedEmailId,
  notes,
  refetchNotes,
  onEdit,
  handleForwardMail,
  handleReplyEmail,
  loadingEmailThread,
  setSelectedTab,
  isInitialLoadThread,
  setIsInitialLoad,
  setIsInitialLoadThread,
}) {
  const chatContainerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const { messageId } = useParams();
  const messageRefs = useRef({});
  const { threads, activities } = email?.data || {};
  const combineThreadsAndActivities = () => {
    const combined = [];

    Object.keys(threads || {}).forEach((date) => {
      threads[date]?.forEach((thread) => {
        combined.push({
          type: "message",
          content: thread,
          timestamp: thread.created_at,
        });
      });
    });

    activities?.forEach((activity) => {
      combined.push({
        type: "activity",
        content: activity,
        timestamp: activity.created_at,
      });
    });

    return combined.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  };
  const combinedData = combineThreadsAndActivities();

  const getAllDataSorted = () => {
    const notesData =
      notes?.data?.map((note) => ({
        type: "note",
        content: note,
        timestamp: note.created_at,
      })) || [];

    return [...combinedData, ...notesData].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  };

  const allData = getAllDataSorted();
  // Scroll behavior
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(chatContainer.scrollTimeout);
      chatContainer.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    chatContainer.addEventListener("scroll", handleScroll);

    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to specific message if `messageId` exists
  useEffect(() => {
    if (messageId && messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [messageId, email]);

  // Reset `isInitialLoadThread` when `email.data` updates
  useEffect(() => {
    if (email?.data) {
      setIsInitialLoadThread(false);
    }
  }, [email?.data]);

  // Scroll to bottom when `allData` updates
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [allData.length, isInitialLoadThread]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [notes]);

  // Combine email threads and activities

  // Merge notes with other data
  

  // Ensure hooks are placed before any return statements
  if (!notes) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: "10px", width: "auto" }}
      />
    );
  }

  if (!email || !email?.data?.threads) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: "10px", width: "auto" }}
      />
    );
  }

  // Formatting helpers
  const formatTime = (time) => moment(time).format("ddd, MMM D, YYYY, h:mm A");
  const formatTime1 = (time) => moment(time).format("h:mm A");
  const formatActivityMessage = (activity) =>
    `${activity.activity} on ${moment(activity.created_at).format(
      "MMM D, YYYY h:mm A"
    )}`;

  return (
    <ChatContainer
      ref={chatContainerRef}
      className={isScrolling ? "scrolling" : ""}
    >
      {isInitialLoadThread ? (
        <Skeleton
          rows={10}
          paragraph={{ rows: 7 }}
          active
          style={{ margin: "10px", width: "100%" }}
        />
      ) : (
        <>
          {allData.map((item, index) => (
            <div
              key={index}
              ref={(el) => {
                if (item.content.id) {
                  messageRefs.current[item.content.id] = el;
                }
              }}
            >
              {item.type === "message" ? (
                <ChatMessage
                  emailData={email?.data}
                  message={item.content.email_body}
                  sender={item.content.sender}
                  timestamp={formatTime(item.timestamp)}
                  time={formatTime1(item.timestamp)}
                  imageName={"user"}
                  currentEmployeeImage={currentEmployeeImage}
                  attachments={item.content.attachments}
                  sender_avatar={item.content.sender_avatar}
                  handleForwardMail={handleForwardMail}
                  handleReplyEmail={handleReplyEmail}
                  setSelectedTab={setSelectedTab}
                  mailDetails={item}
                  recipients={item.content.recipients}
                  ccEmails={item?.content?.cc_emails}
                  setIsInitialLoad={setIsInitialLoad}
                />
              ) : item.type === "activity" ? (
                <div className="activity">
                  <span>{formatActivityMessage(item.content)}</span>
                </div>
              ) : (
                <Comment
                  key={item.content.id}
                  note={item.content}
                  selectedEmailId={selectedEmailId}
                  refetchNotes={refetchNotes}
                  onEdit={onEdit}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </div>
          ))}
        </>
      )}
    </ChatContainer>
  );
}

export default MailsAndComments;
