import React, { useEffect, useState } from "react";
import { Collapse, Skeleton, Drawer, Form, Popconfirm } from "antd";
import {
  Container,
  ContentContainer,
  StyledHeader,
  CompanyCards,
} from "./styles";

import GetLogo from "../../../../getlogo/getlogo";
import { useSelector } from "react-redux";
import useGetData from "../../../../../hooks/useGetData";
import dayjs from "dayjs";
import { InstanceWithAuth } from "../../../../../App";
import { useGlobalAlertMessage } from "../../../../../hooks/useAlertMessage";
import DataNotFound from "../../../../../common/DataNotFound";
import { useNavigate } from "react-router-dom";
import "./styles";
import FormatDate from "../../../../../common/FormatDate";
import _ from "lodash";
import Spinner from "../../../../../common/Spinner";
import moment from "moment";
import CreateMeetingForm from "../../../../meetingCalender/createMeetingForm/CreateMeetingForm";
import EditMeetingForm from "../../../../meetingCalender/createMeetingForm/EditMeetingForm";
const { Panel } = Collapse;
export default function UserMeetingListing({
  userID,
  userData,
  isCompany = false,
}) {
  const [collopasedKey, setCollopasedKey] = useState(false);
  const [timeSearchParam, setTimeSearchParam] = useState("Last 6 months");
  const [selectedCompnayId, setSelectedCompnayId] = useState();
  const [companySearchParam, setCompanySearchParam] = useState("All Companies");
  const { app, auth } = useSelector((state) => state);
  const [form] = Form.useForm();
  const [Addform] = Form.useForm();
  const [Editform] = Form.useForm();
  const [selectedFormLoaction, setSelectedFromLoaction] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [meetingDetails, setMeetingDetails] = useState();
  const [showEditMeetingFrom, setShowEditMeetingFrom] = useState(false);
  const [isMeetingCancel, setIsMeetingCancel] = useState(0);
  const [updateingData, setUpdateingData] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCancelMeeting, setIsCancelMeeting] = useState(false);
  const { showMessage } = useGlobalAlertMessage();
  const [initialCreateMeetingValues, setInitialCreateMeetingValues] = useState(
    {}
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [enableSaveBtn, setEnableBtn] = useState(false);
  const holidays = [];
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
    setSelectedFromLoaction(userData?.data?.location_id);
    Addform.setFieldsValue({
      user: isCompany ? userID : userData?.data?.companies[0]?.id,
    });
  };
  const onClose = () => {
    setOpen(false);
    setShowEditMeetingFrom(false);
    setMeetingDetails();
    setShowSaveBtn(false);
    Editform.resetFields();
    Addform.resetFields();
  };

  function getCurrentDateFormatted() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    // refetchmeetingsData()
  }, [userData]);

  const [fromTime] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
  const {
    data: meetingRoomsData,
    refetchData: refetchmeetingsData,
    loading,
    error,
  } = useGetData(
    isCompany
      ? `meeting-events/loaction/${
          userData?.data?.location_id
        }?from=${fromTime}&to=${""}&company_id=${userData?.data?.id}`
      : `meeting-events/loaction/${
          userData?.data?.location_id
        }?from=${fromTime}&to=${""}&contact_id=${userID}`
  );
  console.log(userData, "userData in from");
  const {
    data: locationData,
    loading: locationDataLoading,
    error: locationDataError,
  } = useGetData("locations/dropdown/get");
  const {
    data: meetinDetailsData,
    loading: meetinDetailsDataLoading,
    error: meetinDetailsDataError,
  } = useGetData(`meeting-events/${meetingDetails?.meetingId}`);
  const handleDownload = (publicUrl) => {
    window.open(publicUrl, "_blank");
  };
  const handelEditMeeting = (value) => {
    setShowEditMeetingFrom(true);
    setOpen(true);
    setMeetingDetails(value);
  };
  const handleFormItemChange = (changedValues, allValues) => {
    const keys = Object.keys(changedValues);
    const fieldsToIgnore = ["DashboardLoginEmail"];
    const isIgnoredFieldChanged = keys.every((key) =>
      fieldsToIgnore.includes(key)
    );

    if (isIgnoredFieldChanged) {
      setShowSaveBtn(false);
    } else {
      const filteredInitialValues = _.omit(initialValues, fieldsToIgnore);
      const filteredAllValues = _.omit(allValues, fieldsToIgnore);
      const isChanged = !_.isEqual(filteredInitialValues, filteredAllValues);

      setShowSaveBtn(isChanged);
      console.log(isChanged, "isChanged");
    }
  };
  const handleCreateFormItemChange = (changedValues, allValues) => {
    const keys = Object.keys(changedValues);
    const fieldsToIgnore = ["quantity", "DashboardLoginEmail"];
    const isIgnoredFieldChanged = keys.every((key) =>
      fieldsToIgnore.includes(key)
    );
    console.log(changedValues, "changedValues");
    console.log(initialValues, "initialValues");
    console.log(allValues, "allValues");
    if (isIgnoredFieldChanged) {
      setEnableBtn(false);
      setShowSaveBtn(false);
    } else {
      const filteredInitialValues = _.omit(
        initialCreateMeetingValues,
        fieldsToIgnore
      );
      const filteredAllValues = _.omit(allValues, fieldsToIgnore);
      const isChanged = !_.isEqual(filteredInitialValues, filteredAllValues);

      setEnableBtn(isChanged);
      setShowSaveBtn(isChanged);
      console.log(isChanged, "isChanged");
    }
  };
  const handleSubmit = async () => {
    setUpdateingData(true);
    try {
      const values = await Addform.validateFields();
      const formData = new FormData();

      formData.append("company_id", values?.user);
      formData.append("location_id", values?.Location);
      formData.append("meeting_room_id", values?.meetingRoom);
      formData.append(
        "booking_date",
        values?.bookinDate
          ? dayjs(values?.bookinDate).format("YYYY-MM-DD")
          : null
      );

      formData.append(
        "booking_start",
        values?.bookinDate && values?.startTime
          ? dayjs(values?.bookinDate)
              .hour(dayjs(values?.startTime).hour())
              .minute(dayjs(values?.startTime).minute())
              .second(0)
              .format("YYYY-MM-DD HH:mm:ss")
          : null
      );

      formData.append(
        "booking_end",
        values?.bookinDate && values?.endTime
          ? dayjs(values?.bookinDate)
              .hour(dayjs(values?.endTime).hour())
              .minute(dayjs(values?.endTime).minute())
              .second(0)
              .format("YYYY-MM-DD HH:mm:ss")
          : null
      );
      formData.append("title", values?.title);
      formData.append("description", values?.notes);
      formData.append("status", 1);
      formData.append("payment_option", values?.paymentOption);
      formData.append(
        "credit_quantity",
        values?.quantity ? values?.quantity : ""
      );
      formData.append("cancelation_reason", "");
      formData.append("credits_used", "");
      const response = await InstanceWithAuth.post("meeting-events", formData, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        refetchmeetingsData();
        setUpdateingData(false);
        onClose();
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      setUpdateingData(false);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  const updateMeeting = async (cancel) => {
    setUpdateingData(cancel != 1 ? true : false);
    setIsCancelMeeting(cancel == 1 ? true : false);
    try {
      const values = await Editform.validateFields();
      const formData = new FormData();
      console.log(values, "values");
      formData.append(
        "booking_date",
        values?.bookinDate
          ? dayjs(values?.bookinDate).format("YYYY-MM-DD")
          : null
      );
      formData.append("meeting_room_id", values?.meetingRoom);
      formData.append(
        "booking_start",
        values?.bookinDate && values?.startTime
          ? dayjs(values?.bookinDate)
              .hour(dayjs(values?.startTime).hour())
              .minute(dayjs(values?.startTime).minute())
              .second(0)
              .format("YYYY-MM-DD HH:mm:ss")
          : null
      );

      formData.append(
        "booking_end",
        values?.bookinDate && values?.endTime
          ? dayjs(values?.bookinDate)
              .hour(dayjs(values?.endTime).hour())
              .minute(dayjs(values?.endTime).minute())
              .second(0)
              .format("YYYY-MM-DD HH:mm:ss")
          : null
      );
      formData.append("title", values?.title);
      formData.append("description", values?.notes);
      formData.append("payment_option", values?.paymentOption);
      formData.append("is_cancelled", cancel);
      formData.append("cancelation_reason", values?.cancelationReason);
      const response = await InstanceWithAuth.post(
        `meeting-events/${meetingDetails?.meetingId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        refetchmeetingsData();
        onClose();
        setUpdateingData(false);
        setIsCancelMeeting(false);
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      setUpdateingData(false);
      setIsCancelMeeting(false);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  const headerStyles = {
    display: "flex",
    padding: "24px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    borderBottom: "1px solid #DFEBEF",
    background: "#FFF",
    boxShadow: "0px 4px 20px 0px rgba(203, 222, 228, 0.37)",
  };
  const DrawerHeader = () => (
    <StyledHeader>
      <div>
        {showEditMeetingFrom &&
        new Date(meetinDetailsData?.data?.booking_start) < new Date() ? (
          <span>Booking details #{meetingDetails?.meetingId}</span>
        ) : showEditMeetingFrom &&
          new Date(meetinDetailsData?.data?.booking_start) > new Date() ? (
          <span>Edit Booking</span>
        ) : !showEditMeetingFrom ? (
          <span>Create Booking</span>
        ) : null}
      </div>

      {showEditMeetingFrom &&
      new Date(meetinDetailsData?.data?.booking_start) < new Date() ? (
        <button
          style={{
            border: "2px solid #A1DC81",
            color: "#A1DC81",
            backgroundColor: "transparent",
            borderRadius: "20px",
            padding: "5px 15px",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          Completed
        </button>
      ) : null}

      {showEditMeetingFrom &&
      new Date(meetinDetailsData?.data?.booking_start) > new Date() ? (
        <div style={{ cursor: "pointer" }}>
          {showEditMeetingFrom ? (
            <div style={{ display: "flex", gap: "5px" }}>
              <Popconfirm
                icon={null}
                description="Are you sure you want to cancel the booking?"
                onConfirm={() => updateMeeting(1)}
                okText="Yes"
                cancelText="No"
              >
                <button className="cancel-btn">
                  <span className="btn-text">
                    {isCancelMeeting ? (
                      <Spinner color="#4cbbcb"></Spinner>
                    ) : (
                      "Cancel Booking"
                    )}
                  </span>
                </button>
              </Popconfirm>
              <button
                disabled={showSaveBtn ? false : true}
                className={showSaveBtn ? "save-btn" : "save-btn-disabled"}
                onClick={
                  showEditMeetingFrom ? () => updateMeeting(0) : handleSubmit
                }
              >
                <span className="btn-text">
                  {updateingData ? <Spinner></Spinner> : "Save"}
                </span>
              </button>
            </div>
          ) : updateingData ? (
            <Spinner color="#4cbbcb"></Spinner>
          ) : (
            <button
              disabled={showSaveBtn ? false : true}
              className={showSaveBtn ? "save-btn" : "save-btn-disabled"}
              onClick={
                showEditMeetingFrom ? () => updateMeeting(0) : handleSubmit
              }
            >
              <span className="btn-text">
                {updateingData ? <Spinner></Spinner> : " Create"}
              </span>
            </button>
          )}
        </div>
      ) : !showEditMeetingFrom ? (
        <div
          style={{ cursor: "pointer" }}
          onClick={showEditMeetingFrom ? () => updateMeeting(0) : handleSubmit}
        >
          {showEditMeetingFrom ? (
            <button
              disabled={showSaveBtn ? false : true}
              className={showSaveBtn ? "save-btn" : "save-btn-disabled"}
            >
              <span className="btn-text">
                {updateingData ? <Spinner></Spinner> : "Save"}
              </span>
            </button>
          ) : updateingData ? (
            <Spinner color="#4cbbcb"></Spinner>
          ) : (
            <button
              disabled={showSaveBtn ? false : true}
              className={showSaveBtn ? "save-btn" : "save-btn-disabled"}
            >
              <span className="btn-text">
                {updateingData ? <Spinner></Spinner> : " Create"}
              </span>
            </button>
          )}
        </div>
      ) : null}
    </StyledHeader>
  );
  const formatEvent = (meeting) => ({
    title: meeting.title || "Untitled Meeting",
    start: new Date(meeting.booking_start),
    end: new Date(meeting.booking_end),
    time: `${new Date(meeting.booking_start).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })} - ${new Date(meeting.booking_end).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })}`,
    name: meeting?.booked_by,
    company: meeting?.user?.company_name,
    description: meeting?.description,
    meetingId: meeting?.id,
    user: meeting?.user?.contact_name,
    location: locationData?.data?.find(
      (location) => location?.key === meeting?.location_id
    ),
    contactId: meeting?.contact_id,
    companyId: meeting?.company_id,
    companyAvatar: meeting?.user?.company_avatar,
    userAvatar: meeting?.user?.contact_avatar,
    eventColor: meeting?.event_color,
    meetingRoom: meeting?.meetingroom_name,
  });

  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Meetings" key="1" className="account-panel">
          <ContentContainer>
            {loading ? (
              <Skeleton active />
            ) : meetingRoomsData?.data?.length == 0 ? (
              <DataNotFound label={"No Meeting Found"}></DataNotFound>
            ) : (
              meetingRoomsData?.data?.map((event) => (
                <CompanyCards>
                  <div className="company-labels">
                    <span className="office">{event.title}</span>
                    <div className="time-details">
                      <div className="btns">
                        <button
                          className="view-btn"
                          onClick={() => handelEditMeeting(formatEvent(event))}
                        >
                          <span
                            className="btn-text"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            {GetLogo("edit")}Edit
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="storage-FormatDate">
                    <p className="date">{FormatDate(event?.booking_start)}</p>
                    {new Date(event.booking_start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}{" "}
                    -{" "}
                    {new Date(event.booking_end).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </div>
                </CompanyCards>
              ))
            )}
          </ContentContainer>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <button className="view-all" onClick={showDrawer}>
              Create Meeting
            </button>
          </div>
        </Panel>
      </Collapse>
      <Drawer
        title={<DrawerHeader></DrawerHeader>}
        onClose={onClose}
        open={open}
        closable={null}
        width={"423px"}
        maskClosable={null}
        headerStyle={headerStyles}
        closeIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
          >
            <path
              d="M23.5 16H8.5"
              stroke="#ABC0C7"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.5 21L8.5 16L13.5 11"
              stroke="#ABC0C7"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
        className="custom-drawer"
        style={{ backgroundColor: "#F5F9FA" }}
      >
        {showEditMeetingFrom ? (
          <EditMeetingForm
            key={userID}
            form={Editform}
            meetingDetails={meetingDetails}
            isMeetingCancel={isMeetingCancel}
            setIsMeetingCancel={setIsMeetingCancel}
            holidays={holidays}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            handleFormItemChange={handleFormItemChange}
          ></EditMeetingForm>
        ) : (
          <CreateMeetingForm
            key={userID}
            form={Addform}
            selectedDate={selectedDate}
            holidays={holidays}
            selectedLocation={selectedLocation}
            app={app}
            selectedFormLoaction={userData?.data?.location_id}
            setSelectedFromLoaction={setSelectedFromLoaction}
            setUser={true}
            defaultUser={isCompany ? userID : userData?.data?.companies[0]?.id}
            setInitialCreateMeetingValues={setInitialCreateMeetingValues}
            handleCreateFormItemChange={handleCreateFormItemChange}
          ></CreateMeetingForm>
        )}
      </Drawer>
    </Container>
  );
}
