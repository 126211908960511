import styled from "styled-components";
const MainContiner = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 19px;
  align-self: stretch;
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid #e9f3f5;
  background: rgba(239, 245, 246, 0.5);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .continer {
    display: flex;
    width: 156.667px;
    align-items: flex-start;
    gap: 12px;
  }
  span {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 126.094%; /* 22.697px */
    letter-spacing: -0.63px;
  }
  svg {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const InfoBlock = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 16px;
  background: #fff;
  div {
    display: flex;
    height: 18px;
    padding-right: 8px;
    align-items: center;
    gap: 8px;
    border-right: 1px solid var(--Gray-20, rgba(85, 99, 115, 0.2));
    p {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));

      /* P1 */
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
    }
  }
`;

const Number = styled.span`
  color: var(--Blue-100, #4cbbcb);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.63px;
`;

const Link = styled.a`
  color: var(--Dark-Gray-100, #2b3746);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  margin: 3px;
`;

export { MainContiner, Card, Header, InfoContainer, InfoBlock, Number, Link };
