import React, { useEffect, useState } from "react";
import { Collapse, Form, Input, Select } from "antd";
import {
  Container,
  ContentContainer,
  StyledButton,
  StyledForm,
  StyledContainer,
} from "./styles";
import useGetData from "../../../../../hooks/useGetData";
import TextArea from "antd/es/input/TextArea";
import { InstanceWithAuth } from "../../../../../App";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import FormatDate from "../../../../../common/FormatDate";
import { useGlobalAlertMessage } from "../../../../../hooks/useAlertMessage";
const { Option } = Select;
const { Panel } = Collapse;

const AboutThisTicket = ({
  ticket,
  selectedEmailId,
  userID,
  email,
  refetchData,
  refetchTikcedBoardData,
  setOpenTicketDrawer,
  CallingFromTicketCenter,
  callingFromTicketDetails,
  setIsModified,
  form,
  setFechingEmployees,
  employees,
}) => {
  const { showMessage } = useGlobalAlertMessage();
  const [collopasedKey, setCollopasedKey] = useState(false);
  const [isPipelineModelOpen, setIsPipelineModelOpen] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [stages, setStages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [initialContacts, setInitialContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  // const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { auth, app } = useSelector((state) => state);
  const [initialValues, setInitialValues] = useState({});
  const { data: pipelines, error: pipelinesError } = useGetData(
    `support/tickets/pipelines?status=1`
  );
  const { data: customFieldsData, error: customFieldsError } =
    useGetData(`ticket-custom-fields`);

  useEffect(() => {
    if (ticket) {
      let values = {
        ticketName: ticket?.name || "",
        priority: ticket?.priority
          ? ticket?.priority === "Very high"
            ? "Very High"
            : ticket.priority
          : "", // Ensure empty string when priority is null/undefined
        description: ticket?.description || "",
        associate_employee: ticket?.user_id || "",
      };

      // Ensure all custom fields are included
      customFieldsData?.data?.forEach((customField) => {
        values[customField?.name] = ticket?.[customField?.name] || null;
      });

      form.setFieldsValue(values);
      setInitialValues(values); // Store the updated initial values including custom fields
    }
  }, [ticket, customFieldsData, form]);

  // Function to compare form values with initial values
  const checkFormChanges = (_, allValues) => {
    const isChanged = Object.keys(initialValues).some(
      (key) => initialValues[key] !== allValues[key]
    );
    setIsModified(isChanged);
  };

  // Prefill form values based on ticket data
  useEffect(() => {
    if (ticket) {
      const selectedPipeline = pipelines?.data?.find(
        (p) => p.id === ticket?.support_pipeline_id
      );
      setSelectedPipeline(selectedPipeline);
      setStages(selectedPipeline?.stages || []);

      const selectedEmployeeObj = employees?.find(
        (emp) => emp.id === ticket?.user_id
      );
      setSelectedEmployee(selectedEmployeeObj?.id);

      const initialValues = {
        ticketName: ticket?.name,
        pipeline: ticket?.support_pipeline_id || "",
        stage: ticket?.support_pipeline_stage_id || "",
        priority: ticket?.priority
          ? ticket?.priority === "Very high"
            ? "Very High"
            : ticket.priority
          : "",
        description: ticket?.description || "",
        associate_contact: ticket?.contact_id || "",
        associate_company: ticket?.company_id || "",
        associate_employee: selectedEmployeeObj?.id || "",
      };

      customFieldsData?.data?.forEach((customField) => {
        const fieldName = customField?.name;
        initialValues[fieldName] = ticket?.[fieldName] || null;
      });

      form.setFieldsValue(initialValues);
      const contactId = ticket?.contact_id;
      setSelectedContact(contactId);
      fetchCompanies(contactId);

      setSelectedCompany(ticket?.company_id);
      setSelectedEmployee(ticket?.user_id);
    }
  }, [pipelines, email, form, employees]);

  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await InstanceWithAuth.get(
          `contacts?query=&per_page=10`,
          {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
        const fetchedContacts = response?.data?.data || [];

        const associatedContact = fetchedContacts?.find(
          (contact) => contact.id === ticket?.contact_id
        );

        setContacts(fetchedContacts);
        setSelectedContact(associatedContact?.id || null);

        if (
          associatedContact &&
          !fetchedContacts.find((c) => c.id === associatedContact.id)
        ) {
          setInitialContacts([associatedContact, ...fetchedContacts]);
        } else {
          setInitialContacts(fetchedContacts);
        }
      } catch (error) {
        console.error("Failed to fetch contacts", error);
      }
    }

    fetchContacts();
  }, [email]);

  const fetchCompanies = async (contactId) => {
    try {
      const response = await InstanceWithAuth.get(
        `companies?contact_id=${contactId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      const fetchedCompanies = response?.data?.data || [];

      setCompanies(fetchedCompanies);

      // Pre-select the company associated with the ticket
      const associatedCompany = fetchedCompanies?.find(
        (company) => company.id === ticket?.company_id
      );

      setSelectedCompany(associatedCompany?.id || null);
    } catch (error) {
      console.error("Failed to fetch companies", error);
    }
  };

  // const fetchEmployees = async () => {
  //   try {
  //     setFechingEmployees(true);
  //     const response = await InstanceWithAuth.get(`/users-home`, {
  //       headers: {
  //         Authorization: `Bearer ${auth?.userObj?.api_token}`,
  //       },
  //     });

  //     if (response?.data) {
  //       console.log("response.data.roles", response.data.roles);
  //       const allEmployees = response.data.roles.reduce(
  //         (acc, role) => [...acc, ...role.users],
  //         []
  //       );
  //       console.log("allEmployees", allEmployees);
  //       setEmployees(allEmployees);
  //       setFechingEmployees(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching employees:", error);
  //   } finally {
  //   }
  // };

  // useEffect(() => {
  //   fetchEmployees();
  // }, []);

  // const handleContactChange = (contactId) => {
  //   setSelectedContact(contactId);
  //   fetchCompanies(contactId);
  //   form.setFieldsValue({ associate_company: null });
  // };

  // const handleEmployeeChange = (employeeId) => {
  //   setSelectedEmployee(employeeId);
  //   form.setFieldsValue({ associate_employee: employeeId });
  // };

  // const handlePipelineChange = (pipelineId) => {
  //   const pipeline = pipelines?.data?.find((p) => p.id === pipelineId);
  //   setSelectedPipeline(pipeline);
  //   setStages(pipeline?.stages || []);

  //   // Reset the stage when pipeline changes
  //   form.setFieldsValue({ stage: null });
  // };

  // const handleOk = () => {
  //   setIsPipelineModelOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsPipelineModelOpen(false);
  // };

  // const showModal = () => {
  //   setIsPipelineModelOpen(true);
  // };

  // const handleContactSearch = async (value) => {
  //   try {
  //     const response = await InstanceWithAuth.get(
  //       `contacts?query=${value}&per_page=5`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${auth?.userObj?.api_token}`,
  //         },
  //       }
  //     );
  //     setContacts(response?.data?.data || []);
  //   } catch (error) {
  //     console.error("Failed to search contacts", error);
  //   }
  // };

  const formatLabel = (label) => {
    return label
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    // <Container open={collopasedKey}>
    <StyledContainer>
      {ticket ? (
        <>
          {/* <Form
              className="form-main"
              requiredMark={false}
              form={form}
            
             
            > */}
          <StyledForm
            form={form}
            layout="vertical"
            // onFinish={handleSubmit}
            initialValues={initialValues}
            onValuesChange={checkFormChanges}
          >
            <Form.Item
              label="Ticket name"
              name="ticketName"
              className="form-item"
            >
              <Input className="location" placeholder="Ticket name" />
            </Form.Item>
            <Form.Item
              label="Ticket Description"
              name="description"
              className="form-item"
            >
              <TextArea
                style={{ width: "100%" }}
                className="input location"
                placeholder="Ticket description here..."
                autoSize={{ minRows: 2, maxRows: 8 }}
              />
            </Form.Item>
            <Form.Item
              label="Ticket Owner"
              name="associate_employee"
              className="form-item"
              // style={{ marginTop: "23px" }}
            >
              <Select
                // dropdownStyle={{
                //   paddingBottom: "260px",
                // }}
                className="location"
                placeholder="Select employee"
                value={selectedEmployee}
                onChange={(employeeId) => {
                  setSelectedEmployee(employeeId);
                  form.setFieldsValue({ associate_employee: employeeId });
                }}
                allowClear
                optionLabelProp="label"
                onClear={() => console.log("owner removed")}
                suffixIcon={
                  <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
                }
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option key="0" value={0} label="Unassigned">
                  Unassigned
                </Option>
                {employees?.map((employee) => (
                  <Option
                    key={employee?.id}
                    value={employee?.id}
                    label={`${employee?.first_name} ${employee?.last_name}`}
                  >
                    {employee?.first_name} {employee?.last_name} (
                    {employee?.email})
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Priority" name="priority" className="form-item">
              <Select
                placeholder="Select priority"
                className="location"
                suffixIcon={
                  <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
                }
              >
                <Option value=""></Option>
                <Option value="Low">Low</Option>
                <Option value="Medium">Medium</Option>
                <Option value="High">High</Option>
              </Select>
            </Form.Item>
            {customFieldsData?.data?.map((customFields) => (
              <>
                {customFields?.type == "Single Select List" ? (
                  <Form.Item
                    label={formatLabel(customFields?.name)}
                    name={customFields?.name}
                    className="form-item"
                  >
                    <Select
                      placeholder={formatLabel(customFields?.name)}
                      className="location"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
                      }
                    >
                      {customFields?.options.map((option, index) => (
                        <Option key={index} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : customFields?.type == "Multiple Select List" ? (
                  <Form.Item
                    label={formatLabel(customFields?.name)}
                    name={customFields?.name}
                    className="form-item"
                  >
                    <Select
                      placeholder={formatLabel(customFields?.name)}
                      className="location"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
                      }
                    >
                      {customFields?.options.map((option, index) => (
                        <Option key={index} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : customFields?.type == "Single Line Text" ? (
                  <Form.Item
                    label={formatLabel(customFields?.name)}
                    name={customFields?.name}
                    className="form-item"
                  >
                    <Input
                      placeholder={formatLabel(customFields?.name)}
                      style={{ width: "100%" }}
                      className="input location"
                    />
                  </Form.Item>
                ) : customFields?.type == "Multiple Line Text" ? (
                  <Form.Item
                    label={formatLabel(customFields?.name)}
                    name={customFields?.name}
                    className="form-item"
                  >
                    <TextArea
                      placeholder={formatLabel(customFields?.name)}
                      rows={4}
                      style={{ width: "100%" }}
                      className="input location"
                    />
                  </Form.Item>
                ) : null}
              </>
            ))}
            <Form.Item label="Create Date" name="newMail" className="form-item">
              <div className="location input-static">
                {FormatDate(ticket?.created_at)}
              </div>
            </Form.Item>
          </StyledForm>
        </>
      ) : (
        <></>
      )}
    </StyledContainer>
    // </Container>
  );
};

export default AboutThisTicket;
