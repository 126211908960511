import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown, Space, Switch } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { handleSupportSearchShow } from "../../../../redux/app/appSlice";
import { InstanceWithAuth } from "../../../../App";
import GetLogo from "../../../getlogo/getlogo";
import { ColumnHeaderContainer } from "./styles";

const ColumnHeader = ({
  department,
  setDepartment,
  departments,
  setSearchParm,
  isOnline,
  setIsOnline,
  setInitialEmails,
  searchText,
  setSearchText,
  setIsSearching,
  setDebouncedSearch,
}) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  useEffect(() => {
    if (departments && departments.length > 0) {
      setSelectedDepartment(
        department ? department.name : departments[0]?.name
      );
      setDepartment(department || departments[0]);
    }
  }, [departments, department, setDepartment]);

  useEffect(() => {
    if (isSearchVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef?.current && !inputRef?.current?.contains(event?.target)) {
        if (!searchText?.trim()) {
          setIsSearchVisible(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchText]);

  const handleSearchClick = () => {
    setIsSearchVisible(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setIsSearching(true);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchText);
    }, 500); // 500ms delay

    return () => clearTimeout(handler); // Cleanup function to avoid unnecessary calls
  }, [searchText]);

  const handleSwitchChange = async (checked) => {
    setIsOnline(checked);
    try {
      const formData = new FormData();
      formData.append("chat_status", checked ? 1 : 0);
      await InstanceWithAuth.post(
        `users/${auth?.userObj?.id}/chat-status`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
    } catch (error) {
      console.error("Failed to update chat status:", error);
    }
  };

  return (
    <ColumnHeaderContainer isSearchVisible={isSearchVisible}>
      <Dropdown
        overlay={
          <Menu>
            {departments?.map((dept, index) => (
              <Menu.Item
                key={index}
                onClick={() => {
                  setSelectedDepartment(dept.name);
                  setDepartment(dept);
                  setInitialEmails(null);
                }}
              >
                {dept.name}
              </Menu.Item>
            ))}
          </Menu>
        }
        trigger={["click"]}
      >
        <Space className="btn-title">
          <span className="ellipsis-text">{selectedDepartment}</span>
          <MdOutlineKeyboardArrowDown size="18px" />
        </Space>
      </Dropdown>

      {/* Search Section */}
      <div className="make-row">
        <div className="search">
          {isSearchVisible ? (
            <input
              ref={inputRef}
              type="text"
              className="search-input"
              placeholder="Type to search..."
              value={searchText}
              onChange={handleSearchChange}
            />
          ) : (
            <div className="search-icon" onClick={handleSearchClick}>
              <i className="fas fa-search">{GetLogo("searchIcon")}</i>
              <div className="search-title">Search</div>
            </div>
          )}
        </div>
      </div>

      <div className="make-row">
        <div className="make-row1">
          <span
            className="circle"
            style={{
              backgroundColor: isOnline ? "lightgreen" : "#2b3746",
            }}
          ></span>
          <span className="status">
            {isOnline ? "You're online" : "You're away"}
          </span>
        </div>
        <Switch
          className="switch"
          checked={isOnline}
          onChange={handleSwitchChange}
        />
      </div>
    </ColumnHeaderContainer>
  );
};

export default ColumnHeader;
