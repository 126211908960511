import React, { useEffect, useState } from "react";
import { Form, Modal, Select, Input, Button } from "antd";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import useGetData from "../../../hooks/useGetData";
import { InstanceWithAuth } from "../../../App";
import { useGlobalAlertMessage } from "../../../hooks/useAlertMessage";

import { StyledForm, StyledContainer } from "./styles";

const { Option } = Select;

const ChangeDepartmentModal = ({
  isModalOpen,
  handleCancel,
  handleOk,
  mailBoxDetails,
  selectedEmailId,
  email
}) => {
  const { data: departments, error: departmentsData } = useGetData(
    "support/departments"
  );

  const { data: employes, error: employesError } = useGetData("users-home");
  const [employees, setEmployees] = useState([]);
  const [selectedInbox, setSelectedInbox] = useState(null);
  const [ticketsremainAssociated, setTicketsremainAssociated] = useState(null);
  const { auth } = useSelector((state) => state);
  const [form] = Form.useForm();
  const { showMessage } = useGlobalAlertMessage();

  useEffect(() => {
    console.log("Employees API response:", employes);
    const allEmployees = employes?.roles?.reduce(
      (acc, role) => [...acc, ...role.users],
      []
    );
    setEmployees(allEmployees || []);
  }, [employes]);

  const handleChangeDepartment = async (values) => {
    let formData = new FormData();
    formData.append("support_email_id", selectedEmailId);
    formData.append(`support_department_id`, values?.department);
    formData.append("user_id", values?.assignedTo);
    formData.append("comment", values?.coment ? values?.coment : "");
    try {
      const response = await InstanceWithAuth.post(
        `support/emails/change-department/${selectedEmailId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
        handleCancel();
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  const handleSaveCheckboxChange = (event) => {
    setTicketsremainAssociated(event.target.checked ? true : false);
  };
  return (
    <Modal
      closeIcon={<RxCross2 />}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={500}
      className="custom-model"
      style={{
        minWidth: "548px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <StyledContainer>
        <h2 className="title-main">Move this conversation</h2>

        <StyledForm
          form={form}
          onFinish={handleChangeDepartment}
          requiredMark={false}
        >
          <Form.Item
            label="Move to"
            className="form-item"
            name="department"
            rules={[
              {
                required: true,
                message: "Select department!",
              },
            ]}
          >
            <Select
              placeholder="Choose an inbox or help desk"
              onChange={(value) => setSelectedInbox(value)}
              className="location"
            >
              {departments?.data &&
                departments?.data?.map((dept, index) => (
                  <Option key={index} value={dept?.id}>{dept.name}</Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Assign to"
            className="form-item"
            name="assignedTo"
            rules={[
              {
                required: true,
                message: "Select user!",
              },
            ]}
          initialValue={email?.data?.user_id}
          >
            <Select
              placeholder="Any user"
              className="location"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
              
            >
              {Array.isArray(employees) &&
                employees.map((employee) => (
                  <Option
                    key={employee.id}
                    value={employee.id}
                    label={`${employee.first_name} ${employee.last_name}`}
                  >
                    {employee.first_name} {employee.last_name} ({employee.email}
                    )
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Add comment (optional)"
            className="form-item"
            name="coment"
          >
            <Input.TextArea
              className="input location"
              placeholder="Enter your comment here"
            />
          </Form.Item>
          <p className="info-message">
            {/* <div className="custom-input">
              <input
                type="checkbox"
                id="checkAll"
                checked={ticketsremainAssociated}
                className={`input-checkbox`}
                onChange={(e) => handleSaveCheckboxChange(e)}
              />
              <label for="checkAll"></label>
            </div> */}
            <span>
              Any associated tickets will remain associated with this
              conversation and in their current pipelines.
            </span>
          </p>
          <div className="modal-footer">
            <Button
              className="save-button"
              type="primary"
              //   disabled={!selectedInbox}
              htmlType="submit"
            >
              Move
            </Button>
            <Button className="close-button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </StyledForm>
      </StyledContainer>
    </Modal>
  );
};

export default ChangeDepartmentModal;
