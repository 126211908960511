import React, { useState } from "react";
import "./styles";
import { Container } from "./styles";
import { Form, Input, Select, DatePicker } from "antd";
import useGetData from "../../../hooks/useGetData";

const DetailForm = ({
  id,
  // status,
  // setStatus,
  preferredAreaCode,
  setPreferredAreaCode,
}) => {
  const [status, setStatus] = useState("");
  const [areaCode, setAreaCode] = useState("");

  const {
    data: areaCodes,
    loading: areaCodesLoading,
    error: areaCodesErro,
  } = useGetData(`area-codes`);

  const areaCodeOptions =
    areaCodes?.data?.map((item) => ({
      label: item?.area_code,
      value: item?.area_code,
    })) || [];

  const options = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 0,
    },
    {
      label: "Pending",
      value: 2,
    },
  ];

  const handleStatusChange = (value) => {
    const intValue = parseInt(value);
    setStatus(intValue);
  };

  const handleAreaCodeChange = (value) => {
    const intValue = parseInt(value);
    setAreaCode(intValue);
  };

  return (
    <Container id={id} className="scroll-section">
      <div className="title">General</div>
      <div className="form-container">
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Virtual Phone Number"
              name="virtual_phone_number"
              className="form-items"
            >
              <Input className="input" placeholder="+12124567890" />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Activation Date"
              name="activation_date"
              className="form-items"
            >
              {/* <Input className="input" placeholder="28 October 2023" /> */}

              <DatePicker
                placeholder="28 October 2023"
                format="YYYY-MM-DD"
                className="input"
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Phone number Status"
              name="status"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Phone number Status"
                className="location"
                style={{
                  width: "100% !important",
                }}
                onChange={handleStatusChange}
                options={[
                  { label: "Active", value: 1 },
                  { label: "Inactive", value: 0 },
                  { label: "Pending", value: 2 },
                ]}
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Area Code"
              name="preferred_area_code"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Area code"
                className="location"
                style={{
                  width: "100% !important",
                }}
                onChange={handleAreaCodeChange}
                options={areaCodeOptions}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DetailForm;
