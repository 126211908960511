import React, { useState, useEffect, useRef } from "react";
import { Form } from "antd";
import {
  Banner,
  ComponentsContainer,
  ProductPlansEnableAndContactContainer,
  PlanFormsContainer,
  MainContainer,
  FixedContainer,
} from "./styels";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import { InstanceWithAuth } from "../../App";
import Spinner from "../../common/Spinner";
import PlansSidebar from "../../components/TemporaryPhoneNumberDetails/plansSidebar/PlansSidebar";
import UserVisibility from "../../components/TemporaryPhoneNumberDetails/userVisibility/UserVisibility";
import DetailForm from "../../components/TemporaryPhoneNumberDetails/detailForm/DetailForm";
import DefaultEndDate from "../../components/TemporaryPhoneNumberDetails/defaultEndDate/DefaultEndDate";
import VoiceMailSettings from "../../components/TemporaryPhoneNumberDetails/voiceMailSettings/VoiceMailSettings";
import { handSelectCompanyFlowTab } from "../../redux/app/appSlice";
import { useGlobalAlertMessage } from "../../hooks/useAlertMessage";
import useGetData from "../../hooks/useGetData";

const TemporaryPhoneNumberDetail = () => {
  const { contactId, companyId, numberId } = useParams();
  const [form] = Form.useForm();
  const { app, auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const [numberDetails, setNumberDetails] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [active, setActive] = useState(1);
  const [activeSection, setActiveSection] = useState(1);
  const observer = useRef(null);
  const [loading, setLoading] = useState(false);
  const [callHandling, setCallHandling] = useState(null);
  const dispatch = useDispatch();
  const {
    data: liveAnsweringData,
    loading: liveAnsweringLoading,
    error: liveAnsweringError,
  } = useGetData(`live-answering?contact_id=${contactId}&dropdown=true`);
  const { showMessage } = useGlobalAlertMessage();
  const {
    data: companyData,
    loading: companyDataLoading,
    refetchData: refetchCompnayDetailsData,
  } = useGetData(`companies/${companyId}`);

  const liveAnsweringOptions =
    liveAnsweringData?.data?.map((item) => {
      const key = Object.keys(item)[0];
      return { label: item[key], value: parseInt(key, 10) };
    }) || [];

  console.log(liveAnsweringOptions, "liveAnsweringOptions");
  const getCurrentDate = () => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
  };

  useEffect(() => {
    const fetchNumberDetails = async () => {
      try {
        const response = await InstanceWithAuth.get(
          `live-answering/phone-numbers/${numberId}`,
          {
            headers: { Authorization: `Bearer ${auth?.userObj?.api_token}` },
          }
        );
        if (response.status === 200 || response.status === 201) {
          setNumberDetails(response.data?.data);
        }
      } catch (error) {
        console.error("Failed to fetch number details", error);
      }
    };

    if (numberId) {
      fetchNumberDetails();
    }
  }, [numberId, auth]);
  function normalizePhoneNumber(phoneNumber) {
    const regex = /^\+\d+\s(\+\d+\s.+)/;
    const match = phoneNumber.match(regex);
    if (match) {
      return match[1];
    }
    return phoneNumber;
  }
  useEffect(() => {
    let additionalFields = {};

    switch (numberDetails?.call_handling_type) {
      case 1 || "1":
        additionalFields = {
          forward_to_direct_number: numberDetails?.forward_calls_to,
        };
        break;
      case 2 || "2":
        additionalFields = {
          forward_to_live_answering_plan:
            numberDetails?.live_answering_profile_id,
        };
        break;
      case 3 || "3":
        additionalFields = {
          forward_to_voicemail: numberDetails?.voicemail_option,
        };
        break;
      case 4 || "4":
        break;
      default:
        console.warn("Unknown call handling type selected");
        break;
    }

    if (numberDetails) {
      form.setFieldsValue({
        activation_date: dayjs(numberDetails.activation_date),
        // forward_calls_to:
        //   numberDetails.forward_calls_to &&
        //   normalizePhoneNumber(numberDetails.forward_calls_to),
        virtual_phone_number: numberDetails.virtual_phone_number,
        preferred_area_code: numberDetails.preferred_area_code,
        voicemail_script: numberDetails.voicemail_script,
        status: numberDetails.status,
        call_handling_type: numberDetails.call_handling_type,
        // voicemail_option: numberDetails.voicemail_option,
        ...additionalFields,
      });

      switch (numberDetails.call_handling_type) {
        case 1 || "1":
          setCallHandling(numberDetails?.call_handling_type);
          break;
        case 2 || "2":
          setCallHandling(numberDetails?.call_handling_type);
          break;
        case 3 || "3":
          setCallHandling(numberDetails?.call_handling_type);
          break;
        case 4 || "4":
          setCallHandling(numberDetails?.call_handling_type);
          break;
        default:
          console.warn("Unknown call handling type selected");
          break;
      }

      if (liveAnsweringData && numberDetails) {
        const profileId = numberDetails.live_answering_profile_id;
        const matchedItem = liveAnsweringData?.data?.find(
          (item) => Object.keys(item)[0] === String(profileId)
        );
        if (matchedItem) {
          const intValue = parseInt(matchedItem?.id);
          form.setFieldsValue({
            live_answering_plan: intValue,
          });
        }
      }
    }
  }, [numberDetails, liveAnsweringData, form]);

  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true);
          const isValid = (value) =>
            value !== undefined && value !== null && value !== "";

          const requestType = numberDetails ? "put" : "post";
          const formData =
            requestType === "put" ? new URLSearchParams() : new FormData();

          // Add values to formData only if they are valid
          if (isValid(contactId)) {
            formData.append("contact_id", contactId);
          }
          if (isValid(companyId)) {
            formData.append("company_id", companyId);
          }
          if (isValid(values?.virtual_phone_number)) {
            formData.append(
              "virtual_phone_number",
              values?.virtual_phone_number
            );
          }
          if (isValid(values?.activation_date)) {
            formData.append("activation_date", dayjs(values?.activation_date).format("YYYY-MM-DD"));
          }
          if (isValid(values?.status)) {
            formData.append("status", values?.status);
          }

          if (isValid(values?.call_handling_type)) {
            formData.append("call_handling_type", values?.call_handling_type);
          }
          if (isValid(values?.forward_calls_to)) {
            formData.append("forward_calls_to", values?.forward_calls_to);
          }
          if (isValid(values?.preferred_area_code)) {
            formData.append("preferred_area_code", values?.preferred_area_code);
          }
          // if (isValid(values?.voicemail_option)) {
          //   formData.append("voicemail_option", values?.voicemail_option);
          // }
          if (isValid(values?.voicemail_script)) {
            formData.append("voicemail_script", values?.voicemail_script);
          }
          if (requestType === "post" && isValid(selectedFile)) {
            formData.append("voicemail_file", selectedFile);
          }

          switch (values?.call_handling_type) {
            case 1:
              if (isValid(values?.forward_to_direct_number)) {
                formData.append(
                  "forward_calls_to",
                  values?.forward_to_direct_number
                );
              }
              break;
            case 2:
              if (isValid(values?.forward_to_live_answering_plan)) {
                formData.append(
                  "live_answering_profile_id",
                  values?.forward_to_live_answering_plan
                );
              }
              break;
            case 3:
              if (isValid(values?.forward_to_voicemail)) {
                formData.append(
                  "voicemail_option",
                  values?.forward_to_voicemail
                );
              }
              break;
            case 4:
              break;
            default:
              console.warn("Unknown call handling type selected");
              break;
          }

          const url = `live-answering/phone-numbers${
            numberDetails ? `/${numberId}` : ""
          }`;

          const response = await InstanceWithAuth[requestType](url, formData, {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
              "Content-Type":
                requestType === "put"
                  ? "application/x-www-form-urlencoded"
                  : "multipart/form-data",
            },
          });

          if (response?.status === 201 || response?.status === 200) {
            showMessage({
              type: "success",
              content: response?.data?.message,
            });

            dispatch(handSelectCompanyFlowTab("6"));
            navigate(`/companies/${companyId}`);
          }
        } catch (error) {
          showMessage({
            type: "error",
            content: error?.response?.data?.message,
          });
        } finally {
          setLoading(false);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const scrollToSection = (id) => {
    setActive(id);
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        if (visibleSection) {
          setActiveSection(visibleSection.id);
        }
      },
      {
        rootMargin: "-40% 0px",
        scrollMargin: "-40% 0px",
        delay: 100,
      }
    );

    const sections = document.querySelectorAll(".scroll-section");

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  const handleSubmit = (value) => {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "42px",
      }}
    >
      <CommonHaeder
        firstBcItem={"Companies"}
        firstBcIcon={"companies"}
        firtBcUrl={"/companies"}
        secondBcItem={companyData?.data?.name}
        secondBcIcon="companies"
        secondBcUrl={`/companies/${companyData?.data?.id}`}
        thirdBcUrl={`/companies/${companyData?.data?.id}`}
        thirdBcItem={"Phone Numbers"}
        thirdBcIcon="plains and products"
        fourthBcItem={
          numberDetails?.virtual_phone_number
            ? numberDetails?.virtual_phone_number
            : "Add New"
        }
        fourthBcIcon="plains and products"
      ></CommonHaeder>
      <MainContainer collapsed={app?.sidebarCollapsed}>
        <Banner>
          <div className="label">
            <div className="back-arrow" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M10 4L6 8L10 12"
                  stroke="#2B3746"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <span>
              {numberDetails ? "Edit Phone Number" : "Add New Phone Number"}
            </span>
          </div>
          <div className="btns">
            <div className="btns">
              <button className="cancel-btn" onClick={() => navigate(-1)}>
                <span className="btn-text">Cancel</span>
              </button>
              <button className="save-btn" onClick={handleExternalSubmit}>
                <span className="btn-text">
                  {loading ? <Spinner></Spinner> : "Save"}
                </span>
              </button>
            </div>
          </div>
        </Banner>

        <PlanFormsContainer>
          <PlansSidebar
            scrollToSection={scrollToSection}
            active={active}
            activeSection={activeSection}
          />
          <FixedContainer className="fixed">
            <Form
              className="main-form"
              onFinish={handleSubmit}
              form={form}
              requiredMark={false}
            >
              <ProductPlansEnableAndContactContainer>
                <ComponentsContainer>
                  <DetailForm id={1} />

                  {/* <DefaultEndDate
                    id={2}
                    contactId={contactId}
                    useLiveAnsweringPlan={useLiveAnsweringPlan}
                    setUseLiveAnsweringPlan={setUseLiveAnsweringPlan}
                    numberDetails={numberDetails}
                    liveAnsweringData={liveAnsweringData}
                    liveAnsweringLoading={liveAnsweringLoading}
                    liveAnsweringOptions={liveAnsweringOptions}
                  /> */}

                  <UserVisibility
                    id={2}
                    liveAnsweringData={liveAnsweringData}
                    form={form}
                    numberDetails={numberDetails}
                    callHandling={callHandling}
                    setCallHandling={setCallHandling}
                  />

                  <VoiceMailSettings
                    id={3}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                </ComponentsContainer>
              </ProductPlansEnableAndContactContainer>
            </Form>
          </FixedContainer>
        </PlanFormsContainer>
      </MainContainer>
    </div>
  );
};

export default TemporaryPhoneNumberDetail;
