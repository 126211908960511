import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 339px;
  width: 100%;
  align-self: stretch;
`

const Title = styled.div`
  display: flex;
  max-width: 323px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .label {
    color: var(--Gray-60, rgba(85, 99, 115, 0.6));
    text-align: right;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    .back {
      cursor: pointer;
      width: 26px;
      height: 26px;
      padding: 0px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: white;
    }
  }

  .icons {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    .icon {
      cursor: pointer;
    }
  }
`

const UserAvatar = styled.div`
  display: flex;
  max-width: 339px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .btns {
   width: 124px;
    // height: 26px; 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .on-hold {
      svg {
        width: 4px;
        height: 4px;
        fill-opacity: unset;
        stroke: #ffdc9c;
        circle {
          fill-opacity: unset;
          stroke: #ffdc9c !important;
        }
      }

      .btn-icon {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-icon svg {
        width: 100%;
        height: 100%;
      }
    }
    .in-active {
      svg {
        width: 4px;
        height: 4px;
        fill-opacity: unset;
        circle {
          fill-opacity: unset;
          stroke: #ff7b7b;
        }
      }

      .btn-icon {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-icon svg {
        width: 100%;
        height: 100%;
      }
    }
    .active {
      .btn-icon {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-icon svg {
        width: 100%;
        height: 100%;
      }
    }
    .in-active {
      .btn-icon {
        svg {
          width: 8px;
          height: 8px;
          fill: #ff7b7b;
          fill-opacity: unset;
          circle {
            fill: #ff7b7b;
            fill-opacity: unset;
            stroke: #ff7b7b;
          }
        }
      }
    }
    .manager-btn {
      display: flex;
      width: 50px;
      height: 26px;
      padding: 9px;
      margin-top: 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: none;
      background: transparent;
      .btn-icon {
        svg {
          width: 12px;
          height: 12px;
        }
      }

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        width: max-content;
        /* width: auto; */
      }
    }
    .manager-btn-offline {
      /* min-width: 92px; */
      display: flex;
      width: 50px;
      height: 26px;
      padding: 9px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: none;
      background: transparent;
      .btn-icon {
        svg {
          width: 12px;
          height: 12px;
          fill: #ff7b7b;
          fill-opacity: unset;
          circle {
            fill: #ff7b7b;
            fill-opacity: unset;
            stroke: #ff7b7b;
          }
        }
      }

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        width: max-content;
        /* width: auto; */
      }
    }

    .impression-btn {
      display: flex;
      width: 50px;
      height: 26px;
      padding: 9px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      // border-left: 1px solid rgba(208, 222, 225, 0.4);
      border: none;
      // border-right: none;
      // border-top: none;
      // border-bottom: none;
      background: transparent;
      cursor: pointer;

      .btn-icon {
        width: 12px;
        height: 12px;
      }

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
  }
  .user-img {
    width: 120px;
    /* width: 100%; */
    object-fit: cover;
    height: 120px;
    img {
      border-radius: 5px;
      border: 1px solid var(--Gray-70, rgba(85, 99, 115, 0.7));
      width: 100%;
      height: 100%;
    }
  }
  .user-img {
    width: 120px;
    /* width: 100%; */
    object-fit: cover;
    /* height: 120px; */
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    text-align: center;
    .placeholder {
      border-radius: 100%;
      border: 1px solid var(--Gray-70, rgba(85, 99, 115, 0.7));
      width: 100%;
      height: 100%;
    }
  }

  .user-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .user-name {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 22.8px */
      letter-spacing: -0.38px;
      margin: 0px;
    }

    .user-id {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.32px;

      .id {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.32px;
      }
    }
  }
`

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  align-self: stretch;

  .icons {
    display: flex;
    align-items: flex-start;
    gap: 11px;
    /* align-self: stretch; */

    .icon {
      cursor: pointer;
      display: flex;
      height: 67.5px;
      justify-content: center;
      align-items: center;
      /* flex: 1 0 0; */
      width: 105.6px;
      border-radius: 8px;
      border: 1px solid #dde8ea;
      background: var(--White-100, #fff);

      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;

        g path {
          fill: #556373;
          fill-opacity: unset;
        }
      }
    }
    .icon:hover {
      background: #4cbbcb;
      svg {
        g path {
          fill: #ffffff;
          fill-opacity: unset;
        }
      }
      svg {
        path {
          fill: #ffffff;
          fill-opacity: unset;
        }
      }
    }
  }
`

export { Container, Title, UserAvatar, Icons }
