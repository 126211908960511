import styled from 'styled-components'

const SideBarContainer = styled.nav`
  .ant-menu::before {
    display: none !important;
  }
  display: flex;
  width: 67px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #e7eeef;
  background: var(--White-100, #fff);
  /* overflow-y: auto; */
  overflow: hidden;

  .side-bar {
    border-right: 1px solid #e7eeef;
    display: flex;
    padding: 0px 0px;
    width: 67px !important;
    min-width: 67px !important;
    max-width: 67px !important;
    display: flex;
    width: 67px;
    flex-direction: column;
    align-items: center;
    background: var(--White-100, #fff);
    gap: 24px;
    /* position: fixed; */
    /* left: 0; */
    /* overflow-y: scroll; */
    /* height: 100vh; */

    /* FixedSidebar.css */
  }

  .sidebar-menu:before {
    display: none;
  }
  .sidebar-menu {
    display: flex;
    /* padding-bottom: 24px; */
    flex-direction: column;
    align-items: center;
    gap: 14px;
    border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    border-right: none !important;
    /* height: 376px; */
    margin: unset !important;
    justify-content: space-evenly;
    /* padding-bottom: 16px; */
    /* margin: auto; */

    .menu-title {
      display: none !important;
    }

    .menu-item {
      padding-left: 8px !important;
      width: 28px;
      height: 28px;
      display: flex;
      margin-top: auto !important;
      padding: 8px;
      flex-direction: column;
      overflow: visible !important;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
      background: var(--White-100, #fff);
      /* padding-left: 0px !important; */
      margin-block: 0 !important;

      .badges {
        position: absolute;
        top: -1px;
        right: -2px;

        .ant-badge-count {
          position: unset;
          background: #4cbbcb;

          max-width: 12px !important;
          min-width: 12px !important;
          max-height: 12px !important;
          display: flex;
          width: 12px !important;
          height: 12px !important;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #fff;
          padding: 0 !important;

          bdi {
            color: var(--White-100, #fff);
            text-align: center;
            font-family: Outfit;
            font-size: 6px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            text-transform: uppercase;
            margin-top: 8px;
            margin-left: 1px;
          }
        }
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: white !important;
    }

    .menu-item:hover,
    .menu-item:focus {
      padding-left: 8px !important;
      width: 28px;
      height: 28px;
      display: flex;
      padding: 8px;
      font-weight: 500;
      transition: none !important;
      outline: none;
      border: none;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e9f3f5;
      background: var(--White-100, #fff);
      padding-bottom: 8px !important;

      svg #Group path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
      svg path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
    }
    .custom-active-item {
      padding-left: 8px !important;
      width: 28px;
      height: 28px;
      display: flex;
      padding: 8px;
      font-weight: 500;
      transition: none !important;
      outline: none;
      border: none;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e9f3f5;
      background: var(--White-100, #fff);
      padding-bottom: 8px !important;

      svg #Group path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
      svg path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
    }
    .ant-menu-inline .ant-menu-item {
      margin-block: 0 !important;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: white !important;
    }
  }
  .sidebar-menu-two {
    display: flex;
    /* padding-bottom: 24px; */
    flex-direction: column;
    align-items: center;
    gap: 14px;
    border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    border-right: none !important;
    /* height: 376px; */
    margin: unset !important;
    justify-content: space-evenly;
    /* padding-bottom: 16px; */
    /* margin: auto; */

    .menu-title {
      display: none !important;
    }

    .menu-item {
      padding-left: 8px !important;
      width: 28px;
      height: 28px;
      display: flex;
      margin-top: auto !important;
      padding: 8px;
      flex-direction: column;
      overflow: visible !important;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
      background: var(--White-100, #fff);
      /* padding-left: 0px !important; */
      margin-block: 0 !important;

      .badges {
        position: absolute;
        top: -1px;
        right: -2px;

        .ant-badge-count {
          position: unset;
          background: #4cbbcb;

          /* max-width: 12px !important; */
          min-width: 12px !important;
          max-height: 12px !important;
          display: flex;
          /* width: 12px !important; */
          height: 12px !important;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #fff;
          padding: 0px 2px !important;

          bdi {
            color: var(--White-100, #fff);
            text-align: center;
            font-family: Outfit;
            font-size: 6px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            text-transform: uppercase;
            margin-top: 8px;
            margin-left: 0px;
          }
        }
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: white !important;
    }

    .menu-item:hover,
    .menu-item:focus {
      padding-left: 8px !important;
      width: 28px;
      height: 28px;
      display: flex;
      padding: 8px;
      font-weight: 500;
      transition: none !important;
      outline: none;
      border: none;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e9f3f5;
      background: var(--White-100, #fff);
      padding-bottom: 8px !important;

      svg #Group path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
      svg path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
    }
    .custom-active-item {
      padding-left: 8px !important;
      width: 28px;
      height: 28px;
      display: flex;
      padding: 8px;
      font-weight: 500;
      transition: none !important;
      outline: none;
      border: none;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e9f3f5;
      background: var(--White-100, #fff);
      padding-bottom: 8px !important;

      svg #Group path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
      svg path {
        fill: #4cbbcb;
        fill-opacity: unset;
      }
    }
    .ant-menu-inline .ant-menu-item {
      margin-block: 0 !important;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: white !important;
    }
  }
  .logout-btn {
    .logout-sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05)); */
      border-right: none !important;
      margin: unset !important;
      justify-content: space-evenly;
      /* padding-bottom: 16px; */

      .menu-title {
        display: none !important;
      }

      .menu-item {
        padding-left: 8px !important;
        width: 28px;
        height: 28px;
        display: flex;
        margin-top: auto !important;
        padding: 8px;
        flex-direction: column;
        overflow: visible !important;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
        background: var(--White-100, #fff);
        /* padding-left: 0px !important; */
        margin-block: 0 !important;

        .badges {
          position: absolute;
          top: -1px;
          right: -2px;

          .ant-badge-count {
            position: unset;
            background: #4cbbcb;

            max-width: 12px !important;
            min-width: 12px !important;
            max-height: 12px !important;
            display: flex;
            width: 12px !important;
            height: 12px !important;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 1px solid #fff;
            padding: 0 !important;

            bdi {
              color: var(--White-100, #fff);
              text-align: center;
              font-family: Outfit;
              font-size: 6px;
              font-style: normal;
              font-weight: 500;
              line-height: 12px;
              text-transform: uppercase;
              margin-top: 8px;
              margin-left: 1px;
            }
          }
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .ant-menu-item:not(.ant-menu-item-selected):hover {
        background-color: white !important;
      }

      .menu-item:hover,
      .menu-item:focus {
        padding-left: 8px !important;
        width: 28px;
        height: 28px;
        display: flex;
        padding: 8px;
        font-weight: 500;
        transition: none !important;
        outline: none;
        border: none;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e9f3f5;
        background: var(--White-100, #fff);
        padding-bottom: 8px !important;

        svg #Group path {
          fill: #4cbbcb;
          fill-opacity: unset;
        }
        svg path {
          fill: #4cbbcb;
          fill-opacity: unset;
        }
      }
      .custom-active-item {
        padding-left: 8px !important;
        width: 28px;
        height: 28px;
        display: flex;
        padding: 8px;
        font-weight: 500;
        transition: none !important;
        outline: none;
        border: none;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e9f3f5;
        background: var(--White-100, #fff);
        padding-bottom: 8px !important;

        svg #Group path {
          fill: #4cbbcb;
          fill-opacity: unset;
        }
        svg path {
          fill: #4cbbcb;
          fill-opacity: unset;
        }
      }
      .ant-menu-inline .ant-menu-item {
        margin-block: 0 !important;
      }

      .ant-menu-item:not(.ant-menu-item-selected):hover {
        background-color: white !important;
      }
    }
  }
`

const SidebarItemsContainer = styled.div`
  display: flex;
  width: 67px;
  height: 100%;
  flex-direction: column;
  align-items: center;
`

const SidebarLogo = styled.div`
  display: flex;
  padding: 8px 20px;
  align-items: center;
  gap: 8px;
  /* border-right: 1px solid #e3eced; */
  border-bottom: 1px solid #e3eced;
  height: 42px;
  width: 67px !important;
  /* border-right: 1px solid #e3eced; */

  .startHub-log {
    width: 26.813px;
    height: 26px;
    flex-shrink: 0;
    cursor: pointer;
    .logo {
    }
  }
`
const SidebarItem = styled.div`
  /* border-right: 1px solid #e3eced; */
  display: flex;
  width: 67px;
  padding: 11px 0px 34px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 96.5%;
  .sidebar-item {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    .user-avatar {
      display: flex;
      width: 55px;
      padding-bottom: 16px;
      justify-content: center;
      align-items: center;
      height: 52px;
      border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));

      .avatar-inner {
        display: flex;
        width: 36px;
        height: 36px;
        align-items: flex-start;
        gap: 8px;
        flex-shrink: 0;

        .avatar {
          width: 36px;
          height: 36px;
          flex-shrink: 0;
          border-radius: 36px;
        }
      }
    }
  }
  .sidebar-item::-webkit-scrollbar {
    width: 3px;
  }

  .sidebar-item::-webkit-scrollbar-thumb {
    background-color: gray;
  }

  .sidebar-item::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
`

const SearchIcon = styled.div`
  height: 56px;
  width: 32px;
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));

  .icon-main {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e9f3f5;
    background: var(--White-100, #fff);
    svg {
      width: 12px;
      height: 12px;
    }
  }
`

export { SideBarContainer, SidebarItemsContainer, SidebarLogo, SidebarItem }
