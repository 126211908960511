import React, { Fragment, useState, useEffect, useRef } from "react";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import TicketCenterSubHeader from "../../components/ticketCenter/ticketCenterSubHeader/TicketCenterSubHeader";
import TicketCenterFilters from "../../components/ticketCenter/ticketCenterFilters/TicketCenterFilters";
import { MainConatiner, BoardContainer, StyledIcon } from "./styles";
import Board from "react-trello";
import { GoDotFill } from "react-icons/go";
import { Avatar, Drawer, Popconfirm, Skeleton, Tooltip } from "antd";
import TicketBoardCards from "../../components/ticketCenter/ticketCards/TicketBoardCards";
import TicketCenterSearchBar from "../../components/ticketCenter/ticketCenterSearchBar/TicketCenterSearchBar";
import useGetData from "../../hooks/useGetData";
import { useNavigate } from "react-router-dom";
import { InstanceWithAuth } from "../../App";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import FirstColumn from "../../components/ticket/FirstColumn/FirstColumn";
import UserDetailSidebar from "../../components/userList/userDetailSidebar/UserDetailSidebar";
import CompanyDetailSidebar from "../../components/componyFlowComponents/companyDetailSidebar/CompanyDetailSidebar";
import CustomTooltip from "../../components/allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import NameAvatar from "../../common/NameAvatar";
import DataNotFound from "../../common/DataNotFound";
import { useGlobalAlertMessage } from "../../hooks/useAlertMessage";
import { RiUserAddLine } from "react-icons/ri";
import GetLogo from "../../components/getlogo/getlogo";

function TicketCenter() {
  const { auth } = useSelector((state) => state);
  const { app } = useSelector((state) => state);
  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [type, setType] = useState("");
  const [tooltipText, setTooltipText] = useState("Click to copy");
  const [selectedOwner, setSelectedOwner] = useState({
    name: "all",
    id: "all",
  });
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedCreatedDate, setSelectedCreatedDate] = useState("");
  const [selectedLastActivityDate, setSelectedLastActivityDate] = useState("");
  const [queryParam, setQueryParam] = useState("");
  const [selectedDate, setSelectTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [selectedLocation, setSelectedLocation] = useState();
  const [endDate, setEndDate] = useState("");
  const [filterDateType, setFilterDateType] = useState("");
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [loadingFilteredTicktes, setLoadingFilteredTicktes] = useState(false);
  const [openTicketDrawer, setOpenTicketDrawer] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [allCardsClosed, setAllCardsClosed] = useState(false);
  const { showMessage } = useGlobalAlertMessage();
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [openedCardId, setOpenedCardId] = useState(null);
  const [isShowCompnayDetails, setIsShowCompnayDetails] = useState(false);
  const [isShowContactDetails, setIsShowContactDetails] = useState(false);
  const [slectedCompnayID, setSelectedCompanyId] = useState("");
  const [ticketBoardDataLoading, setTicketBoardDataLoading] = useState(false);
  const [ticketBoardData, setTicketBoardData] = useState(null);
  const [nextPageMap, setNextPageMap] = useState({});
  const [previousNextPageMap, setPreviousNextPageMap] = useState({});
  const [loadingPaginatedTickets, setLoadingPaginatedTickets] = useState(false);
  const [fetchingEmployees, setFechingEmployees] = useState(false);
  const [lastOpendTicketId, setLastOpendTicketId] = useState("");
  const [opendLateViewTicket, setOpendLateViewTicket] = useState(false);
  const [reOpenViewTicket, setReOpenViewTicket] = useState(false);
  const [fetchingTicket, setFechingTicket] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [CallingFromTicketCenter, setCallingFromTicketCenter] = useState(true);

  document.querySelectorAll("section[title]").forEach((section) => {
    section.removeAttribute("title");
  });

  useEffect(() => {
    const saveFilters = setTimeout(() => {
      if (
        !selectedPipeline &&
        !selectedOwner &&
        !selectedPriority &&
        !selectedCreatedDate &&
        !selectedLastActivityDate &&
        !startDate &&
        !endDate &&
        !selectedLocation &&
        !queryParam &&
        !type
      ) {
        return;
      }
      const filters = {
        selectedPipeline: selectedPipeline || "",
        selectedOwner: selectedOwner || "",
        selectedPriority: selectedPriority || "",
        selectedCreatedDate: selectedCreatedDate || "",
        selectedLastActivityDate: selectedLastActivityDate || "",
        startDate: startDate || "",
        endDate: endDate || "",
        selectedLocation: selectedLocation || "",
        queryParam: queryParam || "",
        type: type || "",
      };

      localStorage.setItem("ticketsTasksFilters", JSON.stringify(filters));
    }, 300);
    return () => clearTimeout(saveFilters);
  }, [
    selectedPipeline,
    selectedOwner,
    selectedPriority,
    selectedCreatedDate,
    selectedLastActivityDate,
    startDate,
    endDate,
    selectedLocation,
    queryParam,
    type,
  ]);

  useEffect(() => {
    const savedFilters = JSON.parse(
      localStorage.getItem("ticketsTasksFilters")
    );
    if (savedFilters) {
      setSelectedPipeline(savedFilters.selectedPipeline || "");
      setSelectedOwner(savedFilters.selectedOwner || "");
      setSelectedPriority(savedFilters.selectedPriority || "");
      setSelectedCreatedDate(savedFilters.selectedCreatedDate || "");
      setSelectedLastActivityDate(savedFilters.selectedLastActivityDate || "");
      setStartDate(savedFilters.startDate || "");
      setEndDate(savedFilters.endDate || "");
      setSelectedLocation(savedFilters.selectedLocation || "");
      setQueryParam(savedFilters.queryParam || "");
      setType(savedFilters.type || "");
    }
  }, []);

  // const {
  //   data: ticketBoardData,
  //   refetchData: refetchTikcedBoardData,
  //   loading: ticketBoardDataLoading,
  // } = useGetData(
  //   `support/ticket-board?support_pipeline_id=${
  //     app?.userSelcetedPipeLine ? app?.userSelcetedPipeLine?.id : ""
  //   }&owner=${
  //     selectedOwner && selectedOwner?.name != "all" ? selectedOwner?.id : ""
  //   }&priority=${
  //     selectedPriority?.name && selectedPriority?.name != "all"
  //       ? selectedPriority?.name
  //       : ""
  //   }&location_id=${
  //     selectedLocation && selectedLocation?.name != "all"
  //       ? selectedLocation?.key
  //       : ""
  //   }&query=${queryParam ? queryParam : ""}&from_date=${
  //     startDate && startDate != "-undefined-undefined" ? startDate : ""
  //   }&to_date=${endDate && endDate != "-undefined-undefined" ? endDate : ""}`
  // );

  const refetchTikcedBoardData = async (laneId, requestedPage) => {
    try {
      setTicketBoardDataLoading(true);

      const response = await InstanceWithAuth.get(
        `support/ticket-board?support_pipeline_id=${
          app?.userSelcetedPipeLine ? app?.userSelcetedPipeLine?.id : ""
        }&owner=${selectedOwner && selectedOwner?.id}&priority=${
          selectedPriority?.name && selectedPriority?.name != "all"
            ? selectedPriority?.name
            : ""
        }&location_id=${
          selectedLocation && selectedLocation?.name != "all"
            ? selectedLocation?.key
            : ""
        }&query=${queryParam ? queryParam : ""}&from_date=${
          startDate && startDate != "-undefined-undefined" ? startDate : ""
        }&to_date=${
          endDate && endDate != "-undefined-undefined" ? endDate : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setTicketBoardDataLoading(false);
        setTicketBoardData(response?.data);
      }
      return response;
    } catch (error) {
      setTicketBoardDataLoading(false);
      return Promise.reject(error);
    }
  };

  const refetchPaginatedTikcedBoardData = async (laneId, id) => {
    try {
      setLoadingPaginatedTickets(true);
      const nextPage = nextPageMap[laneId] || 2;

      const response = await InstanceWithAuth.get(
        `support/load-tickets/${
          app?.userSelcetedPipeLine ? app?.userSelcetedPipeLine?.id : ""
        }/${id}?page=${nextPage}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setLoadingPaginatedTickets(false);

        const newLaneData = response?.data?.data;

        setTicketBoardData((prevData) => {
          if (!prevData?.data || !newLaneData) {
            return prevData;
          }

          const updatedData = {
            ...prevData,
            data: prevData.data.map((lane) => {
              if (lane.id === Number(newLaneData.id)) {
                return {
                  ...lane,
                  cards: [...lane.cards, ...newLaneData.cards],
                };
              }
              return lane;
            }),
          };

          return updatedData;
        });

        setNextPageMap((prev) => {
          const nextPageValue = newLaneData.has_more_pages
            ? newLaneData.current_page + 1
            : "its_last_page";

          return {
            ...prev,
            [newLaneData.id]: nextPageValue,
          };
        });
      } else {
        setLoadingPaginatedTickets(false);
      }

      return response;
    } catch (error) {
      setLoadingPaginatedTickets(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    refetchTikcedBoardData();
  }, [
    selectedPipeline,
    selectedOwner,
    selectedPriority,
    queryParam,
    selectedLocation,
    selectedDate,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (selectedCreatedDate?.name == "all") {
      setStartDate("");
      setEndDate("");
      refetchTikcedBoardData();
    }
  }, [selectedCreatedDate]);

  const showDrawerContactDrawer = () => {
    setOpenTicketDrawer(true);
  };
  const onCloseTicketDrawer = () => {
    setOpenTicketDrawer(false);
    setIsShowCompnayDetails(false);
    setSelectedCompanyId("");
  };

  const isFirstLoad = useRef(true);
  let showLoading = false;
  const now = new Date();

  const cardStyle = {
    border: "1px solid #4cbbcb",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px 0px ",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "14px",
    color: "#2b3746",
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontWeight: "500 !important",
    lineHeight: "normal",
    letterSpacing: "-0.49px",
    width: "250px",
    fontWeight: "400",
  };

  const laneStyle = {
    backgroundColor: "red",
    fontSize: "20px",
    fontWeight: "600",
  };

  function timeAgo(dateString, status) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / 3600);
    const days = Math.floor(seconds / 86400);
    //
    let timeString;

    if (days >= 1) {
      timeString = days === 1 ? "1 day" : `${days} days`;
    } else if (hours >= 1) {
      timeString = hours === 1 ? "1 hour" : `${hours} hours`;
    } else if (minutes >= 1) {
      timeString = minutes === 1 ? "1 minute" : `${minutes} minutes`;
    } else {
      timeString = "Just now";
    }

    if (status === "Open") {
      return timeString === "Just now"
        ? "Opened just now"
        : `Open for ${timeString}`;
    } else {
      // return timeString === "Just now" ? "Just now" : `${timeString} ago`;
      return "Closed";
    }
  }

  const [count, setCount] = useState(0);
  const [mappedData, setMappedData] = useState({ lanes: [] });

  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };
  const handleShowUserDetails = (
    e,
    compnayId,
    type,
    hittingFromContactSideView
  ) => {
    setOpendLateViewTicket(false);
    setLastOpendTicketId("");
    if (hittingFromContactSideView) {
      setIsShowContactDetails(false);
      setIsShowCompnayDetails(true);
      setSelectedCompanyId(compnayId);
      // setOpenTicketDrawer(false);
    } else {
      if (type === "company") {
        setOpenTicketDrawer(true);
        setIsShowContactDetails(false);
        setIsShowCompnayDetails(true);
        setSelectedCompanyId(compnayId);
      } else if (type === "conatct") {
        setOpenTicketDrawer(false);
        setIsShowCompnayDetails(false);
        setIsShowContactDetails(true);
        setSelectedCompanyId(compnayId);
      }
    }
  };
  const handleShowOpenUserAndCompany = (
    e,
    compnayId,
    type,
    hittingFromContactSideView
  ) => {
    setOpendLateViewTicket(true);
    if (hittingFromContactSideView) {
      setIsShowContactDetails(false);
      setIsShowCompnayDetails(true);
      setSelectedCompanyId(compnayId);
      // setOpenTicketDrawer(false);
    } else {
      if (type === "company") {
        setOpenTicketDrawer(true);
        setIsShowContactDetails(false);
        setIsShowCompnayDetails(true);
        setSelectedCompanyId(compnayId);
      } else if (type === "conatct") {
        setOpenTicketDrawer(true);
        setIsShowCompnayDetails(false);
        setIsShowContactDetails(true);
        setSelectedCompanyId(compnayId);
      }
    }
  };

  const fetchEmployees = async () => {
    try {
      setFechingEmployees(true);
      const response = await InstanceWithAuth.get(`/users-home`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response?.data) {
        const allEmployees = response.data.roles.reduce(
          (acc, role) => [...acc, ...role.users],
          []
        );

        setEmployees(allEmployees);
        setFechingEmployees(false);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleCopy = async (e, id, title) => {
    e.stopPropagation();
    const url = `${process.env.REACT_APP_BASE_URL}/tickets/${id}`;
    console.log("url", url);
    const htmlText = `<a href="${url}" target="_blank">${title}</a>`; // HTML link format
    const plainText = `${title}: ${url}`; // Plain text fallback

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([htmlText], { type: "text/html" }),
          "text/plain": new Blob([plainText], { type: "text/plain" }),
        }),
      ]);
      setTooltipText("Copied");
    } catch (error) {
      console.error("Clipboard copy failed:", error);
      navigator.clipboard.writeText(plainText); // Fallback to plain text
    }
    setTimeout(() => {
      setTooltipText("Click to Copy");
    }, 2000);
  };

  // const handleCopy = (e, id, title) => {
  //   const baseUrl = process.env.REACT_APP_BASE_URL;
  //   e.stopPropagation();
  //   const textToCopy = `${baseUrl}/tickets/${id}`;
  //   navigator.clipboard.writeText(textToCopy);
  //   setTooltipText("Copied");
  //   setTimeout(() => {
  //     setTooltipText("Click to Copy");
  //   }, 2000);
  // };

  const CustomCard = ({
    title,
    description,
    owner,
    avatar,
    label,
    id,
    companyName,
    companyAvatar,
    status,
    companyId,
    contactName,
    contactAvatar,
    contactId,
    total,
    priority,
    pipeLineId,
    mailBoxNumber,
    mailBoxLocation,
  }) => (
    <div
      style={{
        background: "rgb(248 252 252)",
        borderRadius: "8px",
        padding: "10px",
        marginBottom: "8px",
        border: "1px solid #b2ebf2",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "80px",
        cursor: "pointer",
        width: "inherit",
      }}
      title=""
      onClick={() => handleCardClick(id)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            // color: '#1397b2',
            fontFamily: "Outfit",
            width: "213px",
            textWrapMode: "wrap",
            maxWidth: "100%",
            wordBreak: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "normal",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </div>
        <Tooltip title={tooltipText}>
          <StyledIcon
            className="icon"
            onClick={(e) => handleCopy(e, id, title)}
          >
            {GetLogo("copy")}
          </StyledIcon>
        </Tooltip>
      </div>

      <div
        style={{
          fontSize: "12px",
          fontWeight: "400",
          // color: '#00796B',
          fontFamily: "Outfit",
        }}
      >
        {timeAgo(label, status)}
      </div>
      <div
        style={{
          fontSize: "12px",
          color: "#333",
          marginTop: "4px",
          display: "flex",
          width: "242px",
          textWrapMode: "wrap",
        }}
      >
        <div style={{ fontWeight: "500" }}>Ticket Owner </div> :{" "}
        {owner ? (
          owner
        ) : (
          <Popconfirm
            title="Are you sure to own?"
            description="Are you sure to own ticket?"
            onConfirm={(e) => {
              e.stopPropagation();
              handleOwnTicket(id, owner);
            }}
            onCancel={(e) => e.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
                paddingLeft: "3px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              Unassigned{" "}
              <RiUserAddLine
                color="#4cbbcb"
                style={{
                  transition: "transform 0.2s ease, filter 0.2s ease",
                }}
                onMouseEnter={(e) => {
                  e.target.style.transform = "scale(1.3)";
                  e.target.style.filter = "brightness(1.2)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "scale(1)";
                  e.target.style.filter = "brightness(1)";
                }}
              />
            </div>
          </Popconfirm>
        )}
      </div>
      <div
        style={{
          fontSize: "12px",
          color: "#333",
          marginTop: "4px",
          maxWidth: "100%",
          wordBreak: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "normal",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {description}
      </div>

      <div style={{ marginTop: "10px", borderTop: "1px solid #ccc" }}></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          {contactAvatar != null ? (
            <CustomTooltip
              text={contactName}
              marginleft="3rem"
              marginTop="-30px"
            >
              <img
                onClick={(e) => handleShowUserDetails(e, contactId, "conatct")}
                src={contactAvatar}
                alt="avatar"
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  // marginRight: '8px',
                  marginTop: "12px",
                  cursor: "pointer",
                }}
              />
            </CustomTooltip>
          ) : contactName != null && contactAvatar == null ? (
            <CustomTooltip
              text={contactName}
              marginleft="3rem"
              marginTop="-10px"
            >
              <div
                style={{
                  // marginRight: '8px',
                  marginTop: "10px",
                  cursor: "pointer",
                }}
                onClick={(e) => handleShowUserDetails(e, contactId, "conatct")}
              >
                <NameAvatar
                  val={20}
                  userName={contactName}
                  fontSize={12}
                ></NameAvatar>
              </div>
            </CustomTooltip>
          ) : (
            <CustomTooltip text={"Unassigned"}>
              <div
                style={{
                  // marginRight: '8px',
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // marginRight: '8px',
                    cursor: "pointer",
                  }}
                ></span>
              </div>
            </CustomTooltip>
          )}

          {companyAvatar != null ? (
            <CustomTooltip
              text={companyName}
              marginleft="3rem"
              marginTop="-30px"
            >
              <img
                onClick={(e) => handleShowUserDetails(e, companyId, "company")}
                src={companyAvatar}
                alt="avatar"
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  // marginRight: '8px',
                  marginTop: "12px",
                  cursor: "pointer",
                }}
              />
            </CustomTooltip>
          ) : companyName != null && companyAvatar == null ? (
            <CustomTooltip
              text={companyName}
              marginleft="3rem"
              marginTop="-10px"
            >
              <div
                style={{
                  // marginRight: '8px',
                  marginTop: "10px",
                  cursor: "pointer",
                }}
                onClick={(e) => handleShowUserDetails(e, companyId, "company")}
              >
                <NameAvatar
                  val={20}
                  userName={companyName}
                  fontSize={12}
                ></NameAvatar>
              </div>
            </CustomTooltip>
          ) : (
            <CustomTooltip text={"Unassigned"}>
              <div
                style={{
                  // marginRight: '8px',
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // marginRight: '8px',
                    cursor: "pointer",
                  }}
                ></span>
              </div>
            </CustomTooltip>
          )}
        </div>
        {priority != undefined && priority != "" && priority != "Nothing" ? (
          <span
            style={{
              fontSize: "12px",
              fontWeight: "500",
              fontFamily: "Outfit",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <>
              <GoDotFill
                fill={
                  priority == "High" || priority == "Very high"
                    ? "#ff7b7b"
                    : priority == "Medium"
                    ? "#ffdc9c"
                    : priority == "Low"
                    ? "#4CCB60"
                    : ""
                }
                size={17}
                color={
                  priority == "High" || priority == "Very high"
                    ? "#ff7b7b"
                    : priority == "Medium"
                    ? "#ffdc9c"
                    : priority == "Low"
                    ? "#4CCB60"
                    : ""
                }
              />
              {priority}
            </>
          </span>
        ) : (
          ""
        )}
      </div>
      {pipeLineId == 5 ? (
        <>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "500",
              fontFamily: "Outfit",
              marginTop: "10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "5px",
            }}
          >
            PMB: <span>{mailBoxNumber}</span>
          </div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "500",
              fontFamily: "Outfit",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "5px",
            }}
          >
            Location: <span>{mailBoxLocation}</span>
          </div>
        </>
      ) : null}
    </div>
  );

  const handleOwnTicket = async (id) => {
    const data = {
      user_id: auth?.userObj?.id || "",
    };

    const encodedData = new URLSearchParams(data).toString();

    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${id}`,
        encodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        refetchTikcedBoardData();
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    const initializeLanes = () => {
      const dataSource =
        advancedFilters?.status === "success"
          ? advancedFilters.data
          : Array.isArray(ticketBoardData?.data)
          ? ticketBoardData.data
          : [];

      if (Array.isArray(dataSource) && dataSource.length > 0) {
        let allClosed = true; // Assume all cards are closed initially

        const lanes = dataSource.map((stage) => {
          const cards = Array.isArray(stage?.cards)
            ? stage.cards.map((card) => {
                // Check if any card is not closed
                if (card.status !== "Closed") {
                  allClosed = false;
                }

                return {
                  id: card?.id?.toString(),
                  title: card?.title,
                  label: card?.label,
                  description: truncateText(card?.description, 80),
                  owner: card?.owner || null,
                  metadata: { sha: "be312a1" },
                  avatar: card?.avatar || null,
                  companyName: card?.company_name || null,
                  companyAvatar: card?.company_avatar || null,
                  status: card?.status || null,
                  companyId: card?.company_id,
                  contactName: card?.contact_name || null,
                  contactAvatar: card?.contact_avatar || null,
                  contactId: card?.contact_id,
                  priority: card?.priority,
                  pipeLineId: card?.support_pipeline_id,
                  mailBoxNumber: card?.pbm,
                  mailBoxLocation: card?.location,
                };
              })
            : [];

          return {
            id: stage?.id?.toString(),
            title: stage.title,
            label: `${stage?.cards?.length || 0}`,
            style: {
              background: "white",
              fontSize: "14px",
              color: "#2b3746",
              fontFamily: "Outfit",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "-0.49px",
              height: "470px",
              width: "280px",
              margin: "5px 5px 0px 0px",
              header: {
                height: "30px",
              },
            },
            cards,
            total: stage?.total,
          };
        });

        setMappedData({ lanes });
        setAllCardsClosed(allClosed);
      } else {
        setMappedData({ lanes: [] });
        setAllCardsClosed(false);
      }
    };

    initializeLanes();
  }, [ticketBoardData?.data, advancedFilters?.data]);

  const openFiltersDrawer = localStorage.getItem("openFiltersDrawer");
  useEffect(() => {
    if (openFiltersDrawer) {
      setOpenContactDrawer(true);
      setOpenContactDrawer(true);
    }
  }, [openFiltersDrawer]);

  const handleDragEnd = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    setMappedData((prevData) => {
      const updatedLanes = prevData.lanes.map((lane) => {
        // Case: Same lane
        if (lane.id === sourceLaneId && sourceLaneId === targetLaneId) {
          // Reorder cards within the same lane
          const cardIndex = lane.cards.findIndex((card) => card.id === cardId);
          const updatedCards = [...lane.cards];
          const [movedCard] = updatedCards.splice(cardIndex, 1); // Remove the card
          updatedCards.splice(position, 0, movedCard); // Add it back at the new position

          return {
            ...lane,
            cards: updatedCards,
            label: `${updatedCards.length}`, // Update label
          };
        }

        // Case: Source lane
        if (lane.id === sourceLaneId) {
          return {
            ...lane,
            cards: lane.cards.filter((card) => card.id !== cardId), // Remove card
            label: `${lane.cards.length - 1}`, // Update label
          };
        }

        // Case: Target lane
        if (lane.id === targetLaneId) {
          return {
            ...lane,
            cards: [
              ...lane.cards.slice(0, position),
              cardDetails, // Add card at new position
              ...lane.cards.slice(position),
            ],
            label: `${lane.cards.length + 1}`, // Update label
          };
        }

        return lane;
      });

      setCount((prevCount) => prevCount + 1);

      return { lanes: updatedLanes };
    });

    handleSubmit(cardId, sourceLaneId, targetLaneId, position, cardDetails);
  };
  const handleCardClick = async (cardId) => {
    setLastOpendTicketId(cardId);
    setOpenedCardId(cardId);
    setOpenTicketDrawer(true);
    fetchTicketData(cardId);
    if (isShowCompnayDetails) {
      setIsShowCompnayDetails(false);
    }

    if (isShowContactDetails) {
      setIsShowContactDetails(false);
    }
  };
  console.log(openContactDrawer);
  const handleReopenCardClick = async (cardId) => {
    setOpenedCardId(cardId);
    fetchTicketData(cardId);
    setIsShowContactDetails(false);
    setIsShowCompnayDetails(false);
    setOpenTicketDrawer(true);
  };

  const fetchTicketData = async (cardId) => {
    setTicketData(null);
    setFechingTicket(true);
    try {
      const response = await InstanceWithAuth.get(`support/tickets/${cardId}`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      setTicketData(response);
    } catch (error) {
    } finally {
      setFechingTicket(false);
    }
  };

  const handleSubmit = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    if (sourceLaneId == targetLaneId) return null;
    const data = {
      support_pipeline_id: app?.userSelcetedPipeLine?.id,
      support_pipeline_stage_id: targetLaneId,
    };
    const encodedData = new URLSearchParams(data).toString();
    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${cardId}`,
        encodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        showMessage({
          type: "success",
          content: response?.data?.message,
        });

        if (openTicketDrawer) {
          if (cardId === openedCardId) {
            fetchTicketData(cardId);
          }
        }
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  const CustomLaneHeader = ({ title, label, total }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "30px",
        padding: "10px",
        fontSize: "15px",
        fontWeight: "500",
        marginBottom: "0px",
        padding: "0px 5px",
      }}
    >
      <span style={{ textWrap: "wrap" }}>{title}</span>
      <span style={{ fontSize: "12px", color: "#6b6b6b" }}>{total}</span>
    </div>
  );

  return (
    <Fragment>
      <CommonHaeder
        firstBcItem={app?.userSelcetedPipeLine?.type}
        firstBcIcon="logTickets"
        secondBcItem={app?.userSelcetedPipeLine?.name}
        secondBcIcon="logTickets"
      />
      <TicketCenterSubHeader />
      <MainConatiner>
        <TicketCenterFilters
          selectedPipeline={selectedPipeline}
          setSelectedPipeline={setSelectedPipeline}
          type={type}
          setType={setType}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          selectedPriority={selectedPriority}
          setSelectedPriority={setSelectedPriority}
          selectedCreatedDate={selectedCreatedDate}
          setSelectedCreatedDate={setSelectedCreatedDate}
          selectedLastActivityDate={selectedLastActivityDate}
          setSelectedLastActivityDate={setSelectedLastActivityDate}
          app={app}
          selectedOption={selectedDate}
          setSelectedOption={setSelectTime}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          setFilterDateType={setFilterDateType}
          filterDateType={filterDateType}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          setAdvancedFilters={setAdvancedFilters}
          setLoadingFilteredTicktes={setLoadingFilteredTicktes}
          openContactDrawer={openContactDrawer}
          setOpenContactDrawer={setOpenContactDrawer}
          refetchTikcedBoardData={refetchTikcedBoardData}
          setNextPageMap={setNextPageMap}
          setPreviousNextPageMap={setPreviousNextPageMap}
        />
        <BoardContainer>
          <TicketCenterSearchBar
            setQueryParam={setQueryParam}
            allCardsClosed={allCardsClosed}
            ticketBoardDataLoading={ticketBoardDataLoading}
          />
          <div className="kanban-board">
            {!ticketBoardDataLoading &&
            !loadingFilteredTicktes &&
            mappedData.lanes.length == 0 ? (
              <div
                style={{
                  minHeight: "280px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <DataNotFound
                  label={"Good Job! You are up to date with this pipeline"}
                ></DataNotFound>
              </div>
            ) : ticketBoardDataLoading || loadingFilteredTicktes ? (
              <Skeleton active></Skeleton>
            ) : mappedData?.lanes && mappedData?.lanes?.length > 0 ? (
              <Board
                data={mappedData}
                laneDraggable={false}
                cardDraggable={true}
                title={null}
                components={{
                  Card: (props) => (
                    <>
                      <CustomCard
                        title={props.title}
                        description={props.description}
                        owner={props.owner}
                        avatar={props.avatar}
                        label={props.label}
                        id={props.id}
                        companyName={props.companyName}
                        companyAvatar={props.companyAvatar}
                        status={props.status}
                        companyId={props.companyId}
                        contactName={props.contactName}
                        contactAvatar={props.contactAvatar}
                        contactId={props.contactId}
                        priority={props.priority}
                        pipeLineId={props.pipeLineId}
                        mailBoxNumber={props.mailBoxNumber}
                        mailBoxLocation={props.mailBoxLocation}
                      />
                    </>
                  ),
                  LaneHeader: CustomLaneHeader,
                }}
                laneButtonStyle={{ display: "none" }}
                handleDragEnd={handleDragEnd}
                cardStyle={cardStyle}
                onCardClick={handleCardClick}
                hideCardDeleteIcon={true}
                onLaneScroll={(requestedPage, laneId) => {
                  const lane = mappedData?.lanes?.find(
                    (lane) => lane.id === laneId
                  );
                  const nextPage = nextPageMap[laneId];
                  const previousNextPage = previousNextPageMap[laneId];
                  const total = lane?.total || 0;

                  if (loadingPaginatedTickets) {
                    return Promise.resolve();
                  }

                  if (nextPage && nextPage == "its_last_page") {
                    return Promise.resolve();
                  }

                  if (nextPage && nextPage !== previousNextPage) {
                    setPreviousNextPageMap((prev) => ({
                      ...prev,
                      [laneId]: nextPage,
                    }));

                    return refetchPaginatedTikcedBoardData(laneId, lane?.id);
                  }

                  if (!nextPage && total > 9) {
                    return refetchPaginatedTikcedBoardData(laneId, lane?.id);
                  }

                  return Promise.resolve();
                }}
                laneStyle={{
                  background: "red",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              />
            ) : !ticketBoardDataLoading &&
              !loadingFilteredTicktes &&
              mappedData.lanes.length == 0 ? (
              <div
                style={{
                  minHeight: "280px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <DataNotFound
                  label={"Good Job! You are up to date with this pipeline"}
                ></DataNotFound>
              </div>
            ) : null}
          </div>
        </BoardContainer>

        <Drawer
          mask={false}
          className="drawer"
          width={400}
          padding={0}
          paddingLG={0}
          paddingXS={0}
          onClose={onCloseTicketDrawer}
          open={openTicketDrawer}
          headerStyle={{
            display: "none",
          }}
          bodyStyle={{
            padding: isShowCompnayDetails ? "0px" : "",
          }}
          // styles={{
          //   padding: isShowCompnayDetails ? '0px' : '',
          // }}
          closeIcon={
            <CloseOutlined style={{ color: "white", fontSize: "bold" }} />
          }
          title="All Filters"
        >
          {isShowCompnayDetails ? (
            <CompanyDetailSidebar
              open={true}
              userID={slectedCompnayID}
              canHide={true}
              setSideBarOpen={onCloseTicketDrawer}
              opendLateViewTicket={opendLateViewTicket}
              handleReopenCardClick={handleReopenCardClick}
              lastOpendTicketId={lastOpendTicketId}
              // setSideBarOpen={onCloseTicketDrawer}
              setOpen={onCloseTicketDrawer}
              buttonSize={"-webkit-fill-available"}
            />
          ) : isShowContactDetails ? (
            <UserDetailSidebar
              open={true}
              userID={slectedCompnayID}
              canHide={true}
              opendLateViewTicket={opendLateViewTicket}
              handleReopenCardClick={handleReopenCardClick}
              lastOpendTicketId={lastOpendTicketId}
              setSideBarOpen={onCloseTicketDrawer}
              setOpen={onCloseTicketDrawer}
              buttonSize={"-webkit-fill-available"}
              OpenCompanyInSideViewForTicketCenter={true}
              handleShowUserDetails={handleShowUserDetails}
            />
          ) : (
            <>
              {employees?.length < 0 || fetchingTicket ? (
                <>
                  <Skeleton />
                </>
              ) : (
                <FirstColumn
                  ticket={ticketData?.data?.data}
                  refetchData={fetchTicketData}
                  CallingFromTicketCenter={CallingFromTicketCenter}
                  setCallingFromTicketCenter={setCallingFromTicketCenter}
                  setOpenTicketDrawer={setOpenTicketDrawer}
                  refetchTikcedBoardData={refetchTikcedBoardData}
                  setFechingEmployees={setFechingEmployees}
                  employees={employees}
                  showUserAndCompanyAvatar={true}
                  handleShowUserDetails={handleShowOpenUserAndCompany}
                />
              )}
            </>
          )}
        </Drawer>
      </MainConatiner>
    </Fragment>
  );
}

export default TicketCenter;
