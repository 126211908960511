import styled from 'styled-components'

const Wrapper = styled.div`
max-width: 100px;
word-wrap: break-word;
right: 10px;
  .custom-tooltip-container {
    position: relative;
    display: inline-block;
  }

  .custom-tooltip {
    position: absolute;
    border-radius: 4px;
    background: var(--Dark-Gray-100, #2b3746);
    color: #fff;
    padding: 4px 8px;
    transform: translateX(-50%);
    z-index: 9999;
    font-size: 10px;
    white-space: nowrap;
    text-align: center;
    font-family: Outfit;
    font-weight: 400;
    line-height: 1;
    color: #fbfcfc;
    bottom: 125%;
    left: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  /* Tooltip Arrow */
  .custom-tooltip-arrow {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: var(--Dark-Gray-100, #2b3746) transparent transparent transparent;
    display: ${(props) => (props.arrow ? "block" : "none")}; /* Hide arrow if prop is false */
  }

  .custom-tooltip-container:hover .custom-tooltip {
    display: block;
  }
`;

export { Wrapper }
