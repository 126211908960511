import React from "react";
import { MainContainer } from "./styles";
import { ReactComponent as Ellipse } from "../../../assets/icons/Ellipse 658 (1).svg";
import { useSelector } from "react-redux";
import NameAvatar from "../../../common/NameAvatar";
import moment from "moment";

const AccountManagerProfile = () => {
  const { auth } = useSelector((state) => state);
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning,";
    else if (hour < 18) return "Good Afternoon,";
    else return "Good Evening,";
  };
  return (
    <MainContainer>
      <div className="profile_img">
        <div className="bg_img">
          {auth?.userObj?.avatar ? (
            <img
              width="100"
              height="100"
              style={{ borderRadius: "100%" }}
              // style={{ width: "100%", height: "100%",borderRadius: "100%",}}
              src={auth?.userObj?.avatar}
              alt="avatar"
              className="avatar"
            />
          ) : (
            <NameAvatar
              userName={auth?.userObj?.first_name}
              val={24}
              fontSize={11}
            ></NameAvatar>
          )}
        </div>
      </div>

      <div className="content">
        <text className="text_msg">{getGreeting()}</text>
        <div className="User_name">
          <span>{`${auth?.userObj?.first_name} ${auth?.userObj?.last_name}`}</span>
        </div>
        <div className="timeline">{moment().format("dddd | D MMMM, YYYY")}</div>
      </div>
    </MainContainer>
  )
}

export default AccountManagerProfile
