import React, { useState, useEffect } from "react";
import "./styles";
import { Container, Icons, Title, UserAvatar } from "./styles";
import GetLogo from "../../../../getlogo/getlogo";
import { useNavigate } from "react-router-dom";
import useGetData from "../../../../../hooks/useGetData";
import { useSelector, useDispatch } from "react-redux";
import { handSelectCompanyFlowTab } from "../../../../../redux/app/appSlice";
import { IoIosChatboxes } from "react-icons/io";
import { BsMailboxFlag } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { IoMdCard } from "react-icons/io";
import { Popconfirm, Skeleton } from "antd";
import CustomTooltip from "../../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import NameAvatar from "../../../../../common/NameAvatar";
import { LuExternalLink } from "react-icons/lu";
import { VscChromeClose } from "react-icons/vsc";
import StatusLabelButton from "../../../../statusLabelButton/StatusLabelButton";
import EmailComposer from "../../../../support/FirstColumn/composer/EmailComposer";
import { IoIosArrowBack } from "react-icons/io";
const UserView = ({
  setOpen,
  userID,
  setSideBarOpen,
  hanleSearchContact,
  canHide,
  opendLateViewTicket,
  handleReopenCardClick,
  lastOpendTicketId,
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setSideBarOpen(false);
    setOpen();
  };
  const {
    data: contactData,
    loading: contactDataLoading,
    error: contactDataErro,
    refetchData: refetchConatctData,
  } = useGetData(`companies/${userID}`);
  const [userDeleting, setUserDeleting] = useState(false);
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isComposerVisible, setIsComposerVisible] = useState(false);

  const openComposer = () => {
    setIsComposerVisible(true);
  };

  const closeComposer = () => {
    setIsComposerVisible(false);
  };

  const handleRedirect = (tabId) => {
    dispatch(handSelectCompanyFlowTab(tabId));
    navigate(`/companies/${userID}`);
  };
  useEffect(() => {
    refetchConatctData();
  }, [userID]);

  const redirectToUrl = (dashboard_login_email) => {
    const baseUrl = "https://app.starthubcenters.com";
    const email = decodeURIComponent(dashboard_login_email);
    const params = `autologin=true&email=${email}`;
    window.open(`${baseUrl}?${params}`, "_blank");
  };

  console.log(contactData, "contactData contactData");

  // opendLateViewTicket={opendLateViewTicket}
  // handleReopenCardClick={handleReopenCardClick}
  // lastOpendTicketId={lastOpendTicketId}
  return (
    <Container>
      <Title>
        <span className="label">
          {opendLateViewTicket ? (
            <span
              className="back"
              onClick={() => handleReopenCardClick(lastOpendTicketId)}
            >
              <IoIosArrowBack size={14} color="#2b3746" />
            </span>
          ) : (
            ""
          )}
        </span>
        <div className="icons">
          <span className="icon" onClick={() => handleRedirect("1")}>
            <LuExternalLink size={17}></LuExternalLink>
          </span>
          {canHide && (
            <span className="icon" onClick={handleClose}>
              <VscChromeClose size={18}></VscChromeClose>
            </span>
          )}
        </div>
      </Title>

      <UserAvatar>
        <div className="user-img">
          {contactDataLoading ? (
            <Skeleton.Avatar active size={107} shape="circle" />
          ) : contactData?.data?.avatar ? (
            <img src={contactData?.data?.avatar} alt="" />
          ) : (
            <NameAvatar userName={`${contactData?.data?.name}`}></NameAvatar>
          )}
        </div>
        <div className="btns">
          {contactDataLoading ? (
            <Skeleton.Avatar active size={15} shape="circle" />
          ) : (
            <CustomTooltip
              text={contactData?.data?.status_label}
              marginTop="-25px"
            >
              <StatusLabelButton
                Data={contactData?.data}
                width="90px"
              ></StatusLabelButton>
            </CustomTooltip>
          )}
        </div>
        <div className="user-detail">
          {contactDataLoading ? (
            <Skeleton.Input active size={23} block="false" />
          ) : (
            <p className="user-name">
              {`${contactData?.data?.name}
            `}
            </p>
          )}
          {contactDataLoading ? (
            <Skeleton.Input active size={19.5} block="false" />
          ) : (
            <span className="user-id">
              ID: <span className="id">{contactData?.data?.id}</span>
            </span>
          )}
        </div>
      </UserAvatar>
      <Icons>
        <div className="icons">
          <CustomTooltip text={"Mail"} marginTop="-20px">
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: "105px", height: "67px" }}
                shape="default"
              />
            ) : (
              <span onClick={() => openComposer()} className="icon">
                {GetLogo("userMail")}
              </span>
            )}
          </CustomTooltip>

          <CustomTooltip text={"Call"} marginTop="-20px">
            <span className="icon">
              {contactDataLoading ? (
                <Skeleton.Button
                  active
                  style={{ width: "105px", height: "67px" }}
                  shape="default"
                />
              ) : (
                <span className="icon">{GetLogo("userCall")}</span>
              )}
            </span>
          </CustomTooltip>

          <CustomTooltip text={"Impersonate "} marginTop="-20px">
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: "105px", height: "67px" }}
                shape="default"
              />
            ) : (
              <span
                onClick={() =>
                  redirectToUrl(contactData?.data?.dashboard_login_email)
                }
                className="icon"
              >
                {GetLogo("eye")}
              </span>
              // <span className="icon">{GetLogo("userCall")}</span>
            )}
          </CustomTooltip>
        </div>
        <div className="icons">
          <CustomTooltip
            text={
              contactDataLoading ? "User" : contactData?.data?.contact?.name
            }
            marginTop="-20px"
          >
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: "105px", height: "67px" }}
                shape="default"
              />
            ) : (
              <span
                className="icon"
                onClick={() =>
                  navigate(`/users/${contactData?.data?.contact?.id}`)
                }
              >
                <FaRegUserCircle fill="#556373" size={28} />
              </span>
            )}
          </CustomTooltip>
          <CustomTooltip
            text={
              contactData?.data?.is_mailbox ? "Virtual Mailbox" : "Payments"
            }
            marginTop="-20px"
          >
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: "105px", height: "67px" }}
                shape="default"
              />
            ) : (
              <span
                className="icon"
                onClick={() =>
                  handleRedirect(contactData?.data?.is_mailbox ? "4" : "5")
                }
              >
                {contactData?.data?.is_mailbox ? (
                  <BsMailboxFlag fill="#556373" size={28} />
                ) : (
                  <IoMdCard fill="#556373" size={28} />
                )}
              </span>
            )}
          </CustomTooltip>
          <CustomTooltip text={"Company Details"} marginTop="-20px">
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: "105px", height: "67px" }}
                shape="default"
              />
            ) : (
              <span className="icon" onClick={() => handleRedirect("1")}>
                {GetLogo("userCompany")}
              </span>
            )}
          </CustomTooltip>
        </div>
      </Icons>

      <EmailComposer
        visible={isComposerVisible}
        onClose={closeComposer}
        contactData={contactData?.data}
        callingFromCompany={true}
      />
    </Container>
  );
};

export default UserView;
