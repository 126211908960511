import styled from "styled-components";
import { Collapse, Tag } from "antd";
const MainContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  ${"" /* min-width:400px; */}
  gap: 24px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid rgba(76, 187, 203, 0.2);
  max-width: 442px;
  width: 100%;

  .tickets_hearder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .header_tickets {
        display: flex;
        width: 100%;
        max-width: 530.5px;
        align-items: center;
        gap: 24px;

        .header_container {
          display: flex;
          align-items: center;
          gap: 12px;

          span {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.7px;
          }

          .icon {
            display: flex;
            padding: 2px 4px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            height: 16px;
            width: 17px;
            border-radius: 3px;
            color: var(--White-100, #fff);
            text-align: center;
            font-family: Outfit;
            font-size: 8px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px; /* 150% */
            text-transform: uppercase;
            background: var(--Blue-100, #4cbbcb);
          }
        }
      }
    }

    .tickets_header_container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      width: 100%;

      .tickets_header_span {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        max-width: 100px;
        span {
          color: var(--Gray-60, rgba(85, 99, 115, 0.6));
          text-align: right;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }

      .ticket_header_text {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        width: 100px;
        span {
          color: var(--Gray-60, rgba(85, 99, 115, 0.6));
          text-align: right;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
        svg {
          width: 16px;
          height: 16px;
        }
        .ant-dropdown{
          color: var(--Gray-60, rgba(85, 99, 115, 0.6));
          text-align: right;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }

  .tickets_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    max-width: 394px;

    .ticket-dropdowns {
      /* display: flex; */
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      max-width: 394px;
      .dropdowns {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        max-width: 394px;
        .ant-collapse {
          width: 394px;
        }
        .all_dropdowns {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          .dropdowns_container {
            display: flex;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
            border-radius: 4px;
            border: 1px solid rgba(208, 222, 225, 0.6);
            background: var(--White-100, #fff);

            .ant-collapse {
              max-width: 394px;
              width: 100%;
            }
            .dropdowns_span {
              display: flex;
              align-items: center;
              gap: 12px;
              align-self: stretch;
              img {
                display: flex;
                width: 20px;
                height: 20px;
                justify-content: center;
                align-items: center;
              }

              .span {
                display: flex;
                padding-right: 8px;
                align-items: center;
                gap: 24px;
                flex: 1 0 0;
                span {
                  display: flex;
                  align-items: center;
                  gap: 12px;
                  flex: 1 0 0;
                  text {
                    color: var(--Dark-Gray-100, #2b3746);
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 100% */
                  }
                }
              }

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
  ${"" /* } */}
`;

const StyledCollapse = styled(Collapse)`
  background: #f8fbfc;
  border: 1px solid #e0e6eb;
  border-radius: 8px;
  border-radius: 4px;
  border: 1px solid rgba(208, 222, 225, 0.6);
  background: var(--White-100, #fff);
  .ticket-colappse {
    .ant-collapse-header {
      font-weight: 600;
      font-size: 16px;
      color: #2b3746;
      display: flex !important;
      flex-direction: row-reverse !important;
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
      height: 56px;
      align-items: center !important;
      .ant-collapse-header-text {
        div span {
          display: inline-block;
          max-width: 203px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: top;
        }
      }
      .ant-collapse .ant-collapse-content {
        color: rgba(0, 0, 0, 0.88);
        background-color: #ffffff;
        border-top: none !important;
      }
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      font-weight: 600;
      font-size: 16px;
      color: #2b3746;
      display: flex !important;
      flex-direction: row-reverse !important;
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
      height: 56px;
      padding-bottom: 0px !important;
      align-items: center !important;
      .ant-collapse-header-text {
        div span {
          display: inline-block;
          max-width: 203px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: top;
        }
      }
      .ant-collapse .ant-collapse-content {
        color: rgba(0, 0, 0, 0.88);
        background-color: #ffffff;
        border-top: none !important;
      }
    }
  }

  .ant-collapse-content {
    background: #ffffff;
    border-top: none;
  }
`;

const PanelContent = styled.div`
  color: #667085;
  font-size: 14px;
  padding: 16px 0;
  padding-top: 0px !important;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Gray-80, rgba(85, 99, 115, 0.8));
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #667085;
  font-size: 16px;
`;

const StyledTag = styled(Tag)`
  display: flex;
  height: 20px;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 1px solid var(--Blue-70, rgba(76, 187, 203, 0.7));
  background: var(--Blue-20, rgba(76, 187, 203, 0.2));
`;
export {
  MainContainer,
  StyledCollapse,
  PanelContent,
  Footer,
  LeftSection,
  RightSection,
  StyledTag,
};
