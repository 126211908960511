import React from 'react'
import { MainContainer } from "./styles";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import AccountManagerProfile from '../../components/accountManagerComponents/accountManagerProfile/AccountManagerProfile';
import AccountManagerCards from '../../components/accountManagerComponents/accountManagerCards/AccountManagerCards';
import AccountManagerTable from '../../components/accountManagerComponents/accountManagerTable/AccountManagerTable';
import AccountManagerTaskComponents from '../../components/accountManagerComponents/accountManagerTaskCompoments/AccountManagerTaskComponent';

const AccountManager = () => {
  return (
    <div>
    <CommonHaeder
      firstBcItem={"Mail Operator"}
      firstBcIcon="user"
    ></CommonHaeder>
    <MainContainer>
      <div className="adminProfile">
        <div className="admin_continer">
        <AccountManagerProfile />
        <AccountManagerCards/>
        <AccountManagerTaskComponents/>
         <AccountManagerTable/>   
        </div>
      </div>
    </MainContainer>
  </div>
  )
}

export default AccountManager
