import styled from "styled-components";

const Container = styled.div`
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
          props.open
            ? "var(--Blue-100, #4cbbcb)"
            : "var(--Gray-5, rgba(85, 99, 115, 0.05))"};

        .ant-collapse-header-text {
          color: ${(props) =>
            props.open
              ? "var(--White-100, #fff)"
              : "var(--Dark-Gray-100, #2B3746)"};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
                props.open
                  ? "var(--White-100, #fff)"
                  : "var(--Dark-Gray-100, #2B3746)"};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 339px;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const SearchContainer = styled.div`
  display: flex;
  width: 339px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .search {
    display: flex;
    gap: 14px;
    .btns-div {
      display: flex;
      gap: 8px;
    }
    .search-input {
      display: flex;
      width: 208px;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
      border-radius: 20px;
      background: var(--Soft-Blue-100, #eff5f6);
      height: 25px;
      .input {
        background: var(--Soft-Blue-100, #eff5f6);
        overflow: hidden;
        border: none;
        outline: none;
      }
      .input::placeholder {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.42px;
      }
      .icon {
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .search-icon {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      cursor: pointer;
      /* width: 24px;
      height: 24px; */
      .fa-search {
        display: flex;
        padding: 6px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        box-shadow: 0px 2px 8px 0px rgba(208, 222, 225, 0.6);
      }
      .search-title {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }
`;

const ContentCard = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #fff;

  .paragraf {
    .color-null {
      color: var(--Blue-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
    }

    .colorfull {
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }

  .icons-and-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .date {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }

    /* .icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 32px; */
    .active {
      display: flex;
      padding: 6px 12px;
      align-items: center;
      gap: 10px;
      /* cursor: pointer; */
      border-radius: 4px;
      border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
      background: rgba(76, 203, 96, 0.2);
      .icon {
        svg {
          width: 8px;
          height: 8px;
        }
      }

      .text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
      /* } */
      .icon {
        width: 16px;
        height: 16px;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
        }
      }
      .active {
        display: flex;
        padding: 6px 12px;
        align-items: center;
        gap: 10px;
        /* cursor: pointer; */
        border-radius: 4px;
        border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
        background: rgba(76, 203, 96, 0.2);
        .icon {
          svg {
            width: 8px;
            height: 8px;
          }
        }

        .text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
    }
  }
`;

const PaginationContainer = styled.div`
  .ant-pagination-prev {
    min-width: 16px !important;
    width: 16px !important;
  }
  .ant-pagination-next {
    min-width: 16px !important;
    width: 16px !important;
  }
  .ant-pagination-item {
    min-width: 24px !important;
    height: 16px !important;
    width: 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
  }
  .pagination-div {
    .ant-pagination-options {
      display: none;
    }
    .ant-pagination-prev {
      width: 16px !important;
      height: 16px !important;
      width: 16px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      svg {
        margin-bottom: 9px;
      }
    }

    .ant-pagination-next {
      width: 16px !important;
      height: 16px !important;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      svg {
        margin-bottom: 9px;
      }
    }

    :where(.css-dev-only-do-not-override-2rgkd4)
      .ant-pagination
      .ant-pagination-prev {
      min-width: 16px !important;
      width: 16px !important;
    }
    .ant-pagination-item {
      width: 24px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      a {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
        text-transform: uppercase;
      }
    }
    .ant-pagination-item-active {
      width: 24px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: rgba(76, 187, 203, 0.15);
      /* border: 1px solid #4cbbcb; */
      border: none;
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 160% */
      text-transform: uppercase;
      a {
        color: var(--Blue-100, #4cbbcb);
        font-family: Outfit;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
        text-transform: uppercase;
      }
    }
  }
`;

export {
  Container,
  ContentContainer,
  SearchContainer,
  ContentCard,
  PaginationContainer,
};
