import React, { useEffect, useState } from "react";
import ChatInputComponentContainer from "./styles";
import MessageBody from "./messageBody/MessageBody";
import CommentBody from "./commentBody/CommentBody";
import { MdDragHandle } from "react-icons/md";
import { useSelector } from "react-redux";
import { BsArrow90DegDown } from "react-icons/bs";
import { FaArrowDown } from "react-icons/fa6";
import { RiArrowDownSFill } from "react-icons/ri";
import { IoReturnUpForwardOutline } from "react-icons/io5";
import CustomTooltip from "../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
const ChatInputComponent = ({
  onSend,
  handleCommentSend,
  savingComment,
  email,
  sendingMail,
  selectedTab,
  setSelectedTab,
  editingNote,
  selectedFolder,
  forwardMailDetails,
  isForwardMail,
  handleReplyEmail,
  clearInputtext,
  forwardFiles,
  setForwardFiles,
  chatThread,
  setFrom,
  from,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [formData, setFormData] = useState({
    to: [],
    cc: [],
    bcc: [],
    message: "",
  });

  const [commentFormData, setCommentFormData] = useState({
    comment: "",
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [containerHeight, setContainerHeight] = useState(300);
  const [isDragging, setIsDragging] = useState(false);
  const [sendChatToEmail, setSendChatToEmail] = useState(false);
  const [startY, setStartY] = useState(0);
  const [startHeight, setStartHeight] = useState(0);
  const [value, setValue] = useState("");
  const { app, auth } = useSelector((state) => state);
  useEffect(() => {
    if (editingNote) {
      setValue(editingNote.notes);
    } else {
      setValue("");
    }
  }, [editingNote]);

  const handleFileSelect = (files) => {
    setSelectedFiles(files);
  };

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSend = () => {
    onSend({ ...formData, files: selectedFiles });
    setSelectedFiles([]);
  };

  const handleCommentInputChange = (name, value) => {
    setCommentFormData({ ...commentFormData, [name]: value });
  };

  const handleSendComment = () => {
    handleCommentSend({ ...commentFormData, files: selectedFiles });
    setCommentFormData({ comment: "" });
    setSelectedFiles([]);
    setValue("");
  };

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartY(e.clientY);
    setStartHeight(containerHeight);
  };

  const handleDrag = (e) => {
    if (!isDragging) return;
    const diffY = startY - e.clientY;
    setContainerHeight(startHeight + diffY);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };
  useEffect(() => {
    setValue("");
  }, [clearInputtext]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);
    } else {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    }
    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [isDragging]);

  useEffect(() => {
    app?.allOpenConversationDetails?.Model === "SupportEmails" &&
      setSendChatToEmail(false);
    setSelectedTab("Email");
  }, [app?.allOpenConversationDetails?.Model]);
  return (
    <ChatInputComponentContainer
      style={{
        height: `${containerHeight}px`,
        filter:
          app?.allOpenConversationDetails?.user_id == null &&
          selectedFolder != "Inbox" &&
          app?.allOpenConversationDetails?.Model == "LiveChat"
            ? "blur(1px)"
            : "",
        pointerEvents:
          app?.allOpenConversationDetails?.user_id == null &&
          selectedFolder != "Inbox" &&
          app?.allOpenConversationDetails?.Model == "LiveChat"
            ? "none"
            : "",
        opacity:
          app?.allOpenConversationDetails?.user_id &&
          (selectedFolder != "Inbox") == null &&
          app?.allOpenConversationDetails?.Model == "LiveChat"
            ? "0.5"
            : "",
      }}
    >
      <div className="tabs">
        <span className="drag-button" onMouseDown={handleDragStart}>
          <MdDragHandle size={30} color="var(--Dark-Gray-100, #2b3746)" />
        </span>
        <div className="dropdownMenu-container">
          <div
            onClick={() => setSelectedTab("Email")}
            className={`tab ${selectedTab === "Email" ? "active" : ""}`}
          >
            <span>
              {selectedFolder == "Chat" ||
              app?.allOpenConversationDetails?.Model == "LiveChat"
                ? "Live Chat"
                : "Email"}
            </span>
            {/* {app?.allOpenConversationDetails?.Model == "LiveChat" && (
              <span onClick={() => setDropdownOpen(!dropdownOpen)}>
                <RiArrowDownSFill size={18} />
              </span>
            )} */}
          </div>

          {/* {dropdownOpen &&
            app?.allOpenConversationDetails?.Model == "LiveChat" && (
              <div className="dropdown-menu">
                {!sendChatToEmail ? (
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setSendChatToEmail(true);
                      setDropdownOpen(!dropdownOpen);
                    }}
                  >
                    Email
                  </div>
                ) : (
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setSendChatToEmail(false);
                      setDropdownOpen(!dropdownOpen);
                    }}
                  >
                    Live Chat
                  </div>
                )}
              </div>
            )} */}
        </div>
        {/* onClick={() => {
                // setSelectedTab("Email");
                // setDropdownOpen(false);
              }} */}
        <div
          className={`tab ${selectedTab === "Comment" ? "active" : ""}`}
          onClick={() => setSelectedTab("Comment")}
        >
          Comment
        </div>
        {app?.allOpenConversationDetails?.Model == "LiveChat" && (
          <div
            className={`tab ${selectedTab === "Transcript" ? "active" : ""}`}
            onClick={() => {
              setSelectedTab("Transcript");
              setSendChatToEmail(true);
            }}
            style={{ paddingBottom: "4px" }}
          >
            {/* Email Transcript */}
            <CustomTooltip text="Email Transcript to Client" marginTop="-25px">
              <IoReturnUpForwardOutline size={18} />
            </CustomTooltip>
          </div>
        )}
      </div>

      {(selectedTab === "Email" || selectedTab === "Transcript") && (
        <MessageBody
          onFileSelect={handleFileSelect}
          onInputChange={handleInputChange}
          handleSend={handleSend}
          email={email}
          sendingMail={sendingMail}
          selectedFolder={selectedFolder}
          forwardMailDetails={forwardMailDetails}
          isForwardMail={isForwardMail}
          handleReplyEmail={handleReplyEmail}
          clearInputtext={clearInputtext}
          forwardFiles={forwardFiles}
          setForwardFiles={setForwardFiles}
          sendChatToEmail={sendChatToEmail}
          setSendChatToEmail={setSendChatToEmail}
          chatThread={chatThread}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setFrom={setFrom}
          from={from}
        />
      )}
      {selectedTab === "Comment" && (
        <CommentBody
          onFileSelect={handleFileSelect}
          onInputChange={handleCommentInputChange}
          handleSend={handleSendComment}
          savingComment={savingComment}
          editingNote={editingNote}
          value={value}
          setValue={setValue}
        />
      )}
    </ChatInputComponentContainer>
  );
};

export default ChatInputComponent;
