import React, { useEffect, useState } from "react";
import "./styles";
import { Container, Title, UserAvatar } from "./styles";
import { useNavigate } from "react-router-dom";
import useGetData from "../../../../../hooks/useGetData";
import { useDispatch } from "react-redux";
import NameAvatar from "../../../../../common/NameAvatar";
import { LuExternalLink } from "react-icons/lu";
import { Skeleton } from "antd";
import {
  handSelectCompanyFlowTab,
  handSelectUserFlowTab,
} from "../../../../../redux/app/appSlice";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../../App";
import { IoIosArrowBack } from "react-icons/io";
const SupportHeader = ({
  userID,
  showDrawer,
  refetchData,
  fromTicket,
  email,
  selectedFolder,
  senderName,
  emailData,
  setClickedCompany,
  clickedCompany,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state);

  const firstName = emailData?.data?.contact?.first_name || "";
  const lastName = emailData?.data?.contact?.last_name || "";

  const fetchContactData = async () => {
    if (!userID) return;
    setLoading(true);
    try {
      const response = await InstanceWithAuth.get(`contacts/${userID}`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        setContactData(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch contact data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, [userID]);

  const handleRedirect = () => {
    dispatch(handSelectUserFlowTab("1"));
    const url = `${window.location.origin}/users/${
      app?.allOpenConversationDetails?.Model == "SupportEmails"
        ? userID
        : app?.allOpenConversationDetails?.contact_id
    }`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleRedirectToCompany = (companyId) => {
    dispatch(handSelectCompanyFlowTab("1"));
    const url = `/companies/${
      selectedFolder == "Chat" ||
      selectedFolder == "All Open" ||
      selectedFolder === "Unassigned" ||
      selectedFolder === "Assigned to me"
        ? companyId
        : contactData?.data?.companies[0]?.id
    }`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Container>
      <Title>
        {clickedCompany && (
          <span className="back" onClick={() => setClickedCompany(null)}>
            <IoIosArrowBack size={14} color="#2b3746" />
          </span>
        )}

        <div className="avatar-wrapper">
          <div className="user-img">
            {/* <img className="contact-image" src={contactData?.data?.avatar} alt="" /> */}

            {contactData?.data?.avatar ? (
              <img
                className="contact-image"
                src={contactData?.data?.avatar}
                alt=""
              />
            ) : (
              <NameAvatar
                val={40}
                userName={
                  app?.allOpenConversationDetails?.contact_id &&
                  app?.allOpenConversationDetails?.Model === "SupportEmails"
                    ? `${firstName || ""} ${lastName || ""}`.trim()
                    : app?.allOpenConversationDetails?.contact_id &&
                      app?.allOpenConversationDetails?.Model === "LiveChat"
                    ? `${
                        app?.allOpenConversationDetails?.contact?.first_name ||
                        ""
                      } ${
                        app?.allOpenConversationDetails?.contact?.last_name ||
                        ""
                      }`.trim()
                    : app?.allOpenConversationDetails?.sender_name
                    ? app?.allOpenConversationDetails?.sender_name
                    : app?.allOpenConversationDetails?.company_id
                    ? app?.allOpenConversationDetails?.company_name
                    : app?.allOpenConversationDetails?.guest_name || ""
                }
              ></NameAvatar>
            )}
          </div>
          <div className="name-wrapper">
            <span className="name">
              {app?.allOpenConversationDetails?.contact_id &&
              app?.allOpenConversationDetails?.Model === "SupportEmails"
                ? `${firstName || ""} ${lastName || ""}`.trim()
                : app?.allOpenConversationDetails?.contact_id &&
                  app?.allOpenConversationDetails?.Model === "LiveChat"
                ? `${
                    app?.allOpenConversationDetails?.contact?.first_name || ""
                  } ${
                    app?.allOpenConversationDetails?.contact?.last_name || ""
                  }`.trim()
                : app?.allOpenConversationDetails?.sender_name
                ? app?.allOpenConversationDetails?.sender_name
                : app?.allOpenConversationDetails?.company_id
                ? app?.allOpenConversationDetails?.company_name
                : app?.allOpenConversationDetails?.guest_name || ""}
            </span>
            <span
              // onClick={() =>
              //   handleRedirectToCompany(
              //     app?.allOpenConversationDetails?.company_id
              //   )
              // }
              onClick={() =>
                showDrawer(
                  "company",
                  app?.allOpenConversationDetails?.company_id
                )
              }
              className="desc"
            >
              {/* {selectedFolder == "Chat" || selectedFolder == "All Open"
                ? app?.allOpenConversationDetails?.company_name
                : */}
              {contactData?.data?.company_name}
              {/* } */}
            </span>

            {!fromTicket && (
              <button
                onClick={() =>
                  showDrawer(
                    "contact",
                    app?.allOpenConversationDetails?.contact_id
                  )
                }
                // onClick={showDrawer}
                className="btn"
              >
                View Contact Details
              </button>
            )}
          </div>
        </div>
        <div className="icons">
          <span className="icon" onClick={() => handleRedirect("1")}>
            <LuExternalLink size={17}></LuExternalLink>
          </span>
        </div>
      </Title>
    </Container>
  );
};

export default SupportHeader;
