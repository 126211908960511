import {
  MainContainer,
  StyledCollapse,
  PanelContent,
  Footer,
  LeftSection,
  RightSection,
  StyledTag,
  
} from "./styles";
import { Dropdown, Space } from "antd";
import { Collapse,  Avatar } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
const AccountManagerSupport = ({ item }) => {
  const dropdownItems = [
    { id: 11, text: "Missing Scan for Client #789" },
    { id: 21, text: "Pending Approval for Order #456" },
    { id: 31, text: "New Message from Client #123" },
    { id: 41, text: "Shipment Delayed for Client #654" },
  ];
  const items = [
    {
      key: "1w",
      label: "2 month",
    },
    {
      key: "2w",
      label: "4 month",
    },
    {
      key: "3w",
      label: "6 month",
    },
  ];
  const mailItem = [
    {
      key: "112",
      label: "2 month",
    },
    {
      key: "212",
      label: "4 month",
    },
    {
      key: "312",
      label: "6 month",
    },
  ];
  const { Panel } = Collapse;

  return (
    <MainContainer>
      {/* <div className="tiekets_container"> */}
      <div className="tickets_hearder">
        <div className="header">
          <div className="header_tickets">
            <div className="header_container">
              <span>Support Inbox</span>
            </div>
          </div>

          <div className="tickets_header_container">
            <Dropdown menu={{ items }} className="tickets_header_span">
              <a href="#" onClick={(e) => e.preventDefault()}>
                <Space>
                  <span>Prioroty</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M4.49988 7.5L8.49988 11.5L12.4999 7.5"
                      stroke="#556373"
                      strokeOpacity="0.2"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Space>
              </a>
            </Dropdown>

            <Dropdown menu={{ items: mailItem }} className="ticket_header_text">
              <a href="#" onClick={(e) => e.preventDefault()}>
                <Space>
                  <span>Client</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M4.49988 7.5L8.49988 11.5L12.4999 7.5"
                      stroke="#556373"
                      strokeOpacity="0.2"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="tickets_body">
        <div className="ticket-dropdowns">
          <div className="dropdowns">
            {dropdownItems.map((item) => (
              <StyledCollapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MdOutlineKeyboardArrowDown fill="rgb(76, 187, 203)"></MdOutlineKeyboardArrowDown>
                  ) : (
                    <MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>
                  )
                }
                defaultActiveKey={[item?.id]}
              >
                <Panel
                  className="ticket-colappse"
                  header={
                    // <div
                    //   style={{
                    //     display: "flex",
                    //     alignItems: "center",
                    //     gap: "6px",
                    //   }}
                    // >
                    //   <Avatar size={24} src="https://via.placeholder.com/30" />
                    //   <div
                    //     style={{
                    //       display: "flex",
                    //       justifyContent: "space-between",
                    //       alignItems: "center",
                    //       width: "100%",
                    //       MaxWidth: "230px",
                    //       padding:'0 8px 0 0'
                    //     }}
                    //   >
                    //   <span>Floyd Miles</span>{" "}
                    //   <span
                    //     style={{
                    //       color: "var(--Gray-60, rgba(85, 99, 115, 0.60))",
                    //       fontFamily: "Outfit",
                    //       fontSize: "12px",
                    //       textAlign: 'right',
                    //       fontStyle: "normal",
                    //       fontWeight: "400",
                    //       lineHeight: "16px",
                    //     }}
                    //   >
                    //     12:12:34
                    //   </span>
                    // </div>
                    // </div>
                    <div
                      style={{
                        display: "flex",
                        paddingRight: "8px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: "1 0 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        {/* <div
                        style={{
                          display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        }}
                      > */}
                        <Avatar
                          size={24}
                          src="https://via.placeholder.com/30"
                        />
                        <span>Floyd Miles</span> {/* </div> */}
                      </div>
                      <span
                        style={{
                          color: "var(--Gray-60, rgba(85, 99, 115, 0.60))",
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          textAlign: "right",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                      >
                        12:12:34
                      </span>
                    </div>
                  }
                  key="1"
                >
                  <PanelContent>
                    Client requested urgent forwarding for their mail item (Box
                    #22) to be delivered to their new address.
                  </PanelContent>
                  <Footer>
                    <LeftSection>11 October 2023</LeftSection>
                    <StyledTag>New</StyledTag>
                    <RightSection>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                      >
                        <path
                          d="M15.8403 6.63339L8.84034 0.133394C8.76904 0.0672732 8.68001 0.0233754 8.58415 0.0070754C8.48829 -0.00922458 8.38976 0.00278075 8.30061 0.0416215C8.21147 0.0804623 8.13559 0.144453 8.08225 0.225759C8.02892 0.307065 8.00045 0.402158 8.00034 0.499395V4.01039C2.97934 4.22739 0.000335693 7.73939 0.000335693 13.4994C0.000461251 13.6078 0.0358391 13.7133 0.101135 13.7999C0.166431 13.8865 0.258106 13.9495 0.362336 13.9794C0.407085 13.9929 0.453597 13.9996 0.500336 13.9994C0.585139 13.9994 0.668548 13.9778 0.74272 13.9367C0.816893 13.8956 0.87939 13.8363 0.924336 13.7644C2.16634 11.7774 3.56734 10.1204 8.00034 10.0054V13.4994C8.00045 13.5966 8.02892 13.6917 8.08225 13.773C8.13559 13.8543 8.21147 13.9183 8.30061 13.9572C8.38976 13.996 8.48829 14.008 8.58415 13.9917C8.68001 13.9754 8.76904 13.9315 8.84034 13.8654L15.8403 7.36539C15.8906 7.3186 15.9307 7.26196 15.9581 7.19899C15.9855 7.13602 15.9997 7.06807 15.9997 6.99939C15.9997 6.93072 15.9855 6.86277 15.9581 6.7998C15.9307 6.73683 15.8906 6.68019 15.8403 6.63339Z"
                          fill="#556373"
                          fill-opacity="0.4"
                        />
                      </svg>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="12"
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M8.5 0C4.1896 0 0.5 4.57371 0.5 6C0.5 7.49314 3.3368 12 8.5 12C13.6632 12 16.5 7.49314 16.5 6C16.5 4.57371 12.8104 0 8.5 0ZM8.5 8.57143C8.02532 8.57143 7.56131 8.42062 7.16663 8.13806C6.77195 7.85551 6.46434 7.45391 6.28269 6.98404C6.10104 6.51418 6.05351 5.99715 6.14612 5.49834C6.23872 4.99953 6.4673 4.54135 6.80294 4.18173C7.13859 3.8221 7.56623 3.5772 8.03178 3.47798C8.49734 3.37876 8.9799 3.42968 9.41844 3.62431C9.85698 3.81893 10.2318 4.14852 10.4955 4.57139C10.7592 4.99426 10.9 5.49142 10.9 6C10.9 6.68199 10.6471 7.33604 10.1971 7.81827C9.74697 8.30051 9.13652 8.57143 8.5 8.57143Z"
                          fill="#556373"
                          fill-opacity="0.4"
                        />
                      </svg>
                    </RightSection>
                  </Footer>
                </Panel>
              </StyledCollapse>
            ))}
          </div>
        </div>
      </div>
      {/* </div> */}
    </MainContainer>
  );
};

export default AccountManagerSupport;
