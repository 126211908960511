import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles";
import useGetData from "../../../../hooks/useGetData";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

const ChatHeaderDropDown = ({
  selectedEmailId,
  setCurrentEmployeeImage,
  email,
  setSelectedUser,
  selectedUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const { showMessage } = useGlobalAlertMessage();
  const { auth } = useSelector((state) => state);

  const { data: employees, error } = useGetData("users-home");
  useEffect(() => {
    setSearchQuery("");
  }, [isOpen]);

  useEffect(() => {
    if (employees && email?.data?.user_id) {
      const matchingRole = employees?.roles?.find((role) =>
        role.users.some((user) => user.id === email?.data?.user_id)
      );

      if (matchingRole) {
        const matchingUser = matchingRole?.users?.find(
          (user) => user.id === email?.data?.user_id
        );

        if (matchingUser) {
          setSelectedUser(matchingUser);
          setCurrentEmployeeImage(matchingUser);
        }
      } else {
        const adminRole = employees.roles.find((role) => role.name === "Admin");

        if (adminRole && adminRole.users.length > 0) {
          setSelectedUser(adminRole.users[0]);
          setCurrentEmployeeImage(adminRole.users[0]);
        }
      }
    } else {
      setSelectedUser(null);
    }
  }, [employees, email]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectUser = async (roleId, userId) => {
    console.log(email?.data?.type);
    const role = employees.roles.find((role) => role.id === roleId);
    const user = role.users.find((user) => user.id === userId);

    setSelectedUser(user);
    setCurrentEmployeeImage(user);

    setIsOpen(false);

    try {
      const response = await InstanceWithAuth.get(
        `${process.env.REACT_APP_API_BASE_URL}/support/emails/${selectedEmailId}/change-owner/${userId}?type=${email?.data?.type}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  // Filter employees based on the search query
  const filteredEmployees = employees?.roles
    .map((role) => ({
      ...role,
      users: role.users.filter((user) =>
        `${user.first_name} ${user.last_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      ),
    }))
    .filter((role) => role.users.length > 0);

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {selectedUser
              ? `${selectedUser?.first_name} ${selectedUser?.last_name}`
              : "Select Employee"}
          </div>
          <MdOutlineKeyboardArrowDown
            fill="rgb(76, 187, 203)"
            width="1rem"
            height="1.5rem"
            size="15px"
          />
        </div>

        {isOpen && (
          <ul className="dropdown-list">
            {/* Search input field */}
            <li className="search-input">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-bar"
              />
            </li>

            {/* Dropdown items filtered by search query */}
            <div style={{ marginTop: "40px" }}>
              {filteredEmployees?.map(
                (role) =>
                  role.users.length > 0 && (
                    <li key={role.id} className="role-item">
                      <strong>{role.name}</strong>
                      <ul className="items">
                        {role.users.map((user) => (
                          <li
                            key={user.id}
                            className="dropdown-item"
                            onClick={() => handleSelectUser(role.id, user.id)}
                          >
                            {user.avatar ? (
                              <img
                                src={user.avatar}
                                alt={`${user.first_name} ${user.last_name}`}
                                className="user-avatar"
                              />
                            ) : (
                              <div className="user-initials">
                                {user?.first_name[0]}
                                {user?.last_name[0]}
                              </div>
                            )}
                            <div className="employee-name">
                              {user.first_name} {user.last_name}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )
              )}
            </div>
          </ul>
        )}
      </div>
    </MainContainer>
  );
};

export default ChatHeaderDropDown;
