import styled from "styled-components";

const ChatMessageContainer = styled.div`
  border: 1px solid #e3eced;
  border-left: ${({ sender }) =>
    sender === "Client" ? "4px solid var(--Blue-100, #4cbbcb);" : ""};
  background: white;
  min-height: 80px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 7px;
  border: 1px solid var(--Blue-100, #4cbbcb);
  background: var(--Blue-20, rgba(76, 187, 203, 0.2));

  .email-item {
    padding: 10px;
  }

  .email-header {
    display: flex;
    align-items: flex-start;
  }

  .email-icon {
    background-color: #2b3746;
    color: #fff;
    padding: 6px;
    border-radius: 25px;
    font-size: 14px;
    width: 30px;
    height: 30px;
    text-align: center;
  }

  .selected-email-icon {
    background-color: var(--Blue-100, #4cbbcb);
    color: #fff;
    padding: 6px;
    border-radius: 25px;
    font-size: 14px;
    width: 30px;
    height: 30px;
    text-align: center;
  }

  .email-details {
    flex: 1;
    margin-left: 10px;
    position: relative;

    .toggle-button {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 110px;
      padding-top: 60px;
      margin-top: -60px;
      z-index: 10;
    }
  }
  .vissible-msg {
    display: flex;
    justify-content: start;
    gap: 5px;
    align-items: center;
    .msg {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .email-top-row {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;

    .left-container {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;

      .arrow-down {
        cursor: pointer;
        padding-top: 2.5px;
      }
    }

    .make-row {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .dropdown-menu {
        position: absolute;
        top: 15px;
        right: 0;
        background-color: white;
        border: 1px solid #ddd;
        padding: 8px;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        width: 100px;

        div {
          padding: 8px 12px;
          color: var(--Gray-80, rgba(85, 99, 115, 0.8));
          text-align: center;
          font-family: Outfit;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }

    .email-sender {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .email-time {
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-right: 5px;
    }
  }

  .email-subject {
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    line-height: 24px;
    color: var(--Gray-100, #556373);
    max-height: ${({ expanded }) => (expanded ? "none" : "170px")};
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s ease;

    .datmIR .email-subject figure img{
      width: auto !important;
    }

    /* Fade effect */
    ${({ expanded, showFaded }) =>
    !expanded &&
    showFaded &&
    `
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }
    `}
  }

  .show-more {
    cursor: pointer;
    color: #4cbbcb;
    display: flex;
    align-items: center;
    text-align: right;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }

  .arrow-down {
    cursor: pointer;
    padding-top: 2.5px;
  }
`;

const AttachmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  z-index: -10;
`;

const AttachmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f7f7f7;
  flex-basis: calc(50% - 10px);
  position: relative;
  color: var(--Gray-80, rgba(85, 99, 115, 0.8));
  text-align: center;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 140px;
  cursor: pointer;
`;

export { ChatMessageContainer, AttachmentList, AttachmentItem };
