import { Form } from "antd";
import styled from "styled-components";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100% !important;
  .form-item {
    width: 100% !important;
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.63px;
    margin-top: 0px;
    margin-bottom: 0px;

    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item {
      width: 100% !important;
      margin-bottom: 0;
    }

    .ant-form-item-row {
      width: 100%;
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      gap: 4px;
    }

    label {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      height: auto;
    }
    .ant-form-item-explain-error {
      font-size: 10px;
      font-weight: 400;
      font-family: Outfit;
    }
    .input-static {
      display: flex;
      padding: 12px !important;
      justify-content: space-between;
      width: 548px;
      max-width: 100%;
      box-sizing: border-box;
      border: none;
      outline: none;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      background: #f9f9f9;
      height: 42px;
      font-family: Outfit;
      .ant-select,
      .ant-select-selector {
        border: none;
        padding: 0;
      }
      .ant-select,
      .ant-select-selector:hover:active::after {
        border: none;
        padding: 0;
      }
    }

    .location {
      align-items: center;
      width: 100%;
      height: 41px;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      height: auto;
      min-height: 42px;
      padding-top: 4px;
      padding-right: 15px !important;
      font-family: Outfit;

      .ant-select-selector {
        border: none;
        outline: none;
        box-shadow: none;
        .ant-select-selection.ant-select-selection--single {
          background: green;
        }
      }
    }
    .location::placeholder {
      color: var(--Gray-40, rgba(85, 99, 115, 0.4));
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }
    .location:focus::placeholder {
      color: transparent;
    }
    .input {
      display: flex;
      height: 85px;
      padding: 12px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      font-family: Outfit;
      border: 1px solid #d0dee1;
      width: 100% !important;
      background: #fff;
      resize: none;
      /* min-width: 290px; */
    }

    .input::placeholder {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }
    .ant-select-selection-item {
      ${
        "" /* color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; */
      }
    }
  }
`;

const StyledContainer = styled.div`
  /* max-width: 500px; */
  /* padding: 5px; */
  border-radius: 8px;
  background: #fff;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
  width: 100% !important;
  .title-main {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.63px;
  }
  .info-message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 50px;
    span {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }
  .modal-footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 5px;

    .close-button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border-radius: 3px;
      border: 1px solid #d0dee1;
      background: #fff;
      cursor: pointer;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .save-button {
      cursor: pointer;
      display: flex;
      height: 32px;
      padding: 0px 20px;
      align-items: center;
      border-radius: 3px;
      border: 1px solid var(--Blue-100, #4cbbcb);
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
      color: var(--Blue-100, #4cbbcb);
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: var(--Blue-100, #4cbbcb);
    }
  }
`;
export { StyledForm, StyledContainer };
