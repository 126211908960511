import React, { useEffect, useRef, useState } from "react";
import { ChatMessageContainer, AttachmentList } from "./styles";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Avatar from "../avatar/avatar";
import CustomTooltip from "../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import MessageDetailsTooltip from "./messageDetailsTooltip/MessageDetailsTooltip";
import { useSelector } from "react-redux";
function ChatMessage({
  message,
  sender,
  timestamp,
  imageName,
  emailData,
  currentEmployeeImage,
  time,
  attachments,
  sender_avatar,
  type,
  allAvailbleConversations,
  currentThread,
  chatThread,
}) {
  const { app, auth } = useSelector((state) => state);
  const [showTooltip, setShowTooltip] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showFaded, setShowFaded] = useState(false);
  // const containerRef = useRef(null);
  // const [showToggleButton, setShowToggleButton] = useState(false);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     setShowToggleButton(containerRef.current.clientHeight > 170);
  //   }
  // }, [message, expanded]);

  // useEffect(() => {
  //   if (message?.length > 150 && !expanded) {
  //     setShowFaded(true);
  //   } else {
  //     setShowFaded(false);
  //   }
  // }, [expanded, message?.length]);

  // const toggleExpand = () => {
  //   setExpanded(!expanded);
  // };

  const TruncateText = ({ text }) => {
    const maxLength = 15;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  let currentThreadOwner = allAvailbleConversations?.data?.filter(
    (chat) => chat?.id == currentThread?.live_chat_id
  )?.[0];

  let Msgsender =
    type == "agent" && app?.allOpenConversationDetails?.owner != null
      ? app?.allOpenConversationDetails?.owner
      : type == "agent" && app?.allOpenConversationDetails?.owner == null
      ? `${auth?.userObj?.first_name} ${auth?.userObj?.last_name}`
      : app?.allOpenConversationDetails?.contact_id != null
      ? app?.allOpenConversationDetails?.contact?.first_name +
        " " +
        app?.allOpenConversationDetails?.contact?.last_name
      : app?.allOpenConversationDetails?.company_id != null
      ? app?.allOpenConversationDetails?.company_name
      : `${chatThread?.data?.guest.first_name} ${chatThread?.data?.guest.last_name}`;
  return (
    <ChatMessageContainer
      // ref={containerRef}
      expanded={expanded}
      showFaded={showFaded}
      sender={Msgsender}
      type={type}
    >
      <div className="email-item">
        <div className="email-header">
          <Avatar sender_avatar={sender_avatar} sender={Msgsender} />
          <div className="email-details">
            <div className="email-top-row">
              <div className="left-container">
                <span className="email-sender">{Msgsender}</span>
              </div>

              <div className="make-row">
                <CustomTooltip marginTop="-15px" text={timestamp}>
                  <span className="email-time">{time}</span>
                </CustomTooltip>
              </div>
            </div>

            <div
              className={`email-subject ${expanded ? "expanded" : ""}`}
              dangerouslySetInnerHTML={{ __html: message }}
            />
            <AttachmentList>
              {Array.isArray(attachments) &&
                attachments?.map((file, index) => (
                  <a
                    href={file?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    className="AttachmentItem"
                  >
                    <div className="file-name">
                      <img className="file-image" src={file?.url} alt=" " />
                      <TruncateText text={file?.filename}></TruncateText>
                    </div>
                    <span className="file-size">{file?.formatted_size}</span>
                  </a>
                ))}
            </AttachmentList>

            {/* {showToggleButton && (
              <div className="toggle-button">
                <div className="show-more" onClick={toggleExpand}>
                  {expanded ? (
                    <>
                      Show less{" "}
                      <span
                        style={{
                          paddingTop: "4px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TiArrowSortedUp />
                      </span>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                      }}
                    >
                      <span> Show more </span>
                      <span
                        style={{
                          padding: 0,
                          marginBottom: "-7px",
                        }}
                      >
                        <TiArrowSortedDown />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </ChatMessageContainer>
  );
}

export default ChatMessage;
