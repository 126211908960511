import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FaCopy } from "react-icons/fa";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Tooltip } from "antd";

const Wrapper = styled.div`
  position: relative;
`;

const TooltipItem = styled.div`
  margin-bottom: 8px;
  display: flex;

  .label {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    width: 55px;
    text-align: right;
    margin-right: 10px;
  }

  .text {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: var(--Gray-100, #556373);
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

const CopyIcon = styled(FaCopy)`
  margin-left: 8px;
  cursor: pointer;
  color: #4cbbcb;

  &:hover {
    color: #4cbbcb;
  }
`;

const CopiedAlert = styled.div`
  color: #4cbbcb;
  margin-top: 10px;
  font-family: Outfit;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

const MessageDetailsTooltip = ({
  from,
  to,
  date,
  subject,
  recipients,
  mailDetails,
  ccEmails,
}) => {
  const [copiedText, setCopiedText] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const copyToClipboard = (e, text) => {
    e.stopPropagation()
    e.preventDefault();
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          setCopiedText(text);
          setTimeout(() => setCopiedText(""), 2000);
        })
        .catch((err) => console.error("Failed to copy:", err));
    } else {
      console.error("Clipboard API not supported");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef?.current &&
        !tooltipRef?.current?.contains(event?.target)
      ) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showTooltip]);

  return (
    <Wrapper ref={tooltipRef}>
      <Tooltip
        title={
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e3eced",
              maxWidth: "500px",
            }}
          >
            <TooltipItem>
              <strong className="label">From: </strong>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  className="text"
                  style={{ maxWidth: "400px", fontWeight: "600" }}
                >
                  {mailDetails?.content?.from_email}
                </span>
                <CopyIcon
                  onMouseDown={(e) =>
                    copyToClipboard(e, mailDetails?.content?.from_email)
                  }
                />
              </div>
            </TooltipItem>

            <TooltipItem>
              <strong className="label">To: </strong>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {recipients?.map((recipient, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="text" style={{ maxWidth: "400px" }}>
                      {recipient}
                    </span>
                    <CopyIcon onMouseDown={(e) => copyToClipboard(e, recipient)} />
                  </div>
                ))}
              </div>
            </TooltipItem>
            {Array.isArray(ccEmails) && ccEmails.length > 0 && (
              <TooltipItem>
                <strong className="label">CC: </strong>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {ccEmails?.map((recipient, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="text" style={{ maxWidth: "400px" }}>
                        {recipient}
                      </span>
                      <CopyIcon onMouseDown={(e) => copyToClipboard(e, recipient)} />
                    </div>
                  ))}
                </div>
              </TooltipItem>
            )}

            <TooltipItem>
              <strong className="label">Date: </strong>
              <span className="text">{date}</span>
            </TooltipItem>

            <TooltipItem>
              <strong className="label">Subject: </strong>
              <span className="text" style={{ maxWidth: "400px" }}>
                {subject}
              </span>
            </TooltipItem>

            {copiedText && <CopiedAlert>Copied to clipboard!</CopiedAlert>}
          </div>
        }
        placement="bottom" // 👈 Moves the arrow to the top
        overlayStyle={{ maxWidth: "500px", textAlign: "left" }}
        open={showTooltip}
        arrowPointAtCenter
        showArrow={true} // 👈 Ensures Ant Design's built-in arrow is used
        overlayInnerStyle={{ padding: "0px" }}
        color="white"
        zIndex={1}
      >
        <div
          className="arrow-down"
          onClick={() => setShowTooltip((prev) => !prev)}
        >
          {showTooltip ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
        </div>
      </Tooltip>
    </Wrapper>
  );
};

export default MessageDetailsTooltip;
