import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ChatHeaderContainer from "./styles";
import ChatHeaderDropDown from "../chatHeaderDropDown/ChatHeaderDropDown";
import CustomAvatar from "./avatar/avatar";
import CustomTooltip from "../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import { InstanceWithAuth } from "../../../../App";
import { IoMdContract } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Echo from "laravel-echo";
import { handleUpdateChatOwner } from "../../../../redux/app/appSlice";
import {
  handleAddCurrentActiveChatThread,
  handleAddCurrentActiveChatTab,
  handleShowContractedChat,
} from "../../../../redux/app/appSlice";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";
import { Skeleton, Avatar, Tooltip } from "antd";
const ViewerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-avatar-lg {
    width: 25px !important;
    height: 25px !important;
  }
`;

const ViewersText = styled.span`
  color: var(--Dark-Gray-100, #2b3746);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.63px;
`;
const ChatHeader = ({
  selectedEmailId,
  setCurrentEmployeeImage,
  currentEmployeeImage,
  email,
  selectedFolder,
  isInitialLoadThread,
}) => {
  const { showMessage } = useGlobalAlertMessage();
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const { auth } = useSelector((state) => state);
  const [joinedChats, setJoinedChats] = useState([]);
  const [joinigChat, setJoinigChat] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const addEmailIdToLocalStorage = (emailId) => {
    const savedEmailIds =
      JSON.parse(localStorage.getItem("emailIdsArray")) || [];

    let type;
    if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
      type = "email";
    } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
      type = "chat";
    }

    const isAlreadySaved = savedEmailIds.some(
      (item) => item.id === emailId && item.type === type
    );

    if (!isAlreadySaved) {
      savedEmailIds.push({ id: emailId, type });

      localStorage.setItem("emailIdsArray", JSON.stringify(savedEmailIds));

      const event = new Event("localStorageChange");
      window.dispatchEvent(event);
    }
  };

  const addActiveChatToContractedChat = (chatDetials) => {
    dispatch(handleAddCurrentActiveChatThread(chatDetials));
    dispatch(handleAddCurrentActiveChatTab(selectedFolder));
    dispatch(handleShowContractedChat(true));
    const event = new Event("localStorageChange");
    window.dispatchEvent(event);
  };

  useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      key: "local",
      cluster: "your-app-cluster",
      wsHost: `${process.env.REACT_APP_SOCKET_BASE_URL}`,
      wsPort: `${process.env.REACT_APP_CHAT_WSPORT}`,
      wssPort: `${process.env.REACT_APP_CHAT_WSSPORT}`,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
      disableStats: true,
    });
    echo.channel("chat.joined").listen("ChatJoined", (event) => {
      console.log("New event received:", event);
      setJoinedChats((prevsJoinedChats) => [...prevsJoinedChats, event]);
    });
    echo.connector.pusher.connection.bind("connected", () => {
      console.log("Successfully connected to Pusher in chat listing");
    });
    return () => {
      echo.disconnect();
    };
  }, []);

  const handleJoinChat = async (chatId) => {
    setSelectedUser(auth?.userObj);
    setCurrentEmployeeImage(auth?.userObj);
    setJoinigChat(true);
    try {
      const response = await InstanceWithAuth.get(
        `live-chat/join-chat/${chatId}`,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        dispatch(
          handleUpdateChatOwner({
            owner: `${auth?.userObj?.first_name} ${auth?.userObj?.last_name}`,
          })
        );
        setJoinigChat(false);
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      setJoinigChat(false);

      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  return (
    <ChatHeaderContainer>
      <div className="wrapper">
        <span className="title">Owner</span>

        {isInitialLoadThread ? (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton.Avatar active size="small" shape="circle" />
            <Skeleton.Input active size="defual" />
          </div>
        ) : (
          <div className="avatar-name-container">
            <CustomAvatar
              currentEmployeeImage={
                email?.data?.user_id && currentEmployeeImage
              }
            />
            {/* 
          {(selectedFolder == 'All Open' &&
            app?.allOpenConversationDetails?.Model == 'LiveChat') ||
          selectedFolder == 'Chat' ? (
            <div className="title owner-name">
              {app?.allOpenConversationDetails?.owner}
            </div>
          ) : ( */}
            <ChatHeaderDropDown
              selectedEmailId={selectedEmailId}
              setCurrentEmployeeImage={setCurrentEmployeeImage}
              email={email}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            ></ChatHeaderDropDown>
            {/* )} */}
          </div>
        )}
      </div>
      {console.log(
        app?.allOpenConversationDetails?.viewers,
        "app?.allOpenConversationDetails?.viewers"
      )}

      {!isInitialLoadThread && (
        <div style={{ display: "flex", gap: "5px" }}>
          {app?.allOpenConversationDetails?.Model === "SupportEmails" &&
          app?.allOpenConversationDetails?.viewers?.length > 0 ? (
            <ViewerContainer>
              <Avatar.Group
                maxCount={3}
                size="large"
                style={{ cursor: "Pointer" }}
                max={3}
              >
                {app?.allOpenConversationDetails?.viewers?.map(
                  (user, index) => (
                    <CustomTooltip key={index} text={user?.full_name} marginTop="-20px">
                      <Avatar
                        src={user?.avatar}
                        size={25}
                        style={{
                          backgroundColor: !user?.avatar
                            ? "#ccc"
                            : "transparent",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        {!user?.avatar && user?.full_name
                          ? user?.full_name.charAt(0).toUpperCase()
                          : ""}
                      </Avatar>
                    </CustomTooltip>
                  )
                )}
              </Avatar.Group>
              <ViewersText>viewing</ViewersText>
            </ViewerContainer>
          ) : null}
          {app?.allOpenConversationDetails &&
          app?.allOpenConversationDetails?.user_id == null &&
          app?.allOpenConversationDetails?.Model == "LiveChat" &&
          !joinedChats?.some(
            (obj) => obj?.liveChat?.id == app?.allOpenConversationDetails?.id
          ) ? (
            <button
              onClick={() =>
                handleJoinChat(app?.allOpenConversationDetails?.id)
              }
              className="join-btn"
            >
              {joinigChat ? "Joining Chat" : "Join Chat"}
            </button>
          ) : null}
          <button onClick={() => addEmailIdToLocalStorage(selectedEmailId)}>
            <IoMdContract size={18} />
          </button>
        </div>
      )}
    </ChatHeaderContainer>
  );
};

export default ChatHeader;
