import React, { useState } from "react";
import { Container } from "./styles";
import "./styles";
import TicketBoardTypeDropDown from "../ticketBoardType/TicketBoardTypeDropDown";
import BoardCustomDropDown from "../boardCustomDropDown/BoardCustomDropDown";
import useGetData from "../../../hooks/useGetData";
import LocationDateDropDwon from "../../loactionFilters/locationDateDropDwon/LocationDateDropDwon";
import { DatePicker, Drawer, Form, Select, Tooltip } from "antd";
import { FaFilter } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { CloseOutlined } from "@ant-design/icons";
import { InstanceWithAuth } from "../../../App";
import { useSelector } from "react-redux";
import AdvancedTicketFilters from "../advancedTicketFilters/AdvancedTicketFilters";
import TicketType from "../ticketType/TicketType";
import { RxCross2 } from "react-icons/rx";
import { useSearchParams } from "react-router-dom";

const TicketCenterFilters = ({
  selectedPipeline,
  setSelectedPipeline,
  selectedOwner,
  setSelectedOwner,
  selectedPriority,
  setSelectedPriority,
  selectedCreatedDate,
  setSelectedCreatedDate,
  app,
  setSelectedOption,
  setEndDate,
  setStartDate,
  setSelectedLocation,
  selectedLocation,
  setAdvancedFilters,
  setLoadingFilteredTicktes,
  type,
  setType,
  openContactDrawer,
  setOpenContactDrawer,
  advancedFilters,
  refetchTikcedBoardData,
  setNextPageMap,
  setPreviousNextPageMap,
}) => {
  const { auth } = useSelector((state) => state);
  const { data: allUserList, refetchData: refecthUserList } =
    useGetData("users");
  const [startDateToDisabele, setStartDateToDisabele] = useState(null);
  const [appliedAdvancedFillters, setAppliedAdvancedFillters] = useState(null);
  const [groups, setGroups] = useState([{ operator: "AND", filters: [] }]);
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };
  const handleStartDateChange = (date, dateString) => {
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${year}-${month}-${day}`;
    setStartDateToDisabele(date);
    setStartDate(formattedDate);
  };
  const { data: locationData } = useGetData("locations/dropdown/get");
  const handleEndDateChange = (date, dateString) => {
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${year}-${month}-${day}`;
    setEndDate(formattedDate);
    setSelectedOption();
  };

  const showDrawerContactDrawer = () => {
    setOpenContactDrawer(true);
  };
  const onCloseContactDrawer = () => {
    setOpenContactDrawer(false);
  };

  const disabledEndDate = (current) => {
    return (
      current &&
      startDateToDisabele &&
      current.valueOf() < startDateToDisabele.valueOf()
    );
  };

  const handleClearFilters = () => {
    setSelectedPipeline(null);
    setSelectedOwner({
      name: "all",
      id: "all",
    });
    setSelectedPriority(null);
    setSelectedCreatedDate("");
    setSelectedLocation(null);
    setAppliedAdvancedFillters(null);
    setStartDate(null);
    setEndDate(null);
    setGroups([{ operator: "AND", filters: [] }]);
    localStorage.removeItem("ticketsTasksFilters");
    setShowMoreFilters(false);
  };

  const areFiltersApplied = () => {
    return (
      (selectedOwner &&
        selectedOwner.name !== "all" &&
        selectedOwner.id !== "all") ||
      (selectedPriority &&
        selectedPriority.name !== "all" &&
        selectedPriority.id !== "all") ||
      (selectedCreatedDate &&
        selectedCreatedDate.name !== "all" &&
        selectedCreatedDate.id !== "all") ||
      (selectedLocation &&
        selectedLocation.name !== "all" &&
        selectedLocation.id !== "all") ||
      (appliedAdvancedFillters &&
        Object.keys(appliedAdvancedFillters || {}).length > 0)
    );
  };

  console.log("selectedCreatedDate", selectedCreatedDate);
  return (
    <Container isSidebarCollapsed={app?.sidebarCollapsed}>
      <div className="make-row">
        <TicketType
          type={type}
          setType={setType}
          app={app}
          setNextPageMap={setNextPageMap}
          setPreviousNextPageMap={setPreviousNextPageMap}
        ></TicketType>
        <TicketBoardTypeDropDown
          selectedPipeline={selectedPipeline}
          setSelectedPipeline={setSelectedPipeline}
          app={app}
          type={type}
          setNextPageMap={setNextPageMap}
          setPreviousNextPageMap={setPreviousNextPageMap}
        ></TicketBoardTypeDropDown>
      </div>

      <div className="filter">
        {/* If not filter applied the show this */}
        {(selectedCreatedDate === "" ||
          selectedCreatedDate?.name === "all") && (
          <>
            <div
              // style={{ paddingLeft: "10px" }}
              className="filter-btn"
              onClick={showDrawerContactDrawer}
            >
              <span
                style={{
                  color:
                    appliedAdvancedFillters &&
                    Object.keys(appliedAdvancedFillters).length > 0
                      ? "#4cbbcb"
                      : "rgba(85, 99, 115, 0.6)", // Directly using the rgba value
                  textAlign: "right",
                  fontFamily: "Outfit",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                  cursor: "pointer",
                }}
              >
                Advanced Filters
              </span>
              <IoFilter
                style={{
                  marginLeft: "8px",
                  color:
                    appliedAdvancedFillters &&
                    Object.keys(appliedAdvancedFillters || {}).length > 0
                      ? "#4cbbcb"
                      : "var(--Gray-60, rgba(85, 99, 115, 0.6))",
                }}
                size={12}
              />
            </div>
            <BoardCustomDropDown
              selectedStatusOption={selectedPriority}
              setSelectedStatusOption={setSelectedPriority}
              filterData={[
                // {
                //   name: "All",
                // },
                {
                  name: "Low",
                },
                {
                  name: "Medium",
                },
                {
                  name: "High",
                },
              ]}
              dropDownTitle="Priority"
            ></BoardCustomDropDown>
            <BoardCustomDropDown
              selectedStatusOption={selectedLocation}
              setSelectedStatusOption={setSelectedLocation}
              dropDownTitle="Location"
              filterData={locationData?.data}
            ></BoardCustomDropDown>
            <BoardCustomDropDown
              selectedStatusOption={selectedOwner}
              setSelectedStatusOption={setSelectedOwner}
              dropDownTitle="Ticket Owner"
              filterData={allUserList?.data}
            ></BoardCustomDropDown>
          </>
        )}

        {/* Filters when side Menu closed */}
        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name !== "between" &&
          selectedCreatedDate?.name !== "all" &&
          app?.sidebarCollapsed && (
            <>
              <BoardCustomDropDown
                selectedStatusOption={selectedPriority}
                setSelectedStatusOption={setSelectedPriority}
                filterData={[
                  // {
                  //   name: "All",
                  // },
                  {
                    name: "Low",
                  },
                  {
                    name: "Medium",
                  },
                  {
                    name: "High",
                  },
                ]}
                dropDownTitle="Priority"
              ></BoardCustomDropDown>
              <BoardCustomDropDown
                selectedStatusOption={selectedLocation}
                setSelectedStatusOption={setSelectedLocation}
                dropDownTitle="Location"
                filterData={locationData?.data}
              ></BoardCustomDropDown>
              <BoardCustomDropDown
                selectedStatusOption={selectedOwner}
                setSelectedStatusOption={setSelectedOwner}
                dropDownTitle="Ticket Owner"
                filterData={allUserList?.data}
              ></BoardCustomDropDown>
            </>
          )}

        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name === "between" &&
          app?.sidebarCollapsed && (
            <>
              <BoardCustomDropDown
                selectedStatusOption={selectedPriority}
                setSelectedStatusOption={setSelectedPriority}
                filterData={[
                  // {
                  //   name: "All",
                  // },
                  {
                    name: "Low",
                  },
                  {
                    name: "Medium",
                  },
                  {
                    name: "High",
                  },
                ]}
                dropDownTitle="Priority"
              ></BoardCustomDropDown>
            </>
          )}

        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name !== "between" &&
          selectedCreatedDate?.name !== "all" &&
          !app?.sidebarCollapsed && (
            <>
              <BoardCustomDropDown
                selectedStatusOption={selectedPriority}
                setSelectedStatusOption={setSelectedPriority}
                filterData={[
                  // {
                  //   name: "All",
                  // },
                  {
                    name: "Low",
                  },
                  {
                    name: "Medium",
                  },
                  {
                    name: "High",
                  },
                ]}
                dropDownTitle="Priority"
              ></BoardCustomDropDown>
            </>
          )}
        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name === "between" &&
          !app?.sidebarCollapsed && <></>}

        {/* Date filters to show always */}
        <BoardCustomDropDown
          selectedStatusOption={selectedCreatedDate}
          setSelectedStatusOption={setSelectedCreatedDate}
          filterData={[
            {
              name: "is",
            },
            {
              name: "between",
            },
            {
              name: "after",
            },
            {
              name: "before",
            },
          ]}
          dropDownTitle="Create Date"
        ></BoardCustomDropDown>
        {selectedCreatedDate?.name == "between" ? (
          <DatePicker
            needConfirm
            placeholder="Start Date"
            className="datePicker"
            onChange={handleStartDateChange}
            style={{ height: "27px" }}
          />
        ) : null}
        {selectedCreatedDate != "" && selectedCreatedDate?.name != "all" ? (
          <DatePicker
            needConfirm
            placeholder={selectedCreatedDate == "between" ? "End Date" : "Date"}
            className="datePicker dropdown-header"
            onChange={handleEndDateChange}
            disabledDate={disabledEndDate}
            style={{ height: "27px" }}
          />
        ) : null}

        {/* Filters when side Menu Open */}
        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name !== "between" &&
          selectedCreatedDate?.name !== "all" &&
          !app?.sidebarCollapsed && (
            <Tooltip
              color="white"
              placement="bottomRight"
              zIndex={1}
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <BoardCustomDropDown
                    selectedStatusOption={selectedLocation}
                    setSelectedStatusOption={setSelectedLocation}
                    dropDownTitle="Location"
                    filterData={locationData?.data}
                  />
                  <BoardCustomDropDown
                    selectedStatusOption={selectedOwner}
                    setSelectedStatusOption={setSelectedOwner}
                    dropDownTitle="Ticket Owner"
                    filterData={allUserList?.data}
                  />

                  <div
                    style={{ paddingLeft: "10px" }}
                    className="filter-btn"
                    onClick={showDrawerContactDrawer}
                  >
                    <span
                      style={{
                        color:
                          appliedAdvancedFillters &&
                          Object.keys(appliedAdvancedFillters).length > 0
                            ? "#4cbbcb"
                            : "rgba(85, 99, 115, 0.6)", // Directly using the rgba value
                        textAlign: "right",
                        fontFamily: "Outfit",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                        cursor: "pointer",
                      }}
                    >
                      Advanced Filters
                    </span>
                    <IoFilter
                      style={{
                        marginLeft: "8px",
                        color:
                          appliedAdvancedFillters &&
                          Object.keys(appliedAdvancedFillters || {}).length > 0
                            ? "#4cbbcb"
                            : "var(--Gray-60, rgba(85, 99, 115, 0.6))",
                      }}
                      size={12}
                    />
                  </div>
                </div>
              }
              open={showMoreFilters}
              overlayInnerStyle={{
                backgroundColor: "#fff",
                border: "1px solid #e3eced",
                padding: "10px 20px",
                maxWidth: "90vw",
                width: "450px",
                position: "absolute",
                right: "-50px",
              }}
            >
              <button
                className="more-filters-btn"
                onClick={() => setShowMoreFilters(!showMoreFilters)}
              >
                More Filters
              </button>
            </Tooltip>
          )}

        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name === "between" &&
          !app?.sidebarCollapsed && (
            <>
              <Tooltip
                color="white"
                placement="bottomRight"
                zIndex={1}
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                      justifyContent: "start",
                      alignItems: "center",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <BoardCustomDropDown
                      selectedStatusOption={selectedLocation}
                      setSelectedStatusOption={setSelectedLocation}
                      dropDownTitle="Location"
                      filterData={locationData?.data}
                    />
                    <BoardCustomDropDown
                      selectedStatusOption={selectedOwner}
                      setSelectedStatusOption={setSelectedOwner}
                      dropDownTitle="Ticket Owner"
                      filterData={allUserList?.data}
                    />
                    <div style={{ paddingLeft: "2px" }}>
                      <BoardCustomDropDown
                        selectedStatusOption={selectedPriority}
                        setSelectedStatusOption={setSelectedPriority}
                        filterData={[
                          { name: "All" },
                          { name: "Low" },
                          { name: "Medium" },
                          { name: "High" },
                        ]}
                        dropDownTitle="Priority"
                      />
                    </div>
                    <div
                      style={{ paddingLeft: "10px" }}
                      className="filter-btn"
                      onClick={showDrawerContactDrawer}
                    >
                      <span
                        style={{
                          color:
                            appliedAdvancedFillters &&
                            Object.keys(appliedAdvancedFillters).length > 0
                              ? "#4cbbcb"
                              : "rgba(85, 99, 115, 0.6)", // Directly using the rgba value
                          textAlign: "right",
                          fontFamily: "Outfit",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16px",
                          cursor: "pointer",
                        }}
                      >
                        Advanced Filters
                      </span>
                      <IoFilter
                        style={{
                          marginLeft: "8px",
                          color:
                            appliedAdvancedFillters &&
                            Object.keys(appliedAdvancedFillters || {}).length >
                              0
                              ? "#4cbbcb"
                              : "var(--Gray-60, rgba(85, 99, 115, 0.6))",
                        }}
                        size={12}
                      />
                    </div>
                  </div>
                }
                open={showMoreFilters}
                overlayInnerStyle={{
                  backgroundColor: "#fff",
                  border: "1px solid #e3eced",
                  padding: "10px 20px",
                  maxWidth: "90vw",
                  width: "600px",
                  position: "absolute",
                  right: "-50px",
                }}
              >
                <button
                  className="more-filters-btn"
                  onClick={() => setShowMoreFilters(!showMoreFilters)}
                >
                  More Filters
                </button>
              </Tooltip>
            </>
          )}

        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name !== "between" &&
          selectedCreatedDate?.name !== "all" &&
          app?.sidebarCollapsed && (
            <Tooltip
              color="white"
              placement="bottomRight"
              zIndex={1}
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{ paddingLeft: "10px" }}
                    className="filter-btn"
                    onClick={showDrawerContactDrawer}
                  >
                    <span
                      style={{
                        color:
                          appliedAdvancedFillters &&
                          Object.keys(appliedAdvancedFillters).length > 0
                            ? "#4cbbcb"
                            : "rgba(85, 99, 115, 0.6)", // Directly using the rgba value
                        textAlign: "right",
                        fontFamily: "Outfit",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                        cursor: "pointer",
                      }}
                    >
                      Advanced Filters
                    </span>
                    <IoFilter
                      style={{
                        marginLeft: "8px",
                        color:
                          appliedAdvancedFillters &&
                          Object.keys(appliedAdvancedFillters || {}).length > 0
                            ? "#4cbbcb"
                            : "var(--Gray-60, rgba(85, 99, 115, 0.6))",
                      }}
                      size={12}
                    />
                  </div>
                </div>
              }
              open={showMoreFilters}
              overlayInnerStyle={{
                backgroundColor: "#fff",
                border: "1px solid #e3eced",
                padding: "10px 20px",
                maxWidth: "90vw",
                width: "200px",
                position: "absolute",
                right: "-50px",
              }}
            >
              <button
                className="more-filters-btn"
                onClick={() => setShowMoreFilters(!showMoreFilters)}
              >
                More Filters
              </button>
            </Tooltip>
          )}

        {selectedCreatedDate?.name &&
          selectedCreatedDate?.name === "between" &&
          app?.sidebarCollapsed && (
            <Tooltip
              color="white"
              placement="bottomRight"
              zIndex={1}
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <BoardCustomDropDown
                    selectedStatusOption={selectedOwner}
                    setSelectedStatusOption={setSelectedOwner}
                    dropDownTitle="Ticket Owner"
                    filterData={allUserList?.data}
                  />
                  <BoardCustomDropDown
                    selectedStatusOption={selectedLocation}
                    setSelectedStatusOption={setSelectedLocation}
                    dropDownTitle="Location"
                    filterData={locationData?.data}
                  />
                  <div
                    style={{ paddingLeft: "10px" }}
                    className="filter-btn"
                    onClick={showDrawerContactDrawer}
                  >
                    <span
                      style={{
                        color:
                          appliedAdvancedFillters &&
                          Object.keys(appliedAdvancedFillters).length > 0
                            ? "#4cbbcb"
                            : "rgba(85, 99, 115, 0.6)", // Directly using the rgba value
                        textAlign: "right",
                        fontFamily: "Outfit",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                        cursor: "pointer",
                      }}
                    >
                      Advanced Filters
                    </span>

                    <IoFilter
                      style={{
                        marginLeft: "8px",
                        color:
                          appliedAdvancedFillters &&
                          Object.keys(appliedAdvancedFillters || {}).length > 0
                            ? "#4cbbcb"
                            : "var(--Gray-60, rgba(85, 99, 115, 0.6))",
                      }}
                      size={12}
                    />
                  </div>
                </div>
              }
              open={showMoreFilters}
              overlayInnerStyle={{
                backgroundColor: "#fff",
                border: "1px solid #e3eced",
                padding: "10px 20px",
                maxWidth: "90vw",
                width: "450px",
                position: "absolute",
                right: "-50px",
              }}
            >
              <button
                className="more-filters-btn"
                onClick={() => setShowMoreFilters(!showMoreFilters)}
              >
                More Filters
              </button>
            </Tooltip>
          )}

        {areFiltersApplied() && (
          <div className="clear-btn" onClick={handleClearFilters}>
            <RxCross2 />
            <span>Clear Filters</span>
          </div>
        )}
      </div>

      <Drawer
        mask={false}
        className="drawer"
        width={640}
        padding={0}
        paddingLG={0}
        paddingXS={0}
        onClose={onCloseContactDrawer}
        open={openContactDrawer}
        headerStyle={{
          backgroundColor: "#4cbbcb",
          color: "#fff",
        }}
        style={{
          padding: "0px",
          margin: "0px",
        }}
        closeIcon={
          <CloseOutlined style={{ color: "white", fontSize: "bold" }} />
        }
        title="All Filters"
      >
        <AdvancedTicketFilters
          setAdvancedFilters={setAdvancedFilters}
          setLoadingFilteredTicktes={setLoadingFilteredTicktes}
          openContactDrawer={openContactDrawer}
          setAppliedAdvancedFillters={setAppliedAdvancedFillters}
          groups={groups}
          setGroups={setGroups}
          setOpenContactDrawer={setOpenContactDrawer}
          refetchTikcedBoardData={refetchTikcedBoardData}
          selectedPipeline={selectedPipeline}
        />
      </Drawer>
    </Container>
  );
};

export default TicketCenterFilters;
