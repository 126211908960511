import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles"; // Import CSS for styling
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
const BoardCustomDropDown = ({
  selectedStatusOption,
  setSelectedStatusOption,
  dropDownTitle = "Selcet Option",
  filterData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState(""); // For search
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSearchText(""); // Reset search on open
  };

  const handleOptionSelect = (option) => {
    setSelectedStatusOption(option);
    setIsOpen(false);
  };

  const filteredData =
    dropDownTitle === "Ticket Owner"
      ? filterData?.filter((list) =>
          `${list?.first_name} ${list?.last_name}`
            .toLowerCase()
            .includes(searchText.toLowerCase())
        )
      : filterData;

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          {isOpen && dropDownTitle === "Ticket Owner" ? (
            <input
              type="text"
              placeholder="Search employee..."
              className="search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              autoFocus // Automatically focus when opened
            />
          ) : (
            <div
              className="user-name"
              style={{
                color:
                  selectedStatusOption && selectedStatusOption?.name !== "all"
                    ? "#4cbbcb"
                    : undefined,
              }}
            >
              {dropDownTitle === "Ticket Owner"
                ? selectedStatusOption?.first_name
                  ? `${selectedStatusOption.first_name} ${selectedStatusOption.last_name}`
                  : selectedStatusOption?.name === "all"
                  ? dropDownTitle
                  : selectedStatusOption?.name === "unassigned"
                  ? "Unassigned"
                  : dropDownTitle
                : dropDownTitle === "Location"
                ? selectedStatusOption?.name &&
                  selectedStatusOption.name !== "all"
                  ? `${selectedStatusOption.label}`
                  : dropDownTitle
                : selectedStatusOption?.name
                ? `${selectedStatusOption.name}`
                : dropDownTitle}
            </div>
          )}

          {!(isOpen && dropDownTitle === "Ticket Owner") && (
            <MdOutlineKeyboardArrowDown
              fill={isOpen ? "rgb(76, 187, 203)" : "rgba(85, 99, 115, 0.6)"}
            />
          )}
        </div>
        {isOpen && (
          <>
            <ul className="dropdown-list">
              {!searchText && (
                <li
                  onClick={() => handleOptionSelect({ name: "all", id: "all" })}
                >
                  All
                </li>
              )}

              {dropDownTitle == "Ticket Owner" && !searchText && (
                <li
                  onClick={() =>
                    handleOptionSelect({
                      name: "unassigned",
                      id: "unassigned",
                    })
                  }
                >
                  Unassigned
                </li>
              )}

              {filteredData?.map((list) => (
                <li key={list.id} onClick={() => handleOptionSelect(list)}>
                  {dropDownTitle === "Ticket Owner"
                    ? `${list?.first_name} ${list?.last_name}`
                    : dropDownTitle === "Location"
                    ? list?.label
                    : list?.name}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </MainContainer>
  );
};

export default BoardCustomDropDown;
