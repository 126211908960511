import React, { useEffect, useRef, useState } from "react";
import { ChatMessageContainer, AttachmentList } from "./styles";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Avatar from "../avatar/avatar";
import CustomTooltip from "../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import MessageDetailsTooltip from "./messageDetailsTooltip/MessageDetailsTooltip";
import GetLogo from "../../../getlogo/getlogo";
import { GoReply } from "react-icons/go";
import { IoReturnUpForward } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import {
  AiOutlineAudio,
  AiOutlineFile,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { Tooltip } from "antd";
function ChatMessage({
  message,
  sender,
  timestamp,
  imageName,
  emailData,
  currentEmployeeImage,
  time,
  attachments,
  sender_avatar,
  handleForwardMail,
  handleReplyEmail,
  mailDetails,
  recipients,
  ccEmails,
  setIsInitialLoad,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showFaded, setShowFaded] = useState(false);
  const [tooltipText, setTooltipText] = useState("Click to copy");
  const { app } = useSelector((state) => state);
  const { messageId } = useParams();
  const [highlight, setHighlight] = useState(false);
  const containerRef = useRef(null);
  const attachementsContainerRef = useRef(null);
  const [showToggleButton, setShowToggleButton] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  // Split message at the email history marker
  const splitIndex = message?.indexOf("[--!!email-history!!--]");
  const hasHistory = splitIndex !== -1;

  // Extract parts
  const beforeHistory = hasHistory
    ? message?.substring(0, splitIndex)
    : message;
  const afterHistory = hasHistory
    ? message?.substring(splitIndex + "[--!!email-history!!--]".length)
    : "";

  useEffect(() => {
    if (containerRef.current) {
      let containerHeight = containerRef.current.clientHeight;
      let attachmentsHeight =
        attachementsContainerRef.current?.clientHeight || 0;

      let adjustedHeight = containerHeight - attachmentsHeight;

      setShowToggleButton(adjustedHeight > 170);
    }
  }, [message, expanded, attachementsContainerRef.current]);

  useEffect(() => {
    if (message?.length > beforeHistory && !expanded) {
      setShowFaded(true);
    } else {
      setShowFaded(false);
    }
  }, [expanded, message?.length]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const TruncateText = ({ text }) => {
    const maxLength = 15;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const handleCopy = (emailData) => {
    const url = `${window.location.origin}/support/${app?.currentActiveInboxTabKey}/${emailData?.id}/${mailDetails?.content?.id}`;
    navigator.clipboard.writeText(url);
    setTooltipText("Copied");
    setTimeout(() => {
      setTooltipText("Click to Copy");
    }, 2000);
  };

  useEffect(() => {
    if (mailDetails?.content?.id?.toString() === messageId) {
      setHighlight(true);
      setIsInitialLoad(false);
      // const timer = setTimeout(() => {
      //   setHighlight(false);
      // }, 1000);

      // return () => clearTimeout(timer);
    }
  }, [messageId, mailDetails]);

  return (
    <ChatMessageContainer
      ref={containerRef}
      expanded={expanded}
      showHistory={showHistory}
      showFaded={showFaded}
      showToggleButton={showToggleButton}
      sender={sender}
      isHighlightedMessage={
        mailDetails?.content?.id?.toString() === messageId && highlight
      }
    >
      <div className="email-item">
        <div className="email-header">
          <Avatar sender_avatar={sender_avatar} sender={sender} />
          <div className="email-details">
            <div className="email-top-row">
              <div className="left-container">
                <span className="email-sender">{sender}</span>

                <MessageDetailsTooltip
                  to={emailData.receiver_email}
                  subject={emailData.subject}
                  from={emailData.from_email}
                  date={timestamp}
                  closeTooltip={() => setShowTooltip(false)}
                  marginTop={40}
                  recipients={recipients}
                  mailDetails={mailDetails}
                  ccEmails={ccEmails}
                />
              </div>

              <div className="make-row">
                <CustomTooltip marginTop="-15px" text={timestamp}>
                  <span className="email-time">{time}</span>
                </CustomTooltip>
                <CustomTooltip marginTop="-15px" text={"Reply"}>
                  <span className="back-icon">
                    <GoReply onClick={() => handleReplyEmail(emailData)} />
                  </span>
                </CustomTooltip>
                <CustomTooltip marginTop="-15px" text={"Forward"}>
                  <span className="back-icon">
                    <IoReturnUpForward
                      onClick={() => handleForwardMail(mailDetails)}
                    />
                  </span>
                </CustomTooltip>

                <CustomTooltip marginTop="-15px" text={tooltipText}>
                  <div className="icon" onClick={() => handleCopy(emailData)}>
                    {GetLogo("copy")}
                  </div>
                </CustomTooltip>
              </div>
            </div>

            <div className={`email-subject ${expanded ? "expanded" : ""}`}>
              {/* Render first part of the message */}
              <div dangerouslySetInnerHTML={{ __html: beforeHistory }} />

              {/* Toggle button (three dots) to show history */}
              {hasHistory && (
                <span
                  className="toggle-history-button"
                  onClick={() => setShowHistory(!showHistory)}
                >
                  <HiDotsHorizontal />
                </span>
              )}

              {/* Show email history when toggled */}
              {showHistory && (
                <div dangerouslySetInnerHTML={{ __html: afterHistory }} />
              )}
            </div>

            <AttachmentList ref={attachementsContainerRef}>
              {attachments.map((file, index) => {
                // Get the file extension

                console.log("file?.filename", file?.filename);
                const fileExtension = file?.filename
                  ?.split(".")
                  .pop()
                  .toLowerCase();

                // Define supported image extensions
                const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
                const isImage = imageExtensions.includes(fileExtension);
                console.log("fileExtension", fileExtension);
                // Define icons for specific file types
                const getFileIcon = () => {
                  if (fileExtension === "pdf") {
                    return <AiOutlineFilePdf size={24} color="#FF0000" />; // Red for PDF
                  }
                  if (fileExtension === "wav") {
                    return <AiOutlineAudio size={24} color="#007BFF" />; // Blue for Audio
                  }
                  return <AiOutlineFile size={24} color="#6c757d" />; // Gray for other files
                };

                return (
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    className="AttachmentItem"
                  >
                    <div className="file-name">
                      {isImage ? (
                        <img
                          className="file-image"
                          src={file?.url}
                          alt={file.filename}
                        />
                      ) : (
                        <span className="file-icon">{getFileIcon()}</span>
                      )}
                      <TruncateText text={file.filename}></TruncateText>
                    </div>
                    <span className="file-size">{file.formatted_size}</span>
                  </a>
                );
              })}
            </AttachmentList>

            {showToggleButton && (
              <div className="toggle-button">
                <div className="show-more" onClick={toggleExpand}>
                  {expanded ? (
                    <>
                      Show less{" "}
                      <span
                        style={{
                          paddingTop: "4px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TiArrowSortedUp />
                      </span>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                      }}
                    >
                      <span> Show more </span>
                      <span
                        style={{
                          padding: 0,
                          marginBottom: "-7px",
                        }}
                      >
                        <TiArrowSortedDown />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ChatMessageContainer>
  );
}

export default ChatMessage;
