import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  
  .make-row{
   display: flex;
    align-items: center;
    gap: 10px;
  }
  .datePicker {
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    gap: 5.333px;
    border-radius: 4px 4px 0px 4px;
    border: 1px solid #e3eced;
    background: transparent;
    height: 27px;
    padding-bottom: 7px;

    color: var(--Gray-60, rgba(85, 99, 115, 0.6));
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
    ${"" /* .ant-picker-clear {
        display: none;
      } */
  }
    .ant-picker-input > input::placeholder {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.42px;
    }
  }
  .search {
    display: flex;
    width: 208px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    background: var(--Soft-Blue-100, #eff5f6);
    height: 32px;
    .input {
      background: var(--Soft-Blue-100, #eff5f6);
      overflow: hidden;
      border: none;
      outline: none;
    }
    .input::placeholder {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.42px;
    }
    .icon {
      width: 16px;
      height: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // min-width: ${(props) => (!props.isSidebarCollapsed ? '500px' : '700px')};
    gap: 20px;
    // max-width:  ${(props) => (!props.isSidebarCollapsed ? '700px' : '900px')};
    height: 35px;

    .more-filters-btn {
  display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    text-align: center;
    border: none;
    cursor: pointer;
    color: var(--White-100, #fff);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}



  .clear-btn {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    text-align: center;
    border: none;
    cursor: pointer;
    color: var(--White-100, #fff);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

    .filter-btn {
      cursor: pointer;
      span {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        text-align: right;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
    .searchable-select {
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
      width: 200px;
      .ant-select-selection-search {
        /* inset-inline-start: 12px !important; */
        top: -1px !important;
      }
    }

    .menu-items,
    .btn-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .ant-space-item {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        text-align: right;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */

        span {
          svg {
            /* width: 16px;
          height: 16px; */
          }
        }
      }
    }
  }
`;

export { Container };