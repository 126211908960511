import styled from 'styled-components'

const Container = styled.div`
  .view-all {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
  }
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
          props.open
            ? 'var(--Blue-100, #4cbbcb)'
            : 'var(--Gray-5, rgba(85, 99, 115, 0.05))'};

        .ant-collapse-header-text {
          color: ${(props) =>
            props.open
              ? 'var(--White-100, #fff)'
              : 'var(--Dark-Gray-100, #2B3746)'};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
                props.open
                  ? 'var(--White-100, #fff)'
                  : 'var(--Dark-Gray-100, #2B3746)'};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-top: 7px;
  }

  .form-collapased {
    border: none;
    .panel {
      border: none;
      .ant-collapse-header {
        width: 339px;
        display: flex;
        height: 48px;
        padding: 12px 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
        opacity: 0.8;
        background: var(--Gray-2, rgba(85, 99, 115, 0.02));
        .ant-collapse-header-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: #2b3746;
            }
          }
        }
      }
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  width: 339px;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const DrowpdowmsContainer = styled.div`
  display: flex;
  padding-top: 16px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .click-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    width: 157.5px;

    .ant-space-item {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.49px;

      span {
        svg {
          width: 8px;
          height: 8px;
          flex-shrink: 0;
          stroke-width: 1.333px;
          stroke: var(--Dark-Gray-100, #2b3746);
        }
      }
    }
  }
`

const InvoiceCard = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #fff;
  margin-bottom: 8px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
      }
      .company-name {
        color: var(--Gray-100, #556373);

        /* P1 */
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 100% */
        margin: 0px;
      }
    }
  }

  .icons-and-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .invoice-amount {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .company-id {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      text-decoration-line: underline;
    }
    .active {
      margin-left: 5px;
      display: flex;
      padding: 0px 6px;
      align-items: center;
      gap: 10px;
      /* cursor: pointer; */
      border-radius: 4px;
      border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
      background: rgba(76, 203, 96, 0.2);
      .icon {
        svg {
          width: 8px;
          height: 8px;
        }
      }

      .text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
    .btns {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .amount {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      .active {
        display: flex;
        padding: 6px 12px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
        background: rgba(76, 203, 96, 0.2);
        .icon {
          svg {
            width: 8px;
            height: 8px;
          }
        }

        .text {
          text-transform: capitalize;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
      .open {
        background-color: #ffc107;
        border: 1px solid #ffc107;
        .text {
          color: #2b3746;
        }
      }
      .pastdue {
        background-color: #dc3545;
        border: 1px solid #dc3545;
        .text {
          color: #fff;
        }
      }
      .voided {
        border: 1px solid #6c757d;
        background-color: #6c757d;
        .text {
          color: #fff;
        }
      }
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 8px;

      .icon {
        display: flex;
        width: 16px;
        height: 16px;
        padding: 1px 0px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
          flex-shrink: 0;

          path {
            fill: var(--Dark-Gray-100, #2b3746);
            fill-opacity: unset;
          }
        }
      }
    }
  }
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 0px 0px 1px 0px;
  opacity: 0px;
  background-color: white !important;
  width: 100%;
  .save-btn {
    display: flex;
    height: 26px;
    padding: 9px 9px;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    gap: 8px;
    border-radius: 3px;
    background: var(--Blue-100, #4cbbcb);
    cursor: pointer;

    .btn-text {
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
  }

  .save-btn-disabled {
    cursor: not-allowed;
    display: flex;
    height: 26px;
    padding: 9px 9px;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    gap: 8px;
    border-radius: 3px;
    border: 1px solid #d0dee1;
    background: #fff;
    .btn-text {
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
  .cancel-btn {
    cursor: pointer;
    display: flex;
    height: 26px;
    padding: 9px 9px;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    gap: 8px;
    border-radius: 3px;
    border: 1px solid #d0dee1;
    background: #fff;
    .btn-text {
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
  span {
    color: #212c3b;
    font-family: 'Outfit';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.18px;
  }
  div {
    color: #212c3b;
    text-align: center;
    font-family: 'Outfit';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
const CompanyCards = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #fff;

  .company-labels {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;

    .company-name {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
      margin: 0px;
    }

    .office {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      /* text-decoration-line: underline; */
    }
  }
  .storage-FormatDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0px;
    .date {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin: 0px;
    }

    .td-storage {
      max-width: 134px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      flex-direction: column;

      .storage {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }

      .ant-progress {
        width: 100%;
        height: 12px;
        /* @media (max-width: 1450px) {
          width: 100px;
        } */
      }
      .ant-progress-bg {
        /* border: 1px solid #70c9d5;
        clip-path: inset(0px auto 0px 0px) !important; */
        /* border-radius: 4px !important; */
        background: linear-gradient(
            0deg,
            rgba(112, 201, 213, 0.52) 0%,
            rgba(112, 201, 213, 0.52) 100%
          ),
          #fff;
        /* width: 94px; */
        height: 12px !important;
      }
    }
    .full {
      max-width: 134px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      flex-direction: column;

      .storage {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }

      .ant-progress {
        width: 100%;
        height: 12px;
        /* @media (max-width: 1450px) {
          width: 100px;
        } */
      }
      .ant-progress-bg {
        background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.52) 0%,
            rgba(255, 0, 0, 0.52) 100%
          ),
          #fff;
        /* width: 94px; */
        height: 12px !important;
      }
    }
  }
  .time-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    .btns {
      display: flex;
      align-items: center;
      gap: 8px;

      .view-btn {
        display: flex;
        cursor: pointer;
        padding: 6px 12px;
        align-items: center;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;

        .btn-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
    }
  }
`
export {
  Container,
  ContentContainer,
  DrowpdowmsContainer,
  InvoiceCard,
  StyledHeader,
  CompanyCards,
}
