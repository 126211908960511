import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1293px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  border-radius: 16px;
  border: 1px solid rgba(76, 187, 203, 0.2);
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .flogged_mail_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .flogged_mail {
        display: flex;
        width: 530.5px;
        align-items: center;
        gap: 24px;
        span {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.7px;
        }
      }

      .filters {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 40px;

        .filter_continer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;

          span {
            color: var(--Gray-60, rgba(85, 99, 115, 0.6));
            text-align: right;
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
          svg {
            width: 16px;
            height: 16px;
          }
        }

        .search_mail {
          display: flex;
          width: 208px;
          padding: 8px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          border-radius: 20px;
          background: var(--Soft-Blue-100, #eff5f6);
          .search {
            display: flex;
            align-items: center;
            gap: 12px;
            svg {
              width: 16px;
              height: 16px;
            }
            input {
              width: 100%;
              border: none;
              outline: none;
              background: var(--Soft-Blue-100, #eff5f6);
              color: var(--Gray-60, rgba(85, 99, 115, 0.6));
              font-family: Outfit, sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.42px;
            }
          }
        }
      }
    }

    .table_header {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: rgba(243, 246, 247, 0.5);

      .table_sender {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-right: 1px solid #d0dee1;
        span {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));

          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
      }

      .Date {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-right: 1px solid #d0dee1;
        span {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));

          /* P2 */
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
      }
      .time {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-right: 1px solid #d0dee1;
        span {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));

          /* P2 */
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
      }
      .status {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-right: 1px solid #d0dee1;
        span {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));

          /* P2 */
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
      }
      .active {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-right: 1px solid #d0dee1;
        span {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));

          /* P2 */
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
      }
    }
  }

  .table_body {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: #fff;

    .table_body_calls {
      display: flex;
      ${"" /* max-width: 1293px;  */}
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      .table_rows {
        display: flex;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));

        .table_sender {
          display: flex;
          padding: 24px 12px;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          span {
            color: var(--Gray-100, #556373);
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }
        }

        .table_date {
          display: flex;
          padding: 24px 12px;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          span {
            color: var(--Gray-100, #556373);
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }
        }
        .table_time {
          display: flex;
          padding: 24px 12px;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          span {
            color: var(--Gray-100, #556373);
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 100% */
          }
        }
        .table_status {
          display: flex;
          padding: 24px 12px;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          .new_status {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              display: flex;
              padding: 6px 12px;
              align-items: center;
              gap: 10px;
              border-radius: 4px;
              border: 1px solid var(--Blue-70, rgba(76, 187, 203, 0.7));
              background: var(--Blue-20, rgba(76, 187, 203, 0.2));
            }
          }
          .resolved_status {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              display: flex;
              padding: 6px 12px;
              align-items: center;
              gap: 10px;
              border-radius: 4px;
              border: 1px solid rgba(76, 203, 96, 0.7);
              background: rgba(76, 203, 96, 0.2);
            }
          }
           .in-progress_status {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              display: flex;
              padding: 6px 5px;
              align-items: center;
              gap: 10px;
              border-radius: 4px;
              border: 1px solid rgba(232, 184, 61, 0.7);
              background: rgba(232, 184, 61, 0.2);
            }
          }
        }
        .table_active {
          display: flex;
          padding: 24px 12px;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          .icon {
            display: flex;
            align-items: flex-start;
            gap: 24px;

            .arrow_img {
              display: flex;
              width: 20px;
              height: 20px;
              padding: 4px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 8px;
            }

            span {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .fix_button {
    display: flex;
    height: 48px;
    padding: 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    background: var(--Gray-2, rgba(85, 99, 115, 0.02));

    span {
      color: var(--Gray-100, #556373);

      /* P2 */
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      text-transform: uppercase;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
export { MainContainer };
