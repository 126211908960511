import React, { useState } from "react";
import { Wrapper } from "./styles.js";

const CustomTooltip = ({
  text,
  children,
  marginTop = "-12px",
  marginleft = "50%",
  height = "19px",
  arrow = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };
  return (
    <Wrapper arrow={arrow}>
      <span
        className="custom-tooltip-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
        {showTooltip && (
          <div
            style={{
              top: `${marginTop}`,
              left: `${marginleft}`,
              height: `${height}`,
            }}
            className="custom-tooltip"
          >
            {capitalizeFirstLetter(text)}
            {arrow && <div className="custom-tooltip-arrow" />}
          </div>
        )}
      </span>
    </Wrapper>
  );
};

export default CustomTooltip;
