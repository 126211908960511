import React from "react";
import { FiInbox } from "react-icons/fi"; // Importing an inbox icon
import {
  MainContiner,
  Card,
  Header,
  InfoContainer,
  InfoBlock,
  Number,
  Link,
} from "./styles";

import useGetData from "../../../hooks/useGetData";

const AccountManagerCards = () => {
  const { data: ticketsData } = useGetData(`dashboard/tickets/statistics`);
  const { data: emailsData } = useGetData(`dashboard/emails/statistics`);

  const { data: tasksData } = useGetData(`dashboard/tasks/statistics`);
  console.log(emailsData, "tickets");
  return (
    <MainContiner>
      <Card>
        <Header>
          <div className="continer">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="vuesax/linear/task-square">
                <g id="task-square">
                  <path
                    id="Vector"
                    d="M12.3706 8.87891H17.6206"
                    stroke="#067787"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M6.37943 8.87891L7.12943 9.62891L9.37943 7.37891"
                    stroke="#067787"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_3"
                    d="M12.3706 15.8789H17.6206"
                    stroke="#067787"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_4"
                    d="M6.37943 15.8789L7.12943 16.6289L9.37943 14.3789"
                    stroke="#067787"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_5"
                    d="M8.99954 22H14.9995C19.9995 22 21.9995 20 21.9995 15V9C21.9995 4 19.9995 2 14.9995 2H8.99954C3.99954 2 1.99954 4 1.99954 9V15C1.99954 20 3.99954 22 8.99954 22Z"
                    stroke="#067787"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>

            <span>Tasks</span>
          </div>
        </Header>
        <InfoContainer>
          <InfoBlock>
            <div>
              <p>New</p>
              <Number>{tasksData?.data?.new_tickets}</Number>
            </div>
            <Link href="#">Update</Link>
          </InfoBlock>
          <InfoBlock>
            <div>
              <p>Pending</p>
              <Number>{tasksData?.data?.pending_tickets}</Number>
            </div>
            <Link href="#">Resolve</Link>
          </InfoBlock>
        </InfoContainer>
      </Card>

      <Card>
        <Header>
          <div className="continer">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icons / 14px / Inbox">
                <path
                  id="Vector"
                  d="M22.6191 14.0566H17.8191C16.6829 14.0566 15.7619 14.9777 15.7619 16.1138V16.1138C15.7619 17.2499 14.8409 18.1709 13.7048 18.1709H10.9619C9.82579 18.1709 8.90477 17.2499 8.90477 16.1138V16.1138C8.90477 14.9777 7.98376 14.0566 6.84763 14.0566H2.04763"
                  stroke="#067787"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M22.6191 16.2863C22.6191 19.1147 22.6191 20.5289 21.7404 21.4076C20.8617 22.2863 19.4475 22.2863 16.6191 22.2863H8.04763C5.2192 22.2863 3.80499 22.2863 2.92631 21.4076C2.04763 20.5289 2.04763 19.1147 2.04763 16.2863V7.71485C2.04763 4.88642 2.04763 3.4722 2.92631 2.59352C3.80499 1.71484 5.2192 1.71484 8.04763 1.71484H16.6191C19.4475 1.71484 20.8617 1.71484 21.7404 2.59352C22.6191 3.4722 22.6191 4.88642 22.6191 7.71484V16.2863Z"
                  stroke="#067787"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <span>Support Inbox</span>
          </div>
        </Header>
        <InfoContainer>
          <InfoBlock>
            <div>
              <p>Support</p>
              <Number>{emailsData?.data?.assignedEmailsCount}</Number>
            </div>
            <Link href="#">View All</Link>
          </InfoBlock>
          <InfoBlock>
            <div>
              <p>Internal</p>
              <Number>{emailsData?.data?.unAssignedEmailsCount}</Number>
            </div>
            <Link href="#">Assign</Link>
          </InfoBlock>
        </InfoContainer>
      </Card>
      <Card>
        <Header>
          <div className="continer">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="vuesax/linear/ticket">
                <g id="ticket">
                  <path
                    id="Vector"
                    d="M20.1661 12.5C20.1661 11.12 21.2861 10 22.6661 10V9C22.6661 5 21.6661 4 17.6661 4H7.66614C3.66614 4 2.66614 5 2.66614 9V9.5C4.04614 9.5 5.16614 10.62 5.16614 12C5.16614 13.38 4.04614 14.5 2.66614 14.5V15C2.66614 19 3.66614 20 7.66614 20H17.6661C21.6661 20 22.6661 19 22.6661 15C21.2861 15 20.1661 13.88 20.1661 12.5Z"
                    stroke="#067787"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M10.6671 4L10.6671 20"
                    stroke="#067787"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-dasharray="5 5"
                  />
                </g>
              </g>
            </svg>

            <span>Tickets</span>
          </div>
        </Header>

        <InfoContainer>
          <InfoBlock>
            <div>
              <p>new</p>
              <Number>{ticketsData?.data?.new_tickets}</Number>
            </div>
            <Link href="#">View All</Link>
          </InfoBlock>
          <InfoBlock>
            <div>
              <p>Pending</p>
              <Number>{ticketsData?.data?.pending_tickets}</Number>
            </div>
            <Link href="#">Assign</Link>
          </InfoBlock>
        </InfoContainer>
      </Card>
    </MainContiner>
  );
};

export default AccountManagerCards;
