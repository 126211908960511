import React, { useState, useEffect, useRef } from "react";
import { MainContainer, TabContainer, TabButton, TabContent } from "./styles";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import FirstColumn from "../../components/support/FirstColumn/FirstColumn";
import SecondColumn from "../../components/support/SecondColumn/SecondColumn";
import ThirdColumn from "../../components/support/ThirdColumn/ThirdColumn";
import FourthColumn from "../../components/support/FourthColumn/FourthColumn";
import EmailComposer from "../../components/support/FirstColumn/composer/EmailComposer";
import { Drawer } from "antd";
import AssociateContact from "../../components/support/AssociateContact/AssociateContact";
import { CloseOutlined } from "@ant-design/icons";
import {
  handleAddCurrentActiveChatTab,
  handleAddCurrentActiveChatTabKey,
} from "../../redux/app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddCurrentActiveChatThread,
  handleAddOpenConversationDetails,
} from "../../redux/app/appSlice";
import noOpenConversation from "./no-open-conversation.svg";
import useGetData from "../../hooks/useGetData";
import SupportSearch from "../../components/support/SupportSearch/SupportSearch";
import { useParams } from "react-router-dom";
import { InstanceWithAuth } from "../../App";
const Support = () => {
  const [selectedEmailOrChat, setSelectedEmailOrChat] = useState(null);
  const [selectedEmailIdForStyling, setSelectedEmailIdForStyling] =
    useState(null);
  const [status, setStatus] = useState(1);
  const [isAllClosedSelected, setIsAllClosedSelected] = useState(false);
  const [isComposerVisible, setIsComposerVisible] = useState(false);
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("addExisting");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [searchParm, setSearchParm] = useState("");
  const [order, setOrder] = useState("DESC");
  const dispatch = useDispatch();
  const refetchEmailsRef = useRef(null);
  const refetchCountRef = useRef(null);
  const refetchEmailRef = useRef(null);
  const id = localStorage.getItem("id");
  const folder = localStorage.getItem("folder");
  const { app, auth } = useSelector((state) => state);
  const [loadingEmailThread, setLoadingEmailThread] = useState(false);
  const [isInitialLoadThread, setIsInitialLoadThread] = useState(true);
  const [email, setEmail] = useState(null);
  const { mailID, mailId, folderId, messageId } = useParams();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderClicked, setFolderClicked] = useState(false);
  const [department, setDepartment] = useState(null);
  const [chatThread, setChatThread] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const abortControllerRef = useRef(null);
  const abortControllerRefForAllConversations = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [initialEmails, setInitialEmails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isFetchingEmail, setIsFetchingEmail] = useState(false);
  const [loadingNextPageEmails, setLoadingNextPageEmails] = useState(false);
  const [isUserTriggered, setIsUserTriggered] = useState(false);
  const [isUserTriggeredFolder, setIsUserTriggeredFolder] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [isClosingAllConversations, setIsClosingAllConversations] =
    useState(false);

  useEffect(() => {
    if (!mailId && !folderId) {
      setSelectedFolder("All Open");
    }
  }, [mailId, folderId]);

  const refetchData = async () => {
    console.log("hi hamza", selectedEmailOrChat, mailId);

    if (!selectedEmailOrChat && !mailId) return;

    if (isUserTriggered && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    try {
      setLoadingEmailThread(true);
      const response = await InstanceWithAuth.get(
        `support/emails/${selectedEmailOrChat ? selectedEmailOrChat : mailId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
          signal: controller.signal,
        }
      );

      if (response.status === 200 || response.status === 201) {
        setEmail(response?.data);
        dispatch(handleAddOpenConversationDetails(response?.data?.data));
      }
      setLoadingEmailThread(false);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Previous request canceled");
      } else {
        console.error(error);
      }
      setLoadingEmailThread(false);
    } finally {
      setIsUserTriggered(false);
    }
  };

  const refetchThread = async () => {
    if (!selectedEmailOrChat && !mailID) return;

    if (isUserTriggered && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    try {
      setLoadingEmailThread(true);
      setChatLoading(true);
      const response = await InstanceWithAuth.get(
        `live-chat/${selectedEmailOrChat ? selectedEmailOrChat : mailID}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
          signal: controller.signal,
        }
      );

      if (response.status === 200 || response.status === 201) {
        setChatThread(response?.data);
        setIsInitialLoadThread(false);
        setEmail(response?.data);
        dispatch(handleAddOpenConversationDetails(response?.data?.data));
        setLoadingEmailThread(false);
      }
      setChatLoading(false);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Previous request canceled");
      } else {
        console.error(error);
      }
      setChatLoading(false);
    } finally {
      setIsUserTriggered(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        setIsUserTriggered(false);
        refetchData();
      } else {
        setIsUserTriggered(false);
        refetchThread();
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [selectedEmailOrChat]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
        refetchData();
      } else {
        refetchThread();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [refetchData, refetchThread, initialEmails]);

  useEffect(() => {
    if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
      refetchData();
    }
  }, [selectedEmailOrChat]);

  const refetchEmails = async () => {
    if (isFetching && !isSearching) return;
    setIsFetching(true);
    setLoading(true);

    if (
      isUserTriggeredFolder &&
      abortControllerRefForAllConversations.current
    ) {
      abortControllerRefForAllConversations.current.abort();
    }
    const controller = new AbortController();
    abortControllerRefForAllConversations.current = controller;

    const nextPage = loadingNextPageEmails ? page + 1 : 1;

    if (loadingNextPageEmails && lastPageNumber && nextPage > lastPageNumber) {
      console.log("⏭️ Reached the last page, stopping requests.");
      setLoadingNextPageEmails(false);
      setLoading(false);
      setIsFetching(false);
      return;
    }

    try {
      console.log("📡 Fetching emails...");
      const normalizedFolderEndpoints = {
        chat: `live-chat?query=${searchText}&order=${order}&filter_by=is_closed&is_closed=0&page=${nextPage}`,
        "all open": `all-conversations?query=${searchText}&order=${order}&page=${nextPage}`,
        unassigned: `all-conversations?query=${searchText}&order=${order}&page=${nextPage}&assigned=unassigned`,
        "assigned to me": `all-conversations?query=${searchText}&order=${order}&page=${nextPage}&assigned=assigned_to_me`,
        "all closed": `all-closed-conversations?query=${searchText}&order=${order}&page=${nextPage}`,
        inbox: `support/emails/inbox?query=${searchText}&status=${status}&order_by=created_at&order=${order}&page=${nextPage}`,
        sent: `support/emails/sent?query=${searchText}&order_by=created_at&order=${order}&page=${nextPage}`,
        spam: `support/emails/spam?query=${searchText}&order_by=created_at&order=${order}&page=${nextPage}`,
        trash: `support/emails/trash?query=${searchText}&order_by=created_at&order=${order}&page=${nextPage}`,
      };

      let normalizedFolder;
      if (folderId && !selectedFolder) {
        console.log("📂 Finding folder for ID:", folderId);

        const selectedFolderData = [
          { key: "1", label: "Inbox" },
          { key: "2", label: "Sent" },
          { key: "3", label: "Spam" },
          { key: "4", label: "Trash" },
          { key: "5", label: "All Open" },
          { key: "6", label: "Chat" },
          { key: "7", label: "All Closed" },
          { key: "8", label: "Unassigned" },
          { key: "9", label: "Assigned to me" },
        ].find((folder) => folder.key === folderId.toString());

        if (!selectedFolderData) {
          console.warn("❌ No matching folder found for folderId:", folderId);
          setLoading(false);
          setIsFetching(false);
          return;
        }
        setSelectedFolder(selectedFolderData.label);

        normalizedFolder = selectedFolderData.label.toLowerCase();
      } else {
        normalizedFolder = selectedFolder?.toLowerCase();
      }
      console.log("📂 Selected Folder:", normalizedFolder);
      // Construct endpoint
      const endpoint =
        department?.id &&
        (normalizedFolder in normalizedFolderEndpoints
          ? `${normalizedFolderEndpoints[normalizedFolder]}&department_id=${department.id}`
          : `${normalizedFolderEndpoints["all open"]}&department_id=${department.id}`);

      if (!endpoint) return;

      // Make the request
      console.log("🌍 API Endpoint:", endpoint);
      const response = await InstanceWithAuth.get(`${endpoint || null}`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log("Response received successfully:", response?.data?.data);

        // Determine if the folder response has nested `data.data` or direct `data`
        const isNestedResponse =
          selectedFolder === "All Open" ||
          selectedFolder === "All Closed" ||
          selectedFolder === "Unassigned" ||
          selectedFolder === "Assigned to me" ||
          Array.isArray(response?.data?.data?.data);

        console.log("Is Nested Response:", isNestedResponse);

        // Get the last page number from the response
        const lastPage = isNestedResponse
          ? response?.data?.data?.last_page
          : response?.data?.last_page;

        console.log("Last Page Number:", lastPage);

        // Save the last page number to avoid further requests beyond this page
        setLastPageNumber(lastPage);

        if (loadingNextPageEmails && nextPage > lastPage) {
          console.log("No more pages to load.");
          setLoading(false);
          setIsFetching(false);
          setLoadingNextPageEmails(false);
          return;
        }

        // Get the new emails from the response
        const newData = isNestedResponse
          ? response?.data?.data?.data || []
          : response?.data?.data || [];
        const newDataArray = Array.isArray(newData) ? newData : [];

        console.log("Fetched Emails:", newDataArray);

        // If paginating, append to previous emails
        if (loadingNextPageEmails) {
          console.log("Loading next page:", nextPage);

          setInitialEmails((prev) => {
            const prevData = isNestedResponse
              ? prev?.data?.data || []
              : prev?.data || [];
            const prevDataArray = Array.isArray(prevData) ? prevData : [];

            console.log("Previous Emails Before Update:", prevDataArray);

            return isNestedResponse
              ? {
                  ...response?.data,
                  data: {
                    ...response?.data,
                    data: [...prevDataArray, ...newDataArray],
                  },
                }
              : {
                  ...response,
                  data: [...prevDataArray, ...newDataArray],
                };
          });

          setPage((prevPage) => prevPage + 1);
        } else {
          if (isClosingAllConversations === true) {
            console.log("Closing all conversations, filtering unique emails.");

            setInitialEmails((prev) => {
              const prevData = isNestedResponse
                ? prev?.data?.data || []
                : prev?.data || [];
              const prevDataArray = Array.isArray(prevData) ? prevData : [];

              console.log("Previous Emails Before Filtering:", prevDataArray);

              // Filter out new emails that are not in the previous data
              const uniqueNewEmails = newDataArray.filter(
                (newEmail) =>
                  !prevDataArray.some((oldEmail) => oldEmail.id === newEmail.id)
              );

              console.log(
                "Unique New Emails After Filtering:",
                uniqueNewEmails
              );

              return isNestedResponse
                ? {
                    ...response?.data,
                    data: {
                      ...response?.data,
                      data: uniqueNewEmails, // Only return new emails
                    },
                  }
                : {
                    ...response,
                    data: uniqueNewEmails, // Only return new emails
                  };
            });
          } else {
            console.log(
              "Setting emails normally (not closing conversations). ",
              newDataArray
            );

            setInitialEmails((prev) => {
              console.log("Replacing with new email data:", newDataArray);
              return isNestedResponse
                ? {
                    ...response?.data,
                    data: { ...response?.data, data: newDataArray },
                  }
                : { ...response, data: newDataArray };
            });
          }
        }
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    } finally {
      setLoading(false);
      setLoadingNextPageEmails(false);
      setIsFetching(false);
      setIsClosingAllConversations(false);
      setIsUserTriggeredFolder(false);
    }
  };

  // Trigger refetch on department change
  useEffect(() => {
    if (department?.id) {
      setIsInitialLoad(true);
      setSelectedEmailOrChat(null);
      refetchEmails();
      setIsInitialLoadThread(true);
    }
  }, [
    department,
    folderId,
    messageId,
    department,
    selectedFolder,
    isClosingAllConversations,
    debouncedSearch,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchEmails();
    }, 5000);
    return () => clearInterval(interval);
  }, [refetchEmails]);

  const handleEmailSelect = (
    emailOrChatId,
    comapnyId,
    conversationsDetails
  ) => {
    if (selectedEmailOrChat === emailOrChatId) {
      return; // Do nothing if the same email/chat is selected again
    }

    setIsUserTriggered(true);
    dispatch(handleAddCurrentActiveChatThread(emailOrChatId));
    if (id && folder) {
      localStorage.removeItem("id");
      localStorage.removeItem("folder");
    }
    setSelectedEmailOrChat(emailOrChatId);
    setSelectedCompanyId(comapnyId);
    setSelectedEmailIdForStyling(conversationsDetails?.communication_id);
    setIsInitialLoadThread(true);
    setIsSearching(false);
  };

  // const refetchEmails = async () => {
  //   if (isFetching) return;
  //   setIsFetching(true);
  //   try {
  //     setLoading(true);
  //     const normalizedFolder = selectedFolder?.toLowerCase();
  //     const normalizedFolderEndpoints = {
  //       chat: `live-chat?query=${searchParm}&order=${order}&filter_by=is_closed&is_closed=0`,
  //       "all open": `all-conversations?query=${searchParm}&order=${order}`,
  //       "all closed": `all-closed-conversations?query=${searchParm}&order=${order}`,
  //       inbox: `support/emails/inbox?status=${status}&order_by=created_at&order=${order}`,
  //       sent: `support/emails/sent?order_by=created_at&order=${order}`,
  //       spam: `support/emails/spam?order_by=created_at&order=${order}`,
  //       trash: `support/emails/trash?order_by=created_at&order=${order}`,
  //     };

  //     const endpoint =
  //       department?.id &&
  //       (normalizedFolder in normalizedFolderEndpoints
  //         ? `${normalizedFolderEndpoints[normalizedFolder]}&department_id=${department.id}`
  //         : `${normalizedFolderEndpoints["all open"]}&department_id=${department.id}`);

  //     const response = await InstanceWithAuth.get(`${endpoint || null}`, {
  //       headers: {
  //         Authorization: `Bearer ${auth?.userObj?.api_token}`,
  //       },
  //     });

  //     if (response.status === 200 || response.status === 201) {
  //       setLoading(false);
  //       setInitialEmails(response?.data);
  //       setIsFetching(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setIsFetching(false);
  //   }
  // };

  // useEffect(() => {
  //   if (department?.id) {
  //     setIsInitialLoad(true);
  //     setSelectedEmailOrChat(null);
  //     refetchEmails();
  //     setIsInitialLoadThread(true);
  //   }
  // }, [department]);

  const showDrawerContactDrawer = () => {
    setActiveTab("addExisting");
    setOpenContactDrawer(true);
  };
  const onCloseContactDrawer = () => {
    setOpenContactDrawer(false);
  };

  const handleFolderSelect = (folder, folderKey) => {
    setEmail(null);
    setInitialEmails(null);
    dispatch(handleAddCurrentActiveChatTab(folder));
    dispatch(handleAddCurrentActiveChatTabKey(folderKey));
    setIsInitialLoad(true);
    setIsInitialLoadThread(true);
    setFolderClicked(true);
    setSelectedFolder(folder);
    setSelectedEmails([]);
    setPage(1);
    setIsUserTriggeredFolder(true);
    setIsSearching(false);
  };

  useEffect(() => {
    setSelectedEmailOrChat(null);
    setSelectedEmailIdForStyling(null);
  }, [selectedFolder]);

  const handleOwnerChange = (event) => {
    const newOwner = event.target.value;
  };

  const openComposer = () => {
    setIsComposerVisible(true);
  };

  const closeComposer = () => {
    setIsComposerVisible(false);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (id && folder) {
      if (folder === "inbox") {
        setSelectedFolder("Inbox");
      } else if (folder === "sent") {
        setSelectedFolder("Sent");
      } else if (folder === "spam") {
        setSelectedFolder("Spam");
      } else if (folder === "trash") {
        setSelectedFolder("Trash");
      } else if (folder === "unassigned") {
        setSelectedFolder("Unassigned");
      } else if (folder === "assigned to me") {
        setSelectedFolder("Assigned to me");
      } else {
        setSelectedFolder("All Closed");
      }
    }
  }, [id, folder]);

  useEffect(() => {
    const setData = async () => {
      console.log(
        "🔄 useEffect triggered with initialEmails:",
        initialEmails?.data
      );

      if (!initialEmails?.data) {
        console.log("⚠️ No initialEmails data found.");
        return;
      }

      if (
        selectedFolder === "All Open" ||
        selectedFolder === "All Closed" ||
        selectedFolder === "Unassigned" ||
        selectedFolder === "Assigned to me"
      ) {
        if (
          !initialEmails?.data?.data ||
          !Array.isArray(initialEmails?.data?.data)
        ) {
          console.log("selectedFolder", selectedFolder);
          console.log(
            "⚠️ initialEmails.data.data is missing or not an array 2."
          );
          dispatch(handleAddOpenConversationDetails(""));
          return;
        }

        if (!initialEmails.data.data[0]) {
          console.log("⚠️ No emails found in initialEmails.data.data.");
          dispatch(handleAddOpenConversationDetails(""));
          return;
        }
      } else {
        if (!initialEmails?.data || !Array.isArray(initialEmails?.data)) {
          console.log("selectedFolder", selectedFolder);

          console.log("⚠️ initialEmails.data is missing or not an array 1.");
          dispatch(handleAddOpenConversationDetails(""));
          return;
        }

        if (!initialEmails?.data[0]) {
          console.log("⚠️ No emails found in initialEmails.data.");
          dispatch(handleAddOpenConversationDetails(""));
          return;
        }
      }

      const emailList = Array.isArray(initialEmails?.data?.data)
        ? initialEmails.data.data
        : Array.isArray(initialEmails?.data)
        ? initialEmails.data
        : [];

      console.log("📩 Processed Email List:", emailList);

      if (!emailList.length) {
        console.log("⚠️ Email list is empty, returning.");
        return;
      }

      if (mailId && folderId) {
        console.log("📌 mailId and folderId detected:", { mailId, folderId });

        if (!isInitialLoad) {
          console.log("⏭️ Skipping due to isInitialLoad being false.");
          return;
        }

        if (folderClicked && isInitialLoad) {
          console.log("📂 Folder clicked and initial load detected.");

          if (
            selectedFolder === "All Open" ||
            selectedFolder === "All Closed" ||
            selectedFolder === "Unassigned" ||
            selectedFolder === "Assigned to me"
          ) {
            console.log("📩 Dispatching first email in All Open/All Closed.");
            dispatch(
              handleAddOpenConversationDetails(initialEmails?.data?.data[0])
            );
            setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
            setSelectedEmailIdForStyling(
              initialEmails?.data?.data[0]?.communication_id
            );
            setIsInitialLoad(false);
          } else {
            console.log("📩 Dispatching first email in other folders.");
            dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
            setSelectedEmailOrChat(initialEmails?.data[0]?.id);
            setSelectedEmailIdForStyling(
              initialEmails?.data[0]?.communication_id
            );
            setIsInitialLoad(false);
          }
        } else {
          console.log("🔍 Searching for selected folder in folder list.");
          const selectedFolderData = [
            { key: "1", label: "Inbox" },
            { key: "2", label: "Sent" },
            { key: "3", label: "Spam" },
            { key: "4", label: "Trash" },
            { key: "5", label: "All Open" },
            { key: "6", label: "Chat" },
            { key: "7", label: "All Closed" },
            { key: "8", label: "Unassigned" },
            { key: "9", label: "Assigned to me" },
          ]?.find((folder) => folder.key === folderId.toString());

          if (selectedFolderData) {
            console.log("📂 Found selected folder:", selectedFolderData.label);
            setSelectedFolder(selectedFolderData.label);
            setSelectedEmailOrChat(mailId);
            await refetchData();

            const emailToSet = Array.isArray(initialEmails?.data?.data)
              ? initialEmails?.data?.data.find(
                  (email) => email.id?.toString() === mailId?.toString()
                )
              : Array.isArray(initialEmails?.data)
              ? initialEmails?.data.find(
                  (email) => email?.id?.toString() === mailId?.toString()
                )
              : null;

            if (emailToSet) {
              dispatch(
                handleAddCurrentActiveChatTab(selectedFolderData?.label)
              );
              dispatch(handleAddOpenConversationDetails(emailToSet));
              setSelectedEmailOrChat(emailToSet?.id);
              setSelectedEmailIdForStyling(emailToSet?.communication_id);
            } else {
              console.log("❌ No matching email found for mailId:", mailId);
            }
          } else {
            console.log("❌ No matching folder found for folderId:", folderId);
          }
        }
      } else if (
        selectedFolder === "All Open" ||
        selectedFolder === "All Closed" ||
        selectedFolder === "Unassigned" ||
        selectedFolder === "Assigned to me"
      ) {
        console.log("📂 Handling 'All Open' or 'All Closed' folder.");

        if (isInitialLoad) {
          console.log("✅ Initial Load: Setting to false.");
          setIsInitialLoad(false);
        } else if (!selectedEmailOrChat) {
          console.log(
            "📩 Dispatching first email in 'All Open' or 'All Closed'."
          );
          dispatch(
            handleAddOpenConversationDetails(initialEmails?.data?.data[0])
          );
          setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
          setSelectedEmailIdForStyling(
            initialEmails?.data?.data[0]?.communication_id
          );
        } else {
          console.log("⏭️ Returning as email is already selected.");
          return;
        }

        console.log("📩 Dispatching first email again to ensure UI update.");
        dispatch(
          handleAddOpenConversationDetails(initialEmails?.data?.data[0])
        );
        setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
        setSelectedEmailIdForStyling(
          initialEmails?.data?.data[0]?.communication_id
        );
      } else {
        console.log("📂 Handling other folders.");

        if (isInitialLoad) {
          console.log("✅ Initial Load: Setting to false.");
          setIsInitialLoad(false);
        } else if (!selectedEmailOrChat) {
          console.log("📩 Dispatching first email in other folders.");
          dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
          setSelectedEmailOrChat(initialEmails?.data[0]?.id);
          setSelectedEmailIdForStyling(
            initialEmails?.data[0]?.communication_id
          );
        } else {
          console.log("⏭️ Returning as email is already selected.");
          return;
        }

        console.log("📩 Dispatching first email again to ensure UI update.");
        dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
        setSelectedEmailOrChat(initialEmails?.data[0]?.id);
        setSelectedEmailIdForStyling(initialEmails?.data[0]?.communication_id);
      }
    };

    setData();
  }, [initialEmails]);

  // useEffect(() => {
  //   if (!initialEmails?.data) return;

  //   if (selectedFolder === "All Open" || selectedFolder === "All Closed") {
  //     if (
  //       !initialEmails?.data?.data ||
  //       !Array.isArray(initialEmails?.data?.data)
  //     ) {
  //       dispatch(handleAddOpenConversationDetails(""));
  //       return;
  //     }

  //     if (!initialEmails.data.data[0]) {
  //       dispatch(handleAddOpenConversationDetails(""));
  //       return;
  //     }
  //   } else {
  //     if (!initialEmails?.data || !Array.isArray(initialEmails?.data)) {
  //       dispatch(handleAddOpenConversationDetails(""));
  //       return;
  //     }

  //     if (!initialEmails?.data[0]) {
  //       dispatch(handleAddOpenConversationDetails(""));
  //       return;
  //     }
  //   }

  //   const emailList = Array.isArray(initialEmails?.data?.data)
  //     ? initialEmails.data.data
  //     : Array.isArray(initialEmails?.data)
  //     ? initialEmails.data
  //     : [];

  //   if (!emailList.length) return;

  //   if (mailId && folderId) {
  //     if (!isInitialLoad) return;
  //     if (folderClicked && isInitialLoad) {
  //       if (selectedFolder === "All Open" || selectedFolder === "All Closed") {
  //         dispatch(
  //           handleAddOpenConversationDetails(initialEmails?.data?.data[0])
  //         );
  //         setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
  //         setSelectedEmailIdForStyling(
  //           initialEmails?.data?.data[0]?.communication_id
  //         );
  //       } else {
  //         dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
  //         setSelectedEmailOrChat(initialEmails?.data[0]?.id);
  //         setSelectedEmailIdForStyling(
  //           initialEmails?.data[0]?.communication_id
  //         );
  //       }
  //     } else {
  //       const selectedFolderData = [
  //         {
  //           key: "1",
  //           label: "Inbox",
  //         },
  //         {
  //           key: "2",
  //           label: "Sent",
  //         },
  //         {
  //           key: "3",
  //           label: "Spam",
  //         },
  //         {
  //           key: "4",
  //           label: "Trash",
  //         },
  //         {
  //           key: "5",
  //           label: "All Open",
  //         },
  //         {
  //           key: "6",
  //           label: "Chat",
  //         },
  //         {
  //           key: "7",
  //           label: "All Closed",
  //         },
  //       ]?.find((folder) => folder.key === folderId.toString());

  //       if (selectedFolderData) {
  //         setSelectedFolder(selectedFolderData.label);

  //         const emailToSet = Array.isArray(initialEmails?.data?.data)
  //           ? initialEmails?.data?.data.find(
  //               (email) => email.id?.toString() === mailId?.toString()
  //             )
  //           : Array.isArray(initialEmails?.data)
  //           ? initialEmails?.data.find(
  //               (email) => email?.id?.toString() === mailId?.toString()
  //             )
  //           : null;

  //         if (emailToSet) {
  //           dispatch(handleAddCurrentActiveChatTab(selectedFolderData?.label));
  //           dispatch(handleAddOpenConversationDetails(emailToSet));
  //           setSelectedEmailOrChat(emailToSet?.id);
  //           setSelectedEmailIdForStyling(emailToSet?.communication_id);
  //         }
  //       }
  //     }
  //   } else if (
  //     selectedFolder === "All Open" ||
  //     selectedFolder === "All Closed"
  //   ) {
  //     if (isInitialLoad) {
  //       setIsInitialLoad(false);
  //     } else if (!selectedEmailOrChat) {
  //       dispatch(
  //         handleAddOpenConversationDetails(initialEmails?.data?.data[0])
  //       );
  //       setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
  //       setSelectedEmailIdForStyling(
  //         initialEmails?.data?.data[0]?.communication_id
  //       );
  //     } else {
  //       return;
  //     }
  //     dispatch(handleAddOpenConversationDetails(initialEmails?.data?.data[0]));
  //     setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
  //     setSelectedEmailIdForStyling(
  //       initialEmails?.data?.data[0]?.communication_id
  //     );
  //   } else {
  //     if (isInitialLoad) {
  //       setIsInitialLoad(false);
  //     } else if (!selectedEmailOrChat) {
  //       dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
  //       setSelectedEmailOrChat(initialEmails?.data[0]?.id);
  //       setSelectedEmailIdForStyling(initialEmails?.data[0]?.communication_id);
  //     } else {
  //       return;
  //     }
  //     dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
  //     setSelectedEmailOrChat(initialEmails?.data[0]?.id);
  //     setSelectedEmailIdForStyling(initialEmails?.data[0]?.communication_id);
  //   }
  // }, [initialEmails]);

  return (
    <div style={{ position: "relative" }}>
      <CommonHaeder firstBcItem={"Support"} firstBcIcon={"support"} />
      {app?.supportSearchShow ? (
        <SupportSearch />
      ) : (
        <>
          <MainContainer>
            <div
              className="first-column"
              style={{
                flex: app?.sidebarCollapsed ? "1.7" : "0.7",
              }}
            >
              <FirstColumn
                status={status}
                selectedFolder={selectedFolder}
                onFolderSelect={handleFolderSelect}
                isAllClosedSelected={isAllClosedSelected}
                openComposer={openComposer}
                selectedEmailId={selectedEmailOrChat}
                setRefetchCount={(refetchFn) => {
                  refetchCountRef.current = refetchFn;
                }}
                setSearchParm={setSearchParm}
                department={department}
                setDepartment={setDepartment}
                setInitialEmails={setInitialEmails}
                searchText={searchText}
                setSearchText={setSearchText}
                setIsSearching={setIsSearching}
                setDebouncedSearch={setDebouncedSearch}
              />
            </div>

            <div className="second-column">
              <SecondColumn
                isInitialLoad={isInitialLoad}
                setIsInitialLoad={setIsInitialLoad}
                status={status}
                selectedFolder={selectedFolder}
                onEmailSelect={handleEmailSelect}
                selectedEmailId={selectedEmailOrChat}
                setRefetchEmails={(refetchFn) => {
                  refetchEmailsRef.current = refetchFn;
                }}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                id={id}
                setSelectedCompanyId={setSelectedCompanyId}
                searchParm={searchParm}
                initialEmails={initialEmails}
                refetchEmails={refetchEmails}
                order={order}
                setOrder={setOrder}
                loading={loading}
                selectedEmailIdForStyling={selectedEmailIdForStyling}
                chatLoading={chatLoading}
                setChatLoading={setChatLoading}
                loadingNextPageEmails={loadingNextPageEmails}
                setLoadingNextPageEmails={setLoadingNextPageEmails}
                setPage={setPage}
                page={page}
              />
            </div>

            {initialEmails?.data?.length <= 0 ||
            initialEmails?.data?.data?.length <= 0 ? (
              <div className="empty-section">
                <img className="svg" src={noOpenConversation} alt="" />
                <span className="h2">No open conversation here</span>
                <span className="h3">You're all caught up.</span>
              </div>
            ) : (
              <>
                <div className="third-column">
                  {selectedEmailOrChat && (
                    <ThirdColumn
                      selectedEmailId={selectedEmailOrChat}
                      onOwnerChange={handleOwnerChange}
                      refetchEmails={refetchEmailsRef.current}
                      refetchCount={refetchCountRef.current}
                      showDrawerContactDrawer={showDrawerContactDrawer}
                      selectedEmails={selectedEmails}
                      setSelectedEmails={setSelectedEmails}
                      selectedFolder={selectedFolder}
                      id={id}
                      refetchData={refetchData}
                      loadingEmailThread={loadingEmailThread}
                      email={email}
                      isInitialLoadThread={isInitialLoadThread}
                      setIsInitialLoadThread={setIsInitialLoadThread}
                      chatThread={chatThread}
                      setChatThread={setChatThread}
                      chatLoading={chatLoading}
                      refetchThread={refetchThread}
                      setIsInitialLoad={setIsInitialLoad}
                    />
                  )}
                </div>
                <div className="fourth-column">
                  {selectedEmailOrChat && (
                    <FourthColumn
                      showDrawerContactDrawer={showDrawerContactDrawer}
                      onCloseContactDrawer={onCloseContactDrawer}
                      selectedEmailId={selectedEmailOrChat}
                      setSelectedEmailOrChat={setSelectedEmailOrChat}
                      refetchCount={refetchCountRef.current}
                      refetchEmails={refetchEmailsRef.current}
                      selectedEmails={selectedEmails}
                      setSelectedEmails={setSelectedEmails}
                      selectedFolder={selectedFolder}
                      selectedCompanyId={selectedCompanyId}
                      setRefetchEmail={(refetchFn) => {
                        refetchEmailRef.current = refetchFn;
                      }}
                      id={id}
                      refetchEmail={refetchData}
                      refetchData={refetchData}
                      loading={loadingEmailThread}
                      email={email}
                      isInitialLoadThread={isInitialLoadThread}
                      setChatThread={setChatThread}
                      setIsInitialLoadThread={setIsInitialLoadThread}
                      refetchThread={refetchThread}
                      setInitialEmails={setInitialEmails}
                      setSelectedEmailIdForStyling={
                        setSelectedEmailIdForStyling
                      }
                      setIsClosingAllConversations={
                        setIsClosingAllConversations
                      }
                      isClosingAllConversations={isClosingAllConversations}
                    />
                  )}
                </div>
              </>
            )}

            <EmailComposer
              refetchEmails={refetchEmailsRef.current}
              selectedEmailId={selectedEmailOrChat}
              visible={isComposerVisible}
              onClose={closeComposer}
              callingFromInbox={true}
            />
          </MainContainer>

          <Drawer
            className="drawer"
            width={600}
            padding={0}
            paddingLG={0}
            paddingXS={0}
            onClose={onCloseContactDrawer}
            open={openContactDrawer}
            headerStyle={{
              backgroundColor: "#4cbbcb",
              color: "#fff",
            }}
            closeIcon={
              <CloseOutlined style={{ color: "white", fontSize: "bold" }} />
            }
            title={
              activeTab === "addExisting"
                ? "Add existing Contact"
                : "Create and associate contact"
            }
          >
            {/* Custom tabs navigation */}
            <TabContainer>
              <TabButton
                isActive={activeTab === "addExisting"}
                onClick={() => handleTabChange("addExisting")}
              >
                Add existing
              </TabButton>
              <TabButton
                isActive={activeTab === "createNew"}
                onClick={() => handleTabChange("createNew")}
              >
                Create new
              </TabButton>
            </TabContainer>

            <TabContent>
              {activeTab === "addExisting" && (
                <AssociateContact
                  type="addExisting"
                  onCloseContactDrawer={onCloseContactDrawer}
                  selectedEmailOrChat={selectedEmailOrChat}
                  refetchEmails={refetchEmailsRef.current}
                  refetchEmail={refetchEmailRef.current}
                  refetchData={refetchData}
                  refetchThread={refetchThread}
                />
              )}
              {activeTab === "createNew" && (
                <AssociateContact
                  type="createNew"
                  onCloseContactDrawer={onCloseContactDrawer}
                  selectedEmailOrChat={selectedEmailOrChat}
                  refetchEmails={refetchEmailsRef.current}
                  refetchEmail={refetchEmailRef.current}
                  refetchData={refetchData}
                  refetchThread={refetchThread}
                />
              )}
            </TabContent>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default Support;
