import React, { useState, useEffect } from "react";
import {
  AssociateContactContainer,
  Footer,
  PaginationContainer,
} from "./styles";
import {
  Divider,
  Form,
  Input,
  Pagination,
  Select,
  Skeleton,
  Space,
} from "antd";
import GetLogo from "../../getlogo/getlogo";
import { InstanceWithAuth } from "../../../App";
import useGetData from "../../../hooks/useGetData";
import { useGlobalAlertMessage } from "../../../hooks/useAlertMessage";
import { handleAddOpenConversationDetails } from "../../../redux/app/appSlice";
import { useDispatch, useSelector } from "react-redux";

const AssociateContact = ({
  type,
  onCloseContactDrawer,
  selectedEmailOrChat,
  refetchEmails,
  refetchEmail,
  refetchData,
  refetchThread,
}) => {
  const { showMessage } = useGlobalAlertMessage();
  const { auth, app } = useSelector((state) => state);
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);
  const [totalContacts, setTotalContacts] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  // ///////////////////////////////////
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // Debounce delay time in milliseconds
  const debounceDelay = 500;

  // Update the debounced search term after user stops typing
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, debounceDelay);

    // Cleanup function to clear the timeout if user starts typing again
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  // Fetch companies based on the debounced search term
  const {
    data: companies,
    loading: loadingCompanies,
    refetchData: refetchCompanies,
  } = useGetData(`companies?query=${debouncedSearchTerm}&per_page=50`);

  const handleSearch = (value) => {
    setSearchTerm(value); // Set the search term while the user is typing
  };
  ////////////////////////////////////////
  // const { data: locationsData, loading: loadingLocations } =
  //   useGetData("locations");

  // const options = locationsData?.data?.map((item) => ({
  //   label: item.name,
  //   value: item.id,
  // }));

  useEffect(() => {
    if (app?.allOpenConversationDetails?.is_sent === 1) {
      const fullName = app?.allOpenConversationDetails?.receiver_name || "";
      const nameParts = fullName ? fullName.split(" ") : [];

      const firstName = nameParts[0] || "";
      const lastName = nameParts.slice(1).join(" ") || "";

      form.setFieldsValue({
        first_name: firstName,
        last_name: lastName,
        email: app?.allOpenConversationDetails?.receiver_email || "",
      });
    } else {
      const fullName = app?.allOpenConversationDetails?.sender_name || "";
      const nameParts = fullName ? fullName.split(" ") : [];

      const firstName = nameParts[0] || "";
      const lastName = nameParts.slice(1).join(" ") || "";

      form.setFieldsValue({
        first_name: firstName,
        last_name: lastName,
        email: app?.allOpenConversationDetails?.sender_email || "",
      });
    }
  }, []);

  // useEffect(() => {
  //   fetchContacts();
  // }, [currentPage, searchTerm]);

  // const fetchContacts = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await InstanceWithAuth.get(
  //       `contacts?query=${searchTerm}&per_page=${perPage}&page=${currentPage}&order=DESC`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${auth?.userObj?.api_token}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200 || response.status === 201) {
  //       const { data, total } = response.data;
  //       setContacts(data);
  //       setTotalContacts(total);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);

  //     showMessage({
  //       type: "error",
  //       content: error?.response?.data?.message,
  //     });
  //   }
  // };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  //   setCurrentPage(1);
  // };

  const handleAssociateExisting = async () => {
    setIsSaving(true);

    // if (!selectedContactId) {
    //   showMessage({
    //     type: "error",
    //     content: "Please select a contact before saving.",
    //   });

    //   setIsSaving(false);
    //   return;
    // }

    let formData = new FormData();
    // formData.append("contact_id", selectedContactId);
    formData.append("create_contact", false);
    let type;
    if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
      type = "email";
    } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
      type = "chat";
    }
    formData.append("type", type);
    formData.append("company_id", company);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/${selectedEmailOrChat}/associate_to_contact`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setIsSaving(false);
        if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
          refetchEmail();
          refetchData();
          // dispatch(handleAddOpenConversationDetails(response?.data?.data));
        } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
          refetchThread();
          // dispatch(handleAddOpenConversationDetails(response?.data?.data));
        }

        showMessage({
          type: "success",
          content: response?.data?.message,
        });
        onCloseContactDrawer();
      }
    } catch (error) {
      setIsSaving(false);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  const handleAssociateNew = async () => {
    setIsSaving(true);
    const [firstNameFromEmail, ...lastNameArray] =
      app?.allOpenConversationDetails?.sender_name?.split(" ") || [""];

    const lastNameFromEmail = lastNameArray?.join(" ");
    let formData = new FormData();
    formData.append("create_contact", true);
    formData.append("first_name", firstName || firstNameFromEmail);
    formData.append("last_name", lastName || lastNameFromEmail);
    formData.append(
      "email",
      email || app?.allOpenConversationDetails?.sender_email
    );
    formData.append("phone", phone);
    formData.append("company_id", company);

    let type;
    if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
      type = "email";
    } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
      type = "chat";
    }
    formData.append("type", type);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/${selectedEmailOrChat}/associate_to_contact`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setIsSaving(false);
        if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
          refetchEmail();
          refetchData();
        } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
          refetchThread();
        }
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
        onCloseContactDrawer();
      }
    } catch (error) {
      setIsSaving(false);
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  return (
    <AssociateContactContainer>
      {type === "addExisting" && (
        <>
          <div className="form-container">
            <div className="form-main">
              <Form form={form} className="form">
                <Form.Item
                  label="Select Company"
                  name="company"
                  className="form-item"
                >
                  <Select
                    placeholder="Select Company"
                    className="location"
                    style={{
                      width: "100% !important",
                    }}
                    showSearch
                    options={companies?.data?.map((company) => ({
                      value: company.id,
                      label: `${company?.name}`,
                    }))}
                    onChange={(value) => setCompany(value)}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onSearch={handleSearch}
                  />
                </Form.Item>

                {/* <div
                  style={{
                    marginTop: "10px",
                    minWidth: "466px",
                  }}
                >
                  <Form.Item
                    label="Select Contact"
                    name="contact"
                    className="form-item"
                  >
                    <Input
                      placeholder="Search contacts"
                      suffix={
                        <span className="icon">{GetLogo("searchIcon")}</span>
                      }
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </Form.Item>
                </div> */}
              </Form>
            </div>
          </div>

          <div className="contact-list">
            {loading ? (
              <></>
            ) : (
              // <Skeleton active />
              <>
                {/* <div className="filters">
                  <span className="total">{totalContacts} contacts</span>
                  <span></span>
                </div>
                <div className="list">
                  {contacts.length > 0 ? (
                    contacts.map((contact) => (
                      <div className="list-item" key={contact.id}>
                        <span className="radio-button">
                          <input
                            type="radio"
                            id={contact.id}
                            name="contact"
                            value={contact.id}
                            onChange={(e) =>
                              setSelectedContactId(e.target.value)
                            }
                          />
                        </span>
                        <label className="label" htmlFor={contact.id}>
                          {contact.first_name +
                            " " +
                            contact.last_name +
                            " (" +
                            contact.email +
                            ")"}
                        </label>
                      </div>
                    ))
                  ) : (
                    <span>No contacts found</span>
                  )}
                </div> */}
              </>
            )}
          </div>

          {/* {!loading && (
            <PaginationContainer>
              <Pagination
                showSizeChanger={false} // Disable page size change for now
                onChange={handlePageChange}
                current={currentPage}
                total={totalContacts}
                pageSize={perPage} // Number of items per page
                nextIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="8"
                    viewBox="0 0 6 8"
                    fill="none"
                  >
                    <path
                      d="M1.5 7L4.5 4L1.5 1"
                      stroke="#556373"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                prevIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="8"
                    viewBox="0 0 6 8"
                    fill="none"
                  >
                    <path
                      d="M4.5 7L1.5 4L4.5 1"
                      stroke="#556373"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                className="pagination-div"
              />
            </PaginationContainer>
          )} */}

          {!loading && (
            <Footer>
              <button onClick={onCloseContactDrawer} className="cancel">
                Cancel
              </button>
              <button
                onClick={() => handleAssociateExisting()}
                disabled={isSaving}
                className="save"
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </Footer>
          )}
        </>
      )}
      {type === "createNew" && (
        <div className="form-container">
          <div className="form-main">
            <Form form={form} className="form">
              <Form.Item
                label="First Name"
                name="first_name"
                className="form-items"
              >
                <Input
                  className="input"
                  placeholder="John"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="last_name"
                className="form-items"
              >
                <Input
                  className="input"
                  placeholder="Doe"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Email" name="email" className="form-items">
                <Input
                  className="input"
                  placeholder="john@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Phone" name="phone" className="form-items">
                <Input
                  className="input"
                  placeholder="+1234567890"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Select Company"
                name="company"
                className="form-item"
              >
                <Select
                  placeholder="Select Company"
                  className="location"
                  style={{
                    width: "100% !important",
                  }}
                  showSearch
                  options={companies?.data?.map((company) => ({
                    value: company.id,
                    label: `${company?.name}`,
                  }))}
                  onChange={(value) => setCompany(value)}
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onSearch={handleSearch}
                />
              </Form.Item>

              <Footer>
                <button onClick={onCloseContactDrawer} className="cancel">
                  Cancel
                </button>
                <button
                  onClick={() => handleAssociateNew()}
                  disabled={isSaving}
                  className="save"
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </Footer>
            </Form>
          </div>
        </div>
      )}
    </AssociateContactContainer>
  );
};

export default AssociateContact;
