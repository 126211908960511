import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import {
  ChatHeaderContainer,
  ReopenIconWrapper,
  ReopenIconWrapperr,
} from "./styles";
import ChatHeaderDropDown from "../chatHeaderDropDown/ChatHeaderDropDown";
import Avatar from "./avatar/avatar";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import { Popconfirm } from "antd";
import { MdDone } from "react-icons/md";
import { PiArrowArcRightBold } from "react-icons/pi";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

const ChatHeader = ({
  selectedEmailId,
  setCurrentEmployeeImage,
  currentEmployeeImage,
  ticket,
  ticketID,
  refetchData,
  refetchDataTicket,
}) => {
  const { showMessage } = useGlobalAlertMessage();
  const { app } = useSelector((state) => state);
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const handleCloseConversation = async () => {
    console.log("handleCloseConversation", auth?.userObj?.api_token);
    try {
      setLoading(true);
      const response = await InstanceWithAuth.post(
        `support/tickets/status/${ticketID}/Closed`,
        null,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setLoading(false);
        refetchData();
        refetchDataTicket();
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConversation = async () => {
    try {
      setLoading(true);

      const response = await InstanceWithAuth.post(
        `support/tickets/status/${ticketID}/Open`,
        null,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setLoading(false);
        refetchData();
        refetchDataTicket();
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <ChatHeaderContainer>
      <div className="wrapper">
        <div>
          <span className="title">Owner</span>
          <div className="avatar-name-container">
            <Avatar currentEmployeeImage={currentEmployeeImage} />
            <ChatHeaderDropDown
              selectedEmailId={selectedEmailId}
              setCurrentEmployeeImage={setCurrentEmployeeImage}
              ticket={ticket}
              ticketID={ticketID}
            ></ChatHeaderDropDown>
          </div>
        </div>
        <div>
          <span className="title1">Created By</span>
          <div className="avatar-name-container">
            {ticket?.created_by ? (
              <span className="name">{ticket?.created_by}</span>
            ) : (
              <span className="name">- Automation -</span>
            )}
          </div>
        </div>
      </div>

      {ticket?.status == "Open" && (
        <button className="button" disabled={loading}>
          <Popconfirm
            title="Close Ticket"
            description="Are you sure to close?"
            onConfirm={() => handleCloseConversation()}
            // onCancel={c}
            okText="Yes"
            cancelText="No"
          >
            <ReopenIconWrapperr>
              <ReopenIconWrapper>
                <MdDone size={16} />
              </ReopenIconWrapper>
              {loading ? "Closing" : "Close Ticket"}
            </ReopenIconWrapperr>
          </Popconfirm>
        </button>
      )}
      {ticket?.status == "Closed" && (
        <button className="button" disabled={loading}>
          <Popconfirm
            title="Reopen Ticket"
            description="Are you sure to reopen?"
            onConfirm={() => handleOpenConversation()}
            // onCancel={c}
            okText="Yes"
            cancelText="No"
          >
            <ReopenIconWrapperr>
              <ReopenIconWrapper>
                <PiArrowArcRightBold size={16} />
              </ReopenIconWrapper>
              {loading ? "Opening" : "Reopen Ticket"}
            </ReopenIconWrapperr>
          </Popconfirm>
        </button>
      )}
    </ChatHeaderContainer>
  );
};

export default ChatHeader;
