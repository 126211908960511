import styled from "styled-components";

const MainContainer = styled.div`

    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .profile_img {
      display: flex;
      width: 100%;
      max-width: 90px;
      height: 90px;
      align-items: flex-start;
      gap: 8px;

      .bg_img {
        width: 100%;
        max-width: 90px;
        height: 90px;
        flex-shrink: 0;
        border-radius: 90px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      ${'' /* width:100%; */}
      width:290px;
      gap: 8px;

      .text_msg {
        color: var(--Gray-80, rgba(85, 99, 115, 0.8));
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.18px;
      }
      .User_name {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .User_name span {
        color: #212c3b;
        font-family: Outfit;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.24px;
      }

      .timeline {
        color: #2d3745;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.14px;
      }
    }
  
`;

export { MainContainer };
